import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { NavigateFunction, useNavigate } from "react-router"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { SelectChangeEvent, useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import LoadingButton from "@mui/lab/LoadingButton"
import MenuItem from "@mui/material/MenuItem"
import {
  CreateRuleDto,
  CreateRuleDtoExaminedEntity,
  CreateRuleDtoOutput,
  CreateRuleDtoType,
  RuleDto,
  RuleDtoExaminedEntity,
  RuleDtoOutput,
  RuleDtoType,
  UpdateRuleDto,
} from "../../apis/model"
import {
  ruleControllerCreate,
  ruleControllerUpdate,
  useRuleControllerCreate,
  useRuleControllerRules,
  useRuleControllerUpdate,
} from "../../apis/endpoints/edgeService"
import RuleFormSkeleton from "./RuleFormSkeleton"
import { stringContainsOnlyValidChar } from "../../utils/regax"
import { useToast } from "../../providers/ToastContextProvider"

const useStyles = makeStyles()(() => ({
  ruleForm: {
    padding: "1rem",
  },
  paper: {
    padding: "1rem",
  },
  formPaper: {
    padding: "2rem 1rem",
  },
  inputField: {
    marginBottom: "1rem",
  },
  save: {
    margin: "0 2rem 0 0",
  },
}))
const RuleForm = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const { addToast } = useToast()
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const ruleId: number = params?.id === undefined || params.id === "new" ? -1 : Number(params.id)

  const { data, isLoading } = useRuleControllerRules()

  useEffect(() => {
    if (ruleId > 0) {
      const rule = data?.find((rule: RuleDto) => rule.id === ruleId)
      if (rule) {
        setRule({
          shortName: rule.shortName,
          title: rule.title,
          description: rule.description,
          examinedEntity: rule.examinedEntity,
          output: rule.output,
          type: rule.type,
          remediation: rule.remediation,
        })
      } else {
        addToast("error", "Rule Id not exist")
      }
    }
  }, [data, ruleId, addToast])

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    description: "",
    examinedEntity: "",
    output: "",
    remediation: "",
    shortName: "",
    title: "",
    type: "",
  })

  const [rule, setRule] = useState<CreateRuleDto>({
    shortName: "",
    title: "",
    description: "",
    examinedEntity: CreateRuleDtoExaminedEntity.Payment,
    output: CreateRuleDtoOutput.Alert,
    type: CreateRuleDtoType.BankAccountVerification,
    remediation: "",
  })

  const ruleExaminedEntity: CreateRuleDtoExaminedEntity[] = [
    CreateRuleDtoExaminedEntity.Vendor,
    CreateRuleDtoExaminedEntity.Payment,
    CreateRuleDtoExaminedEntity.Email,
    CreateRuleDtoExaminedEntity.Ledger,
  ]

  const ruleOutput: CreateRuleDtoOutput[] = [
    CreateRuleDtoOutput.Alert,
    CreateRuleDtoOutput.Gap,
    CreateRuleDtoOutput.Insight,
  ]

  const ruleType: CreateRuleDtoType[] = [
    CreateRuleDtoType.BankAccountVerification,
    CreateRuleDtoType.Sanctions,
    CreateRuleDtoType.DataValidation,
    CreateRuleDtoType.Cyber,
    CreateRuleDtoType.Vulnerability,
  ]

  const createRiskAnalyzer = useRuleControllerCreate({
    mutation: {
      mutationFn: (toSave: { data: CreateRuleDto }) => {
        return ruleControllerCreate(toSave.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate("/admin/rules")
      },
      onError: (e) => {
        setIsSaving(false)
        console.log(e)
      },
    },
  })

  const updateRiskAnalyzer = useRuleControllerUpdate({
    mutation: {
      mutationFn: (toUpdate: { id: number; data: UpdateRuleDto }) => {
        return ruleControllerUpdate(toUpdate.id, toUpdate.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate(-1)
      },
      onError: (e: any) => {
        setIsSaving(false)
      },
    },
  })

  const validateForm = () => {
    const { description, remediation, shortName, title } = rule
    let errors = { ...formErrors }

    errors.shortName = !shortName
      ? t.Errors.fieldInNotDefined
      : shortName.length === 0
        ? t.Errors.inputIsTooLongError
        : !stringContainsOnlyValidChar(shortName)
          ? t.Errors.illegalCharactersError
          : ""

    errors.title = !title
      ? t.Errors.fieldInNotDefined
      : title.length === 0
        ? t.Errors.emptyInputError
        : !stringContainsOnlyValidChar(title)
          ? t.Errors.illegalCharactersError
          : ""

    errors.description = !description
      ? t.Errors.fieldInNotDefined
      : description.length === 0
        ? t.Errors.emptyInputError
        : ""

    errors.remediation = remediation && remediation.length > 1000 ? t.Errors.inputIsTooLongError : ""

    setFormErrors(errors)

    return !Object.values(errors).some((error: string) => !!error)
  }

  const save = () => {
    if (validateForm()) {
      setIsSaving(true)
      if (ruleId !== -1) {
        updateRiskAnalyzer.mutate({
          id: ruleId,
          data: {
            title: rule.title,
            description: rule.description,
            examinedEntity: rule.examinedEntity,
            output: rule.output,
            type: rule.type,
            remediation: rule.remediation,
          },
        })
      } else {
        createRiskAnalyzer.mutate({ data: rule })
      }
    }
  }

  const goToRules = () => navigate(-1)

  const onTypeChange = (e: SelectChangeEvent): void => setRule({ ...rule, type: e.target.value as CreateRuleDtoType })

  const onExaminedEntityChange = (e: SelectChangeEvent): void =>
    setRule({ ...rule, examinedEntity: e.target.value as CreateRuleDtoExaminedEntity })

  const onOutputChange = (e: SelectChangeEvent): void =>
    setRule({ ...rule, output: e.target.value as CreateRuleDtoOutput })

  const onShortNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setRule({ ...rule, shortName: e?.target?.value })

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => setRule({ ...rule, title: e.target.value })

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setRule({ ...rule, description: e.target.value })

  const onRemediationChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setRule({ ...rule, remediation: e.target.value })

  if (isLoading || !data) return <RuleFormSkeleton />

  return (
    <Grid container className={classes.ruleForm} spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant={"h6"} data-testid={"form-title"} fontWeight={400}>
            {t.General.newRule}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Grid spacing={3} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item md={4} xs={12} lg={4}>
              <Stack spacing={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  disabled={ruleId > 0}
                  value={rule.shortName}
                  label={t.General.shortName}
                  inputProps={{ maxLength: 100 }}
                  onChange={onShortNameChange}
                  helperText={formErrors.shortName}
                  error={formErrors.shortName !== ""}
                  data-testid={"short-name"}
                  required
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={rule.title}
                  label={t.General.title}
                  className={classes.inputField}
                  inputProps={{ maxLength: 200 }}
                  onChange={onTitleChange}
                  helperText={formErrors.title}
                  error={formErrors.title !== ""}
                  required
                  data-testid={"title"}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={rule.description}
                  label={t.General.description}
                  className={classes.inputField}
                  inputProps={{ maxLength: 800 }}
                  onChange={onDescriptionChange}
                  helperText={formErrors.description}
                  error={formErrors.description !== ""}
                  required
                  multiline
                  rows={3}
                  data-testid={"description"}
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Grid spacing={3} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item md={4} xs={12} lg={4}>
              <Stack spacing={3}>
                <FormControl fullWidth data-testid="rule-entity">
                  <InputLabel id="rule-entity">{t.General.examinedEntityType}</InputLabel>
                  <Select
                    value={rule.examinedEntity}
                    label={t.General.examinedEntity}
                    labelId="entity-type"
                    onChange={onExaminedEntityChange}
                    size={"small"}
                    required
                  >
                    {ruleExaminedEntity.map((ee: RuleDtoExaminedEntity) => (
                      <MenuItem key={ee} value={ee}>
                        {ee}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth data-testid="output">
                  <InputLabel id="output-entity">{t.General.output}</InputLabel>
                  <Select
                    value={rule.output}
                    label={t.General.output}
                    labelId="output"
                    size={"small"}
                    onChange={onOutputChange}
                    required
                  >
                    {ruleOutput.map((output: RuleDtoOutput) => (
                      <MenuItem key={output} value={output}>
                        {output}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth data-testid="type">
                  <InputLabel id="type">{t.General.type}</InputLabel>
                  <Select
                    value={rule.type}
                    label={t.General.output}
                    labelId="type"
                    size={"small"}
                    onChange={onTypeChange}
                    required
                  >
                    {ruleType.map((type: RuleDtoType) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  variant="outlined"
                  value={rule.remediation}
                  label={t.General.remediation}
                  className={classes.inputField}
                  inputProps={{ maxLength: 1000 }}
                  onChange={onRemediationChange}
                  helperText={formErrors.remediation}
                  error={formErrors.remediation !== ""}
                  rows={3}
                  multiline
                  data-testid={"remediation"}
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <LoadingButton
            size="medium"
            color="primary"
            variant="contained"
            loading={isSaving}
            className={classes.save}
            onClick={save}
            data-testid={"save"}
          >
            {t.General.save}
          </LoadingButton>
          <Button variant={"outlined"} onClick={goToRules} className={classes.save}>
            {t.General.cancel}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RuleForm
