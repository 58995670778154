import React from "react"
import Typography from "@mui/material/Typography"

export default function NotFound(): JSX.Element {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 80,
          }}
        >
          404
        </Typography>
        <Typography style={{ fontSize: 20, textAlign: "center" }}>Oops, page not found</Typography>
      </div>
    </div>
  )
}
