import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

interface IProps {
  children: JSX.Element
}

const PrivateRoute = ({ children }: IProps): JSX.Element => {
  const { isAuthenticated } = useAuth0()
  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return children
}

export default PrivateRoute
