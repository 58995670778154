import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import TableTextCell from "common/TableTextCell"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import TablePageSkeleton from "common/TablePageSkeleton"
import { slotProps } from "theme/data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { formatDate } from "../../utils/utils"
import { useLedgerControllerLedger } from "../../apis/endpoints/edgeService"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

const useStyles = makeStyles()(() => ({
  transactions: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    height: "78vh",
    width: "100%",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))
const Ledgers = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const { data, isLoading } = useLedgerControllerLedger()

  const columns: any[] = [
    { field: "id", headerName: "ID" },
    {
      field: "payeeInternalId",
      headerName: t.General.payeeInternalId,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value} testId={`p-id-${params.value}`} />
      ),
    },
    {
      field: "payeeName",
      headerName: t.General.payeeName,
      flex: 1.3,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value} testId={`p-name-${params.value}`} />
      ),
    },
    {
      field: "payerName",
      headerName: t.General.payerName,
      flex: 1.3,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "amount",
      headerName: t.General.amount,
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "currencyCode",
      headerName: t.General.currencyCode,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "objective",
      headerName: t.General.objective,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "dateCreated",
      headerName: t.General.dateCreated,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value ? formatDate(params.value) : "-"} />
      ),
    },

    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value ? formatDate(params.value) : "-"} />
      ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.transactions} spacing={1} data-teatid={"ledgers"}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" className={classes.title} data-testid={"title"}>
              {data.length.toLocaleString()} {t.General.ledgers}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <DataGridPro
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              rows={data}
              columns={columns}
              slotProps={slotProps}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Ledgers
