import React from "react"
import { ITheme } from "../../theme/theme"
import { Button, useTheme } from "@mui/material"
import { NavigateFunction, useNavigate } from "react-router"
import GppMaybeIcon from "@mui/icons-material/GppMaybe"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { DashboardDto, EntitiesWidgetDto, EntityWidgetDto } from "../../apis/model"

interface IProps {
  entitiesWidget: EntitiesWidgetDto
}

const RiskAlerts = ({ entitiesWidget }: IProps): JSX.Element => {
  const theme: ITheme = useTheme()
  const navigate: NavigateFunction = useNavigate()

  const navigateTo = (route: any): void => navigate(route)

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Paper
          sx={{
            padding: "1rem 1rem",
            cursor: "pointer",
            backgroundColor: entitiesWidget.payment.alerts > 0 ? "rgba(219,97,97,0.2)" : "#FFF",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2}>
              <GppMaybeIcon
                fontSize={"large"}
                sx={{
                  verticalAlign: "middle",
                  color:
                    entitiesWidget.payment.alerts > 0
                      ? theme.palette.severities.high
                      : theme.palette.severities.success,
                }}
              />
              {entitiesWidget.payment.alerts > 0 && (
                <Typography variant={"h6"}>{entitiesWidget.payment.alerts} Risk Alerts </Typography>
              )}
              {entitiesWidget.payment.alerts === 0 && <Typography variant={"h6"}>No Risk Alerts</Typography>}

              {/*{topPaymentsAffected && topPaymentsAffected?.length > 0 && topPaymentsAffected[0].findingList && (*/}
              {/*  <Typography variant={"body1"} color={theme.palette.grey["600"]} sx={{ lineHeight: "2.2rem" }}>*/}
              {/*    Recent alert created at {formatDate(topPaymentsAffected[0].findingList[0].createdAt)}*/}
              {/*  </Typography>*/}
              {/*)}*/}
              {entitiesWidget.payment.alerts === 0 && (
                <Typography variant={"body1"} color={theme.palette.grey["600"]} sx={{ lineHeight: "2.1rem" }}>
                  Real-time
                </Typography>
              )}
            </Stack>
            {entitiesWidget.payment.alerts > 0 && (
              <Button
                variant={"contained"}
                color={"error"}
                sx={{ float: "right" }}
                onClick={navigateTo.bind(null, "/alerts")}
              >
                View Alerts
              </Button>
            )}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RiskAlerts
