import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import { makeStyles } from "../../makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { CompanyDto, UserDto } from "../../apis/model"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Stack from "@mui/material/Stack"
import { ITheme } from "../../theme/theme"

const useStyles = makeStyles()((theme: ITheme) => ({
  current: {
    backgroundColor: "#DEEAFF",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    padding: "5px",
    borderRadius: "5px",
    fontWeight: 600,
  },
  link: {
    textTransform: "capitalize",
    textDecoration: "none",
    cursor: "pointer",
  },
  company: {
    fontWeight: 600,
    marginLeft: "1rem",
  },
  back: {
    cursor: "pointer",
    margin: "auto 0",
  },
}))

interface IProps {
  company: CompanyDto
  user: UserDto
}
const BreadcrumbsNav = ({ company, user }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()

  const navigateTo = (route: any): void => navigate(route)

  let currentRoutes: string[] = []
  currentRoutes = location.pathname !== "/" ? location.pathname.split("/") : []
  if (currentRoutes.length > 0) {
    currentRoutes.shift()
  }

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {location.pathname !== "/" && location.pathname !== "/dashboard" && (
        <Stack direction={"row"}>
          <ArrowBackIcon fontSize={"small"} className={classes.back} onClick={navigateTo.bind(null, -1)} />
          <Typography variant={"body1"} className={classes.company}>
            {company.name}
          </Typography>
        </Stack>
      )}
      {(location.pathname === "/" || location.pathname === "/dashboard") && (
        <Typography variant={"h5"}>Welcome {user.name}</Typography>
      )}
      {currentRoutes.map((cr: string, index) => {
        const to: string = `/${currentRoutes.slice(0, index + 1).join("/")}`
        if (cr === "/" || cr === "dashboard") {
          return <Box key={`br-box`} />
        } else if (index === currentRoutes.length - 1) {
          return (
            <Typography variant={"body1"} className={classes.current} key={`br-${cr}`}>
              {Number(cr) ? `#${cr}` : cr}
            </Typography>
          )
        }

        return (
          <Link
            underline="hover"
            key={`br-${cr}`}
            onClick={navigateTo.bind(null, to)}
            className={classes.link}
            color="inherit"
          >
            {cr}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
export default BreadcrumbsNav
