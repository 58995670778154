import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import Button from "@mui/material/Button"
import { useAuth0 } from "@auth0/auth0-react"

const useStyles = makeStyles()((theme: ITheme) => ({
  newUser: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    color: "#FFF",
    textAlign: "center",
  },
  text: {
    marginBottom: "2rem",
  },
}))
const NewUserSignUp = (): JSX.Element => {
  const { classes } = useStyles()
  const { logout } = useAuth0()

  const navigateToSafety = () => {
    logout()
  }

  return (
    <Grid className={classes.newUser} container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant={"h5"} className={classes.text}>
          Welcome to CREEDNZ
        </Typography>
        <Typography variant={"h6"} className={classes.text}>
          In order to login you have to have an invitation
        </Typography>
        <Typography className={classes.text} variant="body1">
          {`For any questions or help please contact are support team at `}
          <Link href="mailto:support@creednz.com">support@creednz.com</Link>
        </Typography>

        <Button onClick={navigateToSafety} variant={"contained"}>
          Go Back
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewUserSignUp
