import React from "react"
import { makeStyles } from "makeStyles"
import { Button, Grid, Paper, Typography } from "@mui/material"
import { ITheme } from "theme/theme"

const useStyles = makeStyles()((theme: ITheme) => ({
  notification: {
    padding: "0 1rem",
    width: "100%",
  },
  mainHeadline: {
    marginBottom: 0,
    fontSize: 20,
  },
  description: {
    fontSize: 13,
    color: theme.palette.secondary.main,
    lineHeight: 1.3,
    paddingTop: 5,
    paddingBottom: 8,
  },
  countryName: {
    fontSize: 13,
    color: theme.palette.secondary.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  headlineIcon: {
    fontSize: 13,
    paddingTop: 5,
    paddingLeft: 8,
  },
  continueButton: {
    backgroundColor: theme.palette.primary.main,
    height: 30,
    marginTop: 14,
    textTransform: "capitalize",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  continueButtonIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  continueButtonTypo: {
    fontSize: 13,
  },
  sandboxPaper: {
    height: 104,
    marginTop: 20,
    padding: "15px 20px",
    position: "relative",
  },
  siemPaper: {
    marginTop: 20,
    padding: "15px 20px",
    position: "relative",
  },
  erpPaper: {
    marginTop: 20,
    padding: "15px 20px",
    position: "relative",
  },
  instituationPaper: {
    marginTop: 24,
    padding: "15px 20px",
    position: "relative",
    paddingBottom: 20,
  },
  infoERPIcon: {
    fontSize: 13,
    color: theme.palette.primary.main,
    paddingTop: 16.5,
    paddingRight: 6,
  },
  integrationChoiceERP: {
    backgroundColor: "white",
    borderRadius: 4,
    width: 150,
    height: 100,
    margin: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(225, 225, 225)",
    },
  },
}))

function NotificationsSettings(): JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={classes.notification}>
      <Grid item xs={12} container direction="column">
        <Typography className={classes.mainHeadline}>Notifications</Typography>
        <Grid item>
          <Paper className={classes.sandboxPaper}>
            <div className={classes.row}>
              <Typography>Integrations</Typography>
            </div>
            <Typography className={classes.description}>
              Integrations with Email, SMS, Slack or API (Webhook)
            </Typography>
            <Button
              disabled
              variant={"outlined"}
              size={"small"}
              onClick={() => {
                window.location.href = "/beneficiaries"
              }}
            >
              <Typography className={classes.continueButtonTypo}>Setup Notification Channel</Typography>
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.sandboxPaper}>
            <div className={classes.row}>
              <Typography>Notifications</Typography>
            </div>
            <Typography className={classes.description}>Create notification group and level</Typography>
            <Button
              disabled
              variant={"outlined"}
              size={"small"}
              onClick={() => {
                window.location.href = "/beneficiaries"
              }}
            >
              <Typography className={classes.continueButtonTypo}>Create Notification</Typography>
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <div style={{ height: 30, width: "100%" }}></div>
    </div>
  )
}

export default NotificationsSettings
