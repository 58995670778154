import React from "react"
import { ITheme } from "../../theme/theme"
import { Badge, CardHeader, Paper, useTheme } from "@mui/material"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import { makeStyles } from "../../makeStyles"
import Avatar from "@mui/material/Avatar"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import MailIcon from "@mui/icons-material/Mail"
import ListAltIcon from "@mui/icons-material/ListAlt"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ChecklistIcon from "@mui/icons-material/Checklist"
import { styled } from "@mui/material/styles"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import InsightsIcon from "@mui/icons-material/Insights"
import HubOutlinedIcon from "@mui/icons-material/HubOutlined"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"

const useStyles = makeStyles()((theme: ITheme) => ({
  outerCircle: {
    position: "relative",
    backgroundColor: "rgba(105, 140, 174, 0)",
    border: `1px solid rgba(105, 140, 174, 0.5)`,
    borderRadius: "50%",
    height: "450px",
    width: "450px",
    margin: "2rem 5rem 2rem 2rem",
  },
  innerCircle: {
    position: "absolute",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "rgba(46, 118, 253, 0.05)",
    borderRadius: "50%",
    height: "200px",
    width: "200px",
    top: "50%",
    left: "50%",
    margin: "-100px 0px 0px -100px",
  },
  middleCircle: {
    position: "absolute",
    backgroundColor: "rgba(128, 152, 249, 0.03)",
    border: `1px solid rgba(128, 152, 249, 1)`,
    borderRadius: "50%",
    height: "325px",
    width: "325px",
    top: "50%",
    left: "50%",
    margin: "-162.5px 0px 0px -162.5px",
  },
}))

const Coverage = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()

  const creednzItems = [
    {
      icon: <InsightsIcon sx={{ color: theme.palette.common.white }} />,
      title: "Intelligence - Sanctions",
      backgroundColor: theme.palette.primary.main,
    },
    {
      icon: <PriorityHighIcon sx={{ color: theme.palette.common.white }} />,
      title: "Intelligence - Cyber Threats",
      backgroundColor: theme.palette.primary.main,
    },
    {
      icon: <AccountBalanceIcon sx={{ color: theme.palette.common.white }} />,
      title: "Bank Account Validation",
      backgroundColor: theme.palette.primary.main,
    },
    {
      icon: <AutoFixHighIcon sx={{ color: theme.palette.common.white }} />,
      title: "Vendor Enrichment",
      backgroundColor: theme.palette.primary.main,
    },
    {
      icon: <HubOutlinedIcon sx={{ color: theme.palette.common.white }} />,
      title: "Creednz Network",
      backgroundColor: theme.palette.primary.main,
    },
  ]

  const items = [
    {
      icon: <AccountBalanceIcon sx={{ color: theme.palette.common.white }} />,
      top: "200px",
      left: "430px",
      title: "Bank account - Vendors",
      backgroundColor: theme.palette.severities.info,
      alerts: 0,
      tooltip: "top",
      status: "Not Connected",
      lastUpdated: "",
    },
    {
      icon: <AccountBalanceIcon sx={{ color: theme.palette.common.white }} />,
      top: "410px",
      left: "300px",
      title: "Bank account - Statements",
      backgroundColor: theme.palette.severities.info,
      alerts: 0,
      tooltip: "top-start",
      status: "Not Connected",
      lastUpdated: "",
    },
    {
      icon: <ChecklistIcon sx={{ color: theme.palette.common.white }} />,
      top: "110px",
      left: "200px",
      title: "Vendor Onboarding",
      backgroundColor: theme.palette.primary.main,
      alerts: 2,
      tooltip: "left",
      status: "Connected",
      lastUpdated: "3 hours ago",
    },
    {
      icon: <ContactMailIcon sx={{ color: theme.palette.common.white }} />,
      top: "0px",
      left: "120px",
      title: "Email - E-Discovery",
      backgroundColor: theme.palette.severities.info,
      alerts: 0,
      tooltip: "left",
      status: "Not Connected",
      lastUpdated: "",
    },
    {
      icon: <MailIcon sx={{ color: theme.palette.common.white }} />,
      top: "280px",
      left: "150px",
      title: "Email - AP Inbox",
      backgroundColor: theme.palette.primary.main,
      alerts: 0,
      tooltip: "bottom",
      status: "Connected",
      lastUpdated: "1 day ago",
    },
    {
      icon: <CollectionsBookmarkIcon sx={{ color: theme.palette.common.white }} />,
      top: "200px",
      left: "40px",
      title: "ERP - Ledger",
      backgroundColor: theme.palette.severities.medium,
      alerts: 0,
      tooltip: "bottom-end",
      status: "Uploaded File",
      lastUpdated: "9 days ago",
    },
    {
      icon: <AttachMoneyIcon sx={{ color: theme.palette.common.white }} />,
      top: "180px",
      left: "300px",
      title: "ERP - EFT",
      backgroundColor: theme.palette.primary.main,
      alerts: 9,
      tooltip: "bottom",
      status: "Connected",
      lastUpdated: "Just Now",
    },
    {
      icon: <ListAltIcon sx={{ color: theme.palette.common.white }} />,
      top: "60px",
      left: "240px",
      title: "ERP - MVF",
      backgroundColor: theme.palette.severities.medium,
      alerts: 147,
      tooltip: "top-start",
      status: "Uploaded File",
      lastUpdated: "8 days ago",
    },
  ]

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))

  return (
    <Box sx={{ padding: "0 2rem" }}>
      <Typography variant={"h5"} sx={{ fontWeight: 400, textAlign: "left", marginBottom: "1rem" }}>
        Coverage
      </Typography>

      <Paper sx={{ padding: "1rem", height: "600px", marginBottom: "1rem" }}>
        <Stack direction={"row"} paddingTop={"1rem"}>
          <Box
            component={"div"}
            sx={{
              height: "0.75rem",
              width: "0.75rem",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "50%",
              verticalAlign: "middle",
              margin: "auto 0.5rem auto 0",
            }}
          />
          <Typography variant={"body1"}>Covered (Up-To-Date Data)</Typography>
          <Box
            component={"div"}
            sx={{
              height: "0.75rem",
              width: "0.75rem",
              backgroundColor: theme.palette.severities.info,
              borderRadius: "50%",
              verticalAlign: "middle",
              margin: "auto 0.5rem auto 1rem",
            }}
          />
          <Typography variant={"body1"}>Uncovered (No data)</Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Box component={"div"} className={classes.outerCircle}>
            <Box component={"div"} className={classes.middleCircle} />
            <Box component={"div"} className={classes.innerCircle} />
            {items.map((i) => (
              <LightTooltip
                key={i.title}
                open={true}
                // @ts-ignore
                placement={i.tooltip}
                title={
                  <Box padding={"0.1rem 0.2rem"}>
                    <Typography color="inherit" variant={"subtitle2"}>
                      {`${i.title}`}
                    </Typography>
                    <Typography color="inherit" sx={{ fontSize: "0.75rem" }}>
                      {i.lastUpdated}
                    </Typography>
                  </Box>
                }
              >
                <StyledBadge
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    top: i.top,
                    left: i.left,
                    "& .MuiBadge-badge": {
                      backgroundColor:
                        i.status === "Connected"
                          ? "#44b700"
                          : i.status === "Not Connected"
                            ? "rgba(0,0,0,0)"
                            : "#ff9100",
                      color:
                        i.status === "Connected"
                          ? "#44b700"
                          : i.status === "Not Connected"
                            ? "rgba(0,0,0,0)"
                            : "#ff9100",
                      boxShadow: `0 0 0 2px ${i.status === "Not Connected" ? "rgba(0,0,0,0)" : theme.palette.background.paper}`,
                    },
                  }}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    alt={i.title}
                    sx={{
                      backgroundColor: i.backgroundColor,
                      cursor: "pointer",
                    }}
                  >
                    {i.icon}
                  </Avatar>
                </StyledBadge>
              </LightTooltip>
            ))}
          </Box>
          <Box>
            <Stack direction={"column"}>
              {creednzItems.map((i) => (
                <CardHeader
                  key={i.title}
                  sx={{ padding: "0.5rem 0" }}
                  avatar={
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatar
                        sx={{
                          backgroundColor: i.backgroundColor,
                          cursor: "pointer",
                        }}
                        alt={i.title}
                      >
                        {i.icon}
                      </Avatar>
                    </StyledBadge>
                  }
                  title={i.title}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Box>
  )
}

export default Coverage
