import React, { useEffect, useState } from "react"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import TextField from "@mui/material/TextField"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import { ITheme } from "theme/theme"
import { NavigateFunction, useNavigate } from "react-router"
import { eprIntegrationCompanies } from "./common"
import { useAuthControllerGetProfile, authControllerDisconnectMicrosoftAccount } from "../../apis/endpoints/edgeService"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"

const useStyles = makeStyles()((theme: ITheme) => ({
  integration: {
    padding: "0 1rem",
    width: "100%",
  },
  content: {
    height: "89vh",
    overflow: "auto",
  },
  description: {
    marginTop: "0.5rem",
    color: theme.palette.grey["600"],
  },
  continueButton: {
    margin: "1rem 0",
  },
  icon: {
    marginRight: "0.5rem",
  },
  paper: {
    height: "auto",
    marginTop: "0.5rem",
    padding: "1rem",
  },
  integrationChoiceERP: {
    backgroundColor: theme.palette.grey["50"],
    borderRadius: 4,
    width: "8rem",
    height: "5rem",
    margin: "0.5rem",
    cursor: "pointer",
    padding: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.grey["200"],
    },
  },
  integrationLogos: {
    marginTop: "1rem",
    paddingLeft: "1.2rem",
  },
  emailIntegrationDescription: {
    color: theme.palette.grey["600"],
  },
}))

const KeywordsManager = ({
  keywords,
  setKeywords,
  onSubmit,
}: {
  keywords: string[]
  setKeywords: React.Dispatch<React.SetStateAction<string[]>>
  onSubmit: (keywords: string[]) => void
}) => {
  const [newKeyword, setNewKeyword] = useState<string>("")
  const { classes } = useStyles()

  const handleAddKeyword = () => {
    if (newKeyword && !keywords.includes(newKeyword)) {
      const updatedKeywords = [...keywords, newKeyword]
      setKeywords(updatedKeywords)
      onSubmit(updatedKeywords)
      setNewKeyword("")
    }
  }

  const handleKeywordDelete = (keywordToDelete: string) => {
    const updatedKeywords = keywords.filter((keyword) => keyword !== keywordToDelete)
    setKeywords(updatedKeywords)
    onSubmit(updatedKeywords)
  }

  return (
    <Box>
      <Box display="flex" gap={1} style={{ marginBottom: "1rem" }}>
        <Typography variant="subtitle1">Manage Keywords for Email Polling</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title="Emails will be fetched if they contain any of these keywords in their subject or body.">
            <InfoIcon color="action" />
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <TextField
          type="text"
          value={newKeyword}
          label="Add new keyword"
          inputProps={{ maxLength: 1000 }}
          size="small"
          variant="outlined"
          onChange={(e) => setNewKeyword(e.target.value)}
          placeholder="New keyword"
        />
        <Button onClick={handleAddKeyword} variant="contained" size="small">
          +
        </Button>
      </Box>
      <List>
        {keywords.map((keyword, index) => (
          <ListItem key={index} style={{ margin: 0, padding: 0 }}>
            <IconButton aria-label="delete" onClick={() => handleKeywordDelete(keyword)} style={{ padding: 0 }}>
              <DeleteIcon fontSize="small" style={{ padding: 0 }} />
            </IconButton>
            <ListItemText primary={keyword} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const ConnectedEmailsList = ({
  emails,
  handleDisconnectEmail,
}: {
  emails: string[]
  handleDisconnectEmail: (email: string) => void
}) => (
  <Box>
    <Typography variant="subtitle1">Connected Emails</Typography>
    <List>
      {emails.map((email: string, index: number) => (
        <ListItem key={index} style={{ margin: 0, padding: 0 }}>
          <IconButton aria-label="delete" onClick={() => handleDisconnectEmail(email)} style={{ padding: 0 }}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <ListItemText primary={email} style={{ padding: 0 }} />
        </ListItem>
      ))}
    </List>
  </Box>
)

const EmailIntegration = () => {
  const { classes } = useStyles()
  const [keywords, setKeywords] = useState<string[]>([])
  const [connectedEmails, setConnectedEmails] = useState<string[]>([])
  const { data: userProfile, isLoading: isProfileLoading } = useAuthControllerGetProfile()

  useEffect(() => {
    if (!isProfileLoading && userProfile?.company) {
      const emailsFromProfile = userProfile.company.connectedEmailAddresses
      if (emailsFromProfile) {
        setConnectedEmails(emailsFromProfile)
        const keywordsFromProfile = userProfile.company.connectedEmailKeywords
        if (keywordsFromProfile) {
          setKeywords(keywordsFromProfile)
        }
      }
    }
  }, [isProfileLoading, userProfile])

  const handleMicrosoftAuth = () => {
    const companyId = userProfile?.company?.id
    const authUrl = `${process.env.REACT_APP_EDGE_SERVICE_URL}auth/microsoft/login?companyId=${companyId}`
    window.location.href = authUrl
  }

  const handleDisconnectEmail = async (email: string) => {
    const confirmDisconnect = window.confirm(`Are you sure you want to disconnect the email: ${email}?`)

    if (confirmDisconnect) {
      try {
        await authControllerDisconnectMicrosoftAccount(email)
        setConnectedEmails((currentEmails) => currentEmails.filter((e) => e !== email))
      } catch (error) {
        alert("Error disconnecting email!")
        console.error(error)
      }
    }
  }

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center">
          <MarkEmailReadOutlinedIcon className={classes.icon} fontSize="small" />
          <Typography variant="body1">Microsoft365 Email Account Management</Typography>
        </Box>
        <Typography variant="body2" className={classes.emailIntegrationDescription}>
          Enhance security with our Microsoft365 email integration. Utilize custom keyword polling to activate risk
          analyzers and monitoring algorithms, ensuring robust fraud protection and maintaining customer trust.
        </Typography>
        <Box>
          <Button variant="contained" size="small" color="primary" onClick={handleMicrosoftAuth}>
            Connect Email Inbox
          </Button>
        </Box>
        <Stack direction="column" spacing={2}>
          <Box>
            {connectedEmails.length > 0 && (
              <ConnectedEmailsList emails={connectedEmails} handleDisconnectEmail={handleDisconnectEmail} />
            )}
          </Box>
          <Divider orientation="horizontal" flexItem />
          <Box>
            {connectedEmails.length > 0 && (
              <KeywordsManager keywords={keywords} setKeywords={setKeywords} onSubmit={setKeywords} />
            )}
          </Box>
        </Stack>
      </Box>
    </Paper>
  )
}

const Integrations = (): JSX.Element => {
  const { classes } = useStyles()

  const navigate: NavigateFunction = useNavigate()

  const navigateTo = (url: string): void => {
    navigate(url)
  }

  return (
    <Grid container className={classes.integration}>
      <Typography variant="h6">Enterprise and Enterprise Integrations</Typography>
      <Grid item xs={12} className={classes.content}>
        <Grid item>
          <EmailIntegration />
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Box display="flex" alignItems="center">
              <AccountBalanceIcon className={classes.icon} fontSize="small" />
              <Typography variant="body1">Financial institution</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.description}>
              We're here to support you globally and locally.
            </Typography>
            <Typography variant="subtitle2" className={classes.description}>
              We support over 11,500 institutions across North America and Europe, mainly in the United States (US) and
              United Kingdom (GB).
            </Typography>
            <Typography variant="subtitle2" className={classes.description}>
              Access 24 months of customer transaction data, including enhanced geolocation, merchant, and category
              information.
            </Typography>
            <Button
              variant={"outlined"}
              size={"small"}
              className={classes.continueButton}
              onClick={navigateTo.bind(null, "fastlink")}
            >
              Connect Institution
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Box display="flex" alignItems="center">
              <AccountBalanceIcon className={classes.icon} fontSize="small" />
              <Typography variant="body1">Vendor Management</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.description}>
              By uploading your Vendor Management list or integrating your Vendor Management System.
            </Typography>
            <Typography variant="subtitle2" className={classes.description}>
              We add additional indicators and notifications to the fraud detection scheme engine, such as when the
              master vendor was updated, when the master record must be updated, or when there's insufficient
              information, etc.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              className={classes.continueButton}
              onClick={navigateTo.bind(null, "upload-mvf")}
            >
              Upload (CSV, XLS...) Vendor Management List
            </Button>
            <Typography variant="subtitle2" className={classes.description}>
              Or
            </Typography>
            <Button disabled variant="outlined" size="small" className={classes.continueButton}>
              Connect Vendor Management System
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Box display="flex" alignItems="center">
              <IntegrationInstructionsIcon className={classes.icon} fontSize="small" />
              <Typography variant="body1">Event Management (SIEM)</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.description}>
              Integrate with SIEM applications to:
            </Typography>
            <Box>
              <Typography component="div" variant="caption" className={classes.description}>
                Incorporate SIEM data and enrich transactions
              </Typography>
              <Box />
              <Typography variant="caption" className={classes.description}>
                Send audit logs through and create a complete audit picture in the enterprise SIEM
              </Typography>
            </Box>
            <Button
              disabled
              className={classes.continueButton}
              variant="outlined"
              size="small"
              onClick={navigateTo.bind(null, "beneficiaries")}
            >
              Connect SIEM
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Box display="flex" alignItems="center">
              <ManageAccountsIcon className={classes.icon} fontSize="small" />
              <Typography variant="body1">ERP & Accounting Software</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.description}>
              Connect with secure pre-built integrations or our full-featured API.
            </Typography>
            <Typography variant="subtitle2" className={classes.description}>
              Mass payment and accounts payable automation software that integrates with NetSuite, Sage Intacct,
              Quickbooks & More.
            </Typography>
            <Button
              disabled
              variant="outlined"
              size="small"
              className={classes.continueButton}
              onClick={navigateTo.bind(null, "beneficiaries")}
            >
              Connect ERP & Accounting
            </Button>
            <Box>
              <Typography variant="caption" className={classes.description}>
                Integrate via API with the following ERPs (Plus, fiat-file integration):
              </Typography>
            </Box>
            <Grid container direction="row" spacing={3} className={classes.integrationLogos} display="flex">
              {eprIntegrationCompanies.map(
                (company: any): JSX.Element => (
                  <Box key={company.name} className={classes.integrationChoiceERP} display={"flex"} alignItems="center">
                    <img src={company.logo} width="90rem" alt="company-logo" />
                  </Box>
                ),
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Box display="flex" alignItems="center">
              <IntegrationInstructionsIcon className={classes.icon} fontSize="small" />
              <Typography variant="body1">Sandbox</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.description}>
              Use the Sandbox with sample data from up to 100 financial accounts.
            </Typography>
            <Button
              disabled
              className={classes.continueButton}
              variant="outlined"
              size="small"
              onClick={navigateTo.bind(null, "beneficiaries")}
            >
              Explore Sandbox
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Integrations
