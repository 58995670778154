import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { FindingDto } from "../../apis/model"
import FieldWithValue from "../../common/FieldWithValue"

const useStyles = makeStyles()(() => ({
  expand: {
    padding: "1.5rem",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0.75rem",
  },
  subTitle: {
    padding: "0.5rem 0",
  },
  linkRa: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}))

interface IProps {
  row: FindingDto
}
const ExpandIndicators = ({ row }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Grid container className={classes.expand} data-testid={"expand"}>
      <Grid item xs={12} md={10}>
        <FieldWithValue name={t.General.remediation} value={row.remediation} testId={"remediation"} />
      </Grid>
    </Grid>
  )
}

export default ExpandIndicators
