import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import React from "react"
import { ITheme } from "theme/theme"
import FieldWithValue from "../../common/FieldWithValue"

const useStyles = makeStyles()(() => ({
  expand: {
    padding: "1.5rem",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0.75rem",
  },
}))

interface IProps {
  row: any
}
const ExpandPayment = ({ row }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Grid container className={classes.expand}>
      <Grid item xs={12} md={5}>
        <FieldWithValue name={t.General.abaNumber} value={row.abaNumber} />
        <FieldWithValue name={t.General.payeeBankAccountNumber} value={row.payeeBankAccountNumber} />
      </Grid>
    </Grid>
  )
}

export default ExpandPayment
