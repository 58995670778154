import * as React from "react"
import { useTheme } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import Skeleton from "@mui/material/Skeleton"
import Toolbar from "@mui/material/Toolbar"
import { useAuthControllerGetProfile } from "apis/endpoints/edgeService"
import { makeStyles } from "makeStyles"
import { useNavigate } from "react-router"
import { ITheme } from "theme/theme"
import SideDrawer from "./SideDrawer"
import { useLocation } from "react-router-dom"
import Button from "@mui/material/Button"
import ActionDrawer from "./ActionDrawer"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import BreadcrumbsNav from "./BreadcrumbsNav"
import DarkMode from "images/dark-toggle.svg"
import LightMode from "images/light-toggle.svg"
// import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
// import MoneyRecoveryDrawer from "./MoneyRecoveryDrawer"

const drawerWidth = 218
const useStyles = makeStyles()((theme: ITheme) => ({
  mainBox: {
    display: "flex",
  },
  appbar: {
    width: `calc(100vw - (${theme.spacing(10)} + 1px))`,
    height: "5rem",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    minHeight: "5rem",
    "@media (min-width: 0px) and (orientation: landscape)": {
      minHeight: "5rem",
    },
    "@media (min-width: 600px)": {
      minHeight: "5rem",
    },
  },
  appbarOpen: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  user: {
    cursor: "pointer",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logo: {
    fontSize: "1.8rem",
    width: "160px",
    cursor: "pointer",
    fontFamily: "Montserrat Alternates, sans-serif",
    color: theme.palette.primary.main,
  },
  upload: {
    cursor: "pointer",
    marginLeft: "1rem",
  },
}))

interface IProps {
  children: JSX.Element
  changeMode: () => void
  mode: string
}

const Header = ({ children, changeMode, mode }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = React.useState(localStorage.getItem("side") !== "false")
  const [openImport, setOpenImport] = React.useState(false)
  // const [openMoneyRecovery, setOpenMoneyRecovery] = React.useState(false)

  const { data, isLoading, refetch } = useAuthControllerGetProfile()

  const navigateTo = (url: string): void => navigate(url)

  const toggleSideBar = (value: boolean) => {
    localStorage.setItem("side", String(value))
    setOpen(value)
  }

  const openImportDialog = () => setOpenImport(true)
  //
  // const openMoneyRecoveryDialog = () => setOpenMoneyRecovery(true)
  // const closeMoneyRecoveryDialog = () => setOpenMoneyRecovery(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }
    setOpenImport(open)
  }

  // const toggleRecovery = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
  //   ) {
  //     return
  //   }
  //   setOpenMoneyRecovery(open)
  // }

  if (isLoading || !data) return <Skeleton variant="rectangular" width="100vw" height="3.5rem" data-testid="loading" />

  return (
    <Box className={classes.mainBox}>
      <AppBar
        data-testid="header-appbar"
        position="fixed"
        className={open ? classes.appbarOpen : classes.appbar}
        style={{
          borderRadius: 0,
          background: "transparent",
          boxShadow: "none",
          backgroundColor: !location.pathname.includes("admin")
            ? theme.palette.background.default
            : theme.palette.common.black,
        }}
      >
        <Toolbar className={classes.toolbar}>
          {!location.pathname.includes("admin") && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
              <BreadcrumbsNav company={data.company} user={data.user} />
            </Box>
          )}

          {location.pathname.includes("admin") && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
              <Button variant={"outlined"} onClick={navigateTo.bind(null, "/")}>
                {t.General.exitSettings}
              </Button>
            </Box>
          )}
          {!location.pathname.includes("admin") && (
            <Box sx={{ flexGrow: 0, display: { xs: "flex" } }}>
              <img
                src={mode === "dark" ? DarkMode : LightMode}
                alt={"mode"}
                onClick={changeMode}
                style={{ height: "2.2rem", cursor: "pointer" }}
              />
            </Box>
          )}

          {location.pathname === "/" && (
            <Box sx={{ flexGrow: 0, flexDirection: "row" }}>
              <Grid container direction="row" className={classes.user}>
                <Button
                  data-testid={"upload-files"}
                  variant="contained"
                  startIcon={<FilePresentIcon fontSize={"small"} />}
                  onClick={openImportDialog}
                  className={classes.upload}
                >
                  Upload File
                </Button>
                {/*<Button*/}
                {/*  data-testid={"money-recovery"}*/}
                {/*  variant="outlined"*/}
                {/*  startIcon={<CurrencyExchangeIcon fontSize={"small"} />}*/}
                {/*  onClick={openMoneyRecoveryDialog}*/}
                {/*  className={classes.upload}*/}
                {/*>*/}
                {/*  Money Recovery*/}
                {/*</Button>*/}
              </Grid>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <SideDrawer open={open} children={children} me={data} refetch={refetch} toggleSideBar={toggleSideBar} />
      <SwipeableDrawer
        style={{ zIndex: 1202 }}
        anchor={"right"}
        open={openImport}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        BackdropProps={{ invisible: true }}
      >
        <ActionDrawer openImport={openImport} />
      </SwipeableDrawer>
      {/*<MoneyRecoveryDrawer openImport={openMoneyRecovery} close={closeMoneyRecoveryDialog} />*/}
    </Box>
  )
}

export default Header
