import React, { useContext, useState } from "react"

interface IAuthorizedUserPermissionsInterface {
  permissions?: string[]
  isGranted: (askedPermission: string) => boolean
  isRole: (askedRole: string) => boolean
  setUserRole: (roles: string[], permissions: string[]) => void
}
export const PermissionContext = React.createContext({} as IAuthorizedUserPermissionsInterface)

export const usePermission = () => useContext(PermissionContext)

export const AuthorizedUserPermissionsProvider = ({ children }: any) => {
  const [permissions, setPermission] = useState<string[]>([])
  const [roles, setRoles] = useState<string[]>([])

  const isGranted = (askedPermission: string): boolean => permissions.includes(askedPermission)

  const isRole = (askedRole: string): boolean => roles.includes(askedRole)

  const setUserRole = (roles: string[], permissions: string[]): void => {
    setRoles(roles)
    setPermission(permissions)
  }

  return (
    <PermissionContext.Provider value={{ permissions, isGranted, isRole, setUserRole }}>
      {children}
    </PermissionContext.Provider>
  )
}
