import React from "react"
import Typography from "@mui/material/Typography"
import { makeStyles } from "../makeStyles"
import { ITheme } from "../theme/theme"
import Stack from "@mui/material/Stack"

const useStyles = makeStyles()((theme: ITheme) => ({
  field: {
    padding: "0.4rem 0",
  },
  name: {
    color: theme.palette.secondary.main,
    paddingRight: "0.4rem",
  },
  value: {
    padding: "0 5px",
    marginRight: "0.5rem",
  },
}))

interface IProps {
  name: string
  value: string | number | undefined
  style?: any
  testId?: string
  icon?: JSX.Element
}

const FieldWithValue = ({ name, value, style, testId, icon }: IProps) => {
  const { classes } = useStyles()
  return (
    <Stack direction={"row"} className={classes.field} data-testid={testId || "text-cell"}>
      <Typography variant="body1" display={"inline"} className={classes.name}>
        {name}:
      </Typography>
      <Typography variant="body1" display={"inline"} sx={style} className={classes.value}>
        {value || "-"}
      </Typography>
      {icon}
    </Stack>
  )
}

export default FieldWithValue
