import React, { useEffect, useRef, useState } from "react"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Box from "@mui/material/Box"
import { AXIOS_INSTANCE } from "../apis/mutator/custom-instance"
import LoadingButton from "@mui/lab/LoadingButton"
import { UploadType } from "../utils/types"

const useStyles = makeStyles()((theme: ITheme) => ({
  formFileUpload: {
    padding: "2rem",
    height: "15rem",
    width: "30rem",
    margin: "auto",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
    "@media (max-width: 600px)": {
      height: "18rem",
    },
  },
  formFileSideUpload: {
    padding: "2rem",
    height: "13rem",
    width: "18rem",
    margin: "auto",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
    "@media (max-width: 600px)": {
      height: "18rem",
    },
  },
  labelFileUpload: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: theme.palette.grey["500"],
  },
  dragFileElement: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  error: {
    color: theme.palette.error.main,
    margin: "2rem",
    fontStyle: "italic",
  },
  btn: {
    marginTop: "5rem",
    float: "right",
    marginRight: "4rem",
  },
  file: {
    marginTop: "0.5rem",
  },
  successMessage: {
    color: theme.palette.success.main,
    margin: "0 auto",
    fontStyle: "italic",
  },
}))

interface IProps {
  continueToNextStep?: () => void
  type: UploadType
  emptyFile?: boolean
}

const Upload = ({ continueToNextStep, type, emptyFile }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const [dragActive, setDragActive] = useState<boolean>(false)
  const [selectedFile, setSelectFile] = useState<File | null>(null)
  const [errors, setErrors] = useState<string>("")
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const inputRef = useRef(null)

  const onButtonClick = (): void => {
    //@ts-ignore
    inputRef.current.click()
  }

  useEffect(() => {
    emptyFile && setSelectFile(null)
  }, [emptyFile])

  const handleDrag = (e: any): void => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files)
      setSelectFile(e.dataTransfer.files[0])
      console.log("drop", e.dataTransfer.files[0])
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setSelectFile(e.target.files[0])
      validateFile(e.target.files[0])
    }
  }

  const validateFile = (file: File): void => {
    if (file.size > 100485760) {
      // 10MB
      setErrors(t.Errors.maxFileSizeIs10MB)
    }
  }

  const handleUpload = async (): Promise<void> => {
    setIsUploading(true)

    if (selectedFile) {
      setIsUploading(true)
      try {
        const formData = new FormData()
        formData.append("file", selectedFile)
        await AXIOS_INSTANCE.post(`upload/${type}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res: any): void => {
            setSuccessMessage("File uploaded successfully")
            setIsUploading(false)
            continueToNextStep && continueToNextStep()
          })
          .catch((e): void => {
            setErrors(e.toString())
            setIsUploading(false)
          })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} className={classes.formFileUpload}>
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={false}
                hidden
                onChange={handleChange}
                accept=".xlsx, .xls, .csv"
              />
              <label id="label-file-upload" htmlFor="input-file-upload" className={classes.labelFileUpload}>
                <Stack spacing={3}>
                  <CloudUploadIcon fontSize="large" style={{ margin: "auto" }} />
                  <Typography variant="body2">{t.General.dragAndDropYourFileHereOr}</Typography>
                  <Button onClick={onButtonClick} variant="text" data-testid={"upload-title"}>
                    {t.General.uploadAFile}
                  </Button>
                </Stack>
              </label>
              {dragActive && (
                <Box
                  className={classes.dragFileElement}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></Box>
              )}
              {selectedFile && (
                <Stack spacing={1} className={classes.file}>
                  <Typography variant="subtitle2" gutterBottom>{`File name: ${selectedFile.name} (${
                    selectedFile.size / 1000
                  }kb)`}</Typography>
                  {errors !== "" && !successMessage && (
                    <Typography variant="body1" className={classes.error}>
                      {errors}
                    </Typography>
                  )}
                  {successMessage && (
                    <Typography variant="body1" className={classes.successMessage}>
                      {successMessage}
                    </Typography>
                  )}
                </Stack>
              )}
            </form>
          </Grid>
          <Grid item xs={12} md={12} justifyContent={"center"}>
            <LoadingButton
              data-testid={"upload-btn"}
              size="medium"
              color="primary"
              variant="contained"
              loading={isUploading}
              className={classes.btn}
              onClick={handleUpload}
            >
              {t.General.upload}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Upload
