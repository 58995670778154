import React from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import TableTextCell from "common/TableTextCell"
import { formatDate } from "utils/utils"
import TablePageSkeleton from "common/TablePageSkeleton"
import { NavigateFunction, useNavigate } from "react-router"
import { dataGridSx, slotProps } from "theme/data-grid"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import { useAuthControllerGetProfile } from "../../apis/endpoints/edgeService"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"

const useStyles = makeStyles()(() => ({
  paymentsAlerts: {
    padding: "0rem 1rem",
  },
  paper: {
    padding: "1rem",
  },
  title: {
    fontWeight: 400,
  },
  table: {
    height: "calc(80vh)",
  },
}))

const PaymentsAlerts = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const data = {
    topVendorsAffected: [
      {
        id: 833,
        name: "K Line (Europe) Limited",
        count: 0,
        Critical: 0,
        High: 4,
        Medium: 2,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 835,
        name: "K Line (Eu) Limited",
        count: 0,
        Critical: 0,
        High: 2,
        Medium: 1,
        Low: 1,
        Informational: 0,
        Success: 0,
      },
      {
        id: 850,
        name: "Property investments limited",
        count: 0,
        Critical: 0,
        High: 2,
        Medium: 1,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 845,
        name: "Nspired Ltd",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 2,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 886,
        name: "Bsp Retail Limited",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 2,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 923,
        name: "K Laundry Limited",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 2,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 838,
        name: "C&R Construction South West Ltd",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 1,
        Low: 1,
        Informational: 0,
        Success: 0,
      },
      {
        id: 836,
        name: "H.A.R.D.T. Group Gmbh",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 1,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 844,
        name: "Nspired Investments Ltd",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 1,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 847,
        name: "Obapiipc Limited",
        count: 0,
        Critical: 0,
        High: 1,
        Medium: 1,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
    ],
    indicationsByName: [
      {
        name: "Vendor is not registered",
        count: 63,
        Critical: 0,
        High: 28,
        Medium: 35,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Duplicate vendor details",
        count: 8,
        Critical: 0,
        High: 4,
        Medium: 0,
        Low: 4,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Lookalike vendor name",
        count: 2,
        Critical: 0,
        High: 2,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Duplicate wire instruction",
        count: 8,
        Critical: 0,
        High: 2,
        Medium: 6,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Vendor contact email has been compromised",
        count: 2,
        Critical: 0,
        High: 2,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Vendor address include po box address only",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Non-business Email in a Look-alike Vendor",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Cross-Customer vendor details verification",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Company not active",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Invalid account number",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: " Invalid Routing Number",
        count: 34,
        Critical: 0,
        High: 0,
        Medium: 34,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        name: "No data reported account",
        count: 20,
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 20,
        Informational: 0,
        Success: 0,
      },
      {
        name: "Missing vendor details",
        count: 3,
        Critical: 0,
        High: 0,
        Medium: 3,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
    ],
    topPaymentsAffected: [
      {
        id: 193,
        amount: 52826.4,
        currency: "USD",
        findingList: [
          {
            id: 25888,
            name: "Unvalidated Bank Account Transaction",
            description:
              "A transaction is flagged for being directed to a bank account that hasn't undergone our validation process. This may indicate that the recipient bank operates outside the established global network",
            createdAt: "2024-02-07T07:00:38.975Z",
          },
        ],
        name: "Heal Ur Tech Ltd",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 201,
        amount: 582.48,
        currency: "USD",
        findingList: [
          {
            id: 25949,
            name: "Transaction with Sanctioned Entity",
            description:
              "A payment directed to Nspired Ltd, identified by 7150856, has been flagged as the entity is listed on the OFAC sanctions list as of 1/22/2024. Review and adhere to internal policies to avoid potential fines and legal implications",
            createdAt: "2024-02-07T07:00:44.466Z",
          },
        ],
        name: "Nspired Ltd",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 203,
        amount: 850,
        currency: "USD",
        findingList: [
          {
            id: 25966,
            name: "Master File Banking Detail Mismatch",
            description:
              "A payment to Customs Agents Limited, bank account 51008922, lacks corresponding documentation in the Master Vendor File. Verification of the payment's origin is recommended\n",
            createdAt: "2024-02-07T07:00:46.394Z",
          },
        ],
        name: "Customs Agents Limited",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 206,
        amount: 41600,
        currency: "USD",
        findingList: [
          {
            id: 25989,
            name: "Cyber Breach Vendor Bank Change",
            description:
              "A recent change in banking details has been detected for Property investments limited, with a pending payment of 41,600 USD. This vendor has indications of a potential cyber breach. Enhanced validation is advised due to increased risk\n",
            createdAt: "2024-02-07T07:00:48.877Z",
          },
        ],
        name: "Property investments limited",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 212,
        amount: 3000,
        currency: "USD",
        findingList: [
          {
            id: 26050,
            name: "Vendor Bank Account Discrepancy",
            description:
              "A discrepancy has been detected between the bank account details for a payment to Felda Europe S.r.l. and the records in your vendor master. Verification is needed to resolve the mismatch",
            createdAt: "2024-02-07T07:00:54.859Z",
          },
        ],
        name: "Felda Europe S.r.l.",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
      {
        id: 219,
        amount: 8761,
        currency: "USD",
        findingList: [
          {
            id: 26112,
            name: "Look-alike Vendor First Payment",
            description:
              "A first-time payment to K Line (Europe) Limited is detected, bearing similarities to another entity in your Vendor Master K Line (Eu) Limited. It is recommended to scrutinize the record to prevent potential fraud\n",
            createdAt: "2024-02-07T07:01:03.026Z",
          },
        ],
        name: "K Line (Europe) Limited",
        count: 1,
        Critical: 0,
        High: 1,
        Medium: 0,
        Low: 0,
        Informational: 0,
        Success: 0,
      },
    ],
    company: {
      vendorFindings: {
        High: 49,
        Medium: 78,
        Low: 24,
        Informational: 3,
        Success: 2720,
        Critical: 0,
      },
      dataProcessing: {
        vendors: 2634,
        payments: 240,
        emails: 0,
      },
      vendors: 91,
      payments: 27,
      emails: 0,
      vendorsAlerts: 145,
      paymentsAlerts: 9,
      emailsAlerts: 0,
    },
    paymentsIndications: [
      {
        entityType: "Finding",
        id: 26021,
        createdAt: "2024-02-07T07:00:52.349Z",
        updatedAt: "2024-02-07T07:00:52.349Z",
        examinedEntityType: "Payment",
        examinedEntityId: 209,
        examinedEntityName: null,
        indicator: "RA_59",
        companyId: 1,
        title: "Vendor Payment Instruction Missing",
        description:
          "An outgoing payment to Eveerge Limited is detected without complete payment instructions in the vendor's master record, posing a risk as the origin of bank details is unclear\n",
        remediation: "Check for vendors data in MVF",
        riskScore: "Informational",
        riskAnalyzerCategory: "Bank account validation",
        riskAnalyzerSubCategory: "Account activity",
      },
      {
        entityType: "Finding",
        id: 26037,
        createdAt: "2024-02-07T07:00:53.629Z",
        updatedAt: "2024-02-07T07:00:53.629Z",
        examinedEntityType: "Payment",
        examinedEntityId: 211,
        examinedEntityName: null,
        indicator: "RA_58",
        companyId: 1,
        title: "High Velocity of Payments",
        description: "The number of payments in recent 72 hours is relative high",
        remediation: "Verify authenticity of payments ",
        riskScore: "Informational",
        riskAnalyzerCategory: "Transaction analysis",
        riskAnalyzerSubCategory: "Inspect vendors with suspicious attributes",
      },
      {
        entityType: "Finding",
        id: 26105,
        createdAt: "2024-02-07T07:01:01.677Z",
        updatedAt: "2024-02-07T07:01:01.677Z",
        examinedEntityType: "Payment",
        examinedEntityId: 218,
        examinedEntityName: null,
        indicator: "RA_60",
        companyId: 1,
        title: "Inconsistency with documenting banking details",
        description: "Creednz has located 64 records that appear to be missing payment details.",
        remediation: "Check for payment data ",
        riskScore: "Informational",
        riskAnalyzerCategory: "Beneficiary information validation",
        riskAnalyzerSubCategory: "Business details",
      },
    ],
  }

  const { data: meData, isLoading: isLoadingMe } = useAuthControllerGetProfile()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const navigate: NavigateFunction = useNavigate()

  const handleClose = () => setAnchorEl(null)

  const navigateTo = (url: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    navigate(url)
  }

  const ExpandableCell = ({ description }: { description: string }) => {
    const [expanded, setExpanded] = React.useState(false)

    return (
      <Box component="div" key={description} sx={{ marginBottom: "0.5rem" }}>
        {expanded ? description : `${description.slice(0, 0)}`}
        {description.length > 0 && (
          <Link
            sx={{ paddingLeft: expanded ? "0.5rem" : 0, fontSize: "0.875", cursor: "pointer" }}
            type="button"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </Box>
    )
  }

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "findingList",
      headerName: "Alert",
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <TableTextCell
            content={formatDate(params.row.findingList[0].createdAt)}
            style={{ color: theme.palette.grey["700"], padding: "0.7rem 0 0.2rem 0" }}
          />
          <Typography gutterBottom fontWeight={400} variant={"h6"}>
            {params.value[0].name}
          </Typography>
          <ExpandableCell description={params.value[0].description} />
        </Box>
      ),
    },
    {
      headerName: "Submitted By",
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={meData?.user.name || ""} />,
    },
    {
      field: "name",
      headerName: t.General.payeeName,
      flex: 0.4,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "amount",
      headerName: t.General.amount,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={`${params.value.toLocaleString()} ${params.row.currency}`} />
      ),
    },
    {
      field: "findingList.id",
      headerName: t.General.actions,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            color={"error"}
            variant={"contained"}
            sx={{ backgroundColor: theme.palette.severities.high, padding: "0 1rem" }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Action
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack sx={{ width: "17rem" }}>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Hold</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Inspect</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Upload to bank</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Ignore</Typography>
            </Stack>
          </Popover>
          <Button
            // size="small"
            variant={"outlined"}
            onClick={navigateTo.bind(
              null,
              `/indicators/${params?.row.findingList ? params?.row.findingList[0].id : 0}`,
            )}
          >
            Details
          </Button>
        </Stack>
      ),
    },
  ]

  if (!data || !meData || isLoadingMe) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.paymentsAlerts} spacing={3} data-testid={"indicators"}>
      <Grid item xs={12}>
        <Typography variant="h5" data-testid={"title"} className={classes.title}>
          {`Payments Risk Alerts`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <DataGridPro
              disableColumnSelector
              disableDensitySelector
              rows={data.topPaymentsAffected || []}
              columns={columns}
              slotProps={slotProps}
              checkboxSelection
              disableRowSelectionOnClick={true}
              sx={{
                ...dataGridSx,
              }}
              getEstimatedRowHeight={() => 150}
              getRowHeight={() => "auto"}
              scrollbarSize={0}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PaymentsAlerts
