import React from "react"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import { Chip, useTheme } from "@mui/material"
import TimelineItem from "@mui/lab/TimelineItem"
import Timeline from "@mui/lab/Timeline"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import Stack from "@mui/material/Stack"
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent"

const useStyles = makeStyles()(() => ({
  versionLog: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
}))

interface Version {
  id: string
  title: string
  changes: VersionChanges[]
}

interface VersionChanges {
  name: string
  type: "default" | "success" | "warning" | "error" | "primary" | "secondary" | "info" | undefined
  msg: string
}

const VersionLog = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const versions: Version[] = [
    {
      id: "1.10.001",
      title: "Upload Transactions, Roles and Organization ",
      changes: [
        { name: "ADDED", type: "success", msg: "Upload transaction file" },
        { name: "ADDED", type: "success", msg: "Create Roles and Permission" },
        { name: "ADDED", type: "success", msg: "Organization and companies infrastructure" },
        { name: "REFACTOR", type: "warning", msg: "We removed the customer entity" },
        { name: "UPDATED", type: "error", msg: "We solved UI bugs" },
      ],
    },
    {
      id: "1.09.001",
      title: "Giact API, Improve MVF parsing",
      changes: [
        { name: "ADDED", type: "success", msg: "Adding Giact API" },
        { name: "ADDED", type: "success", msg: "Adding more fields to the Vendor parsing" },
        { name: "ADDED", type: "success", msg: "Organization and companies infra" },
        { name: "ADDED", type: "success", msg: "Adding status to are risk analyzer run" },
        { name: "UPDATED", type: "error", msg: "We fixed Bugs at the login and some package vulnerabilities" },
      ],
    },
    {
      id: "1.08.001",
      title: "Improving APIs, Log improvement",
      changes: [
        { name: "ADDED", type: "success", msg: "CyberSixGil API" },
        { name: "REFACTOR", type: "warning", msg: "OpenCop adding cache" },
        { name: "REFACTOR", type: "warning", msg: "Log improvement" },
      ],
    },
    {
      id: "1.07.001",
      title: "Dashboard improvement, APIs improvement",
      changes: [
        { name: "ADDED", type: "success", msg: "Dashboard improvement" },
        { name: "UPDATED", type: "error", msg: "We fixed Bugs and package vulnerabilities" },
      ],
    },
  ]

  return (
    <Grid container className={classes.versionLog} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h6">{t.General.versionLog}</Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {versions.map((version: Version) => {
              return (
                <TimelineItem key={version.id}>
                  <TimelineOppositeContent color="text.secondary">{version.id}</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid container>
                      <Stack direction={"column"}>
                        <Typography variant={"body1"}>{version.title}</Typography>
                        <List component="div">
                          {version.changes.map((change: VersionChanges) => {
                            return (
                              <ListItemButton sx={{ pl: 2 }} key={change.msg}>
                                <ListItemIcon>
                                  <Chip
                                    label={change.name}
                                    size="small"
                                    color={change.type}
                                    sx={{ mr: 3, width: "5rem" }}
                                  />
                                </ListItemIcon>
                                <Typography variant={"subtitle2"}>{change.msg}</Typography>
                              </ListItemButton>
                            )
                          })}
                        </List>
                      </Stack>
                    </Grid>
                  </TimelineContent>
                </TimelineItem>
              )
            })}
          </Timeline>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default VersionLog
