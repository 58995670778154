import React, { useEffect, useState } from "react"
import AppBar from "@mui/material/AppBar"
import { makeStyles } from "../../makeStyles"
import { ITheme } from "../../theme/theme"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import { useTheme } from "@mui/material"
import VendorForm from "../vendor/VendorForm"
import { UnauthorizedPage } from "../../common/UnauthorizedPage"
import { ThanksPage } from "./ThanksPage"
import { useVendorOnBoardingControllerAuth } from "../../apis/endpoints/edgeService"
import { VendorFormType } from "../../utils/types"

const useStyles = makeStyles()((theme: ITheme) => ({
  logo: {
    fontSize: "1.8rem",
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "Montserrat Alternates, sans-serif",
    color: theme.palette.primary.main,
  },
  content: {
    position: "absolute",
    top: "9%",
    width: "100%",
    height: "100%",
  },
}))

export const Boarding = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const [component, setComponent] = useState<JSX.Element>()
  const t = theme.props

  const onBoardingSuccess = () => {
    setComponent(<ThanksPage vendorDetails={data} />)
  }

  const { data, isSuccess, isError } = useVendorOnBoardingControllerAuth()

  useEffect(() => {
    isSuccess &&
      setComponent(
        <VendorForm
          vendorFormType={VendorFormType.VendorOnBoarding}
          onBoardingSuccess={onBoardingSuccess}
          vendorDetails={data}
        />,
      )
  }, [isSuccess])

  useEffect(() => {
    isError && setComponent(<UnauthorizedPage />)
  }, [isError])

  // useEffect(() => {
  //   if (error) {
  //     setComponent(<UnauthorizedPage />)
  //   }
  //
  //   // if (isSuccess) {
  //   //   setComponent(
  //   //     <VendorForm
  //   //       vendorFormType={VendorFormType.VendorOnBoarding}
  //   //       onBoardingSuccess={onBoardingSuccess}
  //   //       vendorDetails={data}
  //   //     />,
  //   //   )
  //   // }
  // }, [data, error, isSuccess])

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <AppBar sx={{ background: "#E7EEFA" }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <Typography className={classes.logo}>{t.General.creednz}</Typography>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box className={classes.content}>{component}</Box>
    </Box>
  )
}
