import React, { useMemo, useState } from "react"
import { makeStyles } from "makeStyles"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { GridToolbar } from "@mui/x-data-grid"
import TableTextCell from "common/TableTextCell"
import Accordion from "@mui/material/Accordion"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useFindingsControllerFindingsByExaminedId, useVendorsControllerVendor } from "apis/endpoints/edgeService"
import { useParams } from "react-router-dom"
import { formatDate } from "utils/utils"
import VendorSkeleton from "./VendorSkeleton"
import { dataGridSx, slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import Link from "@mui/material/Link"
import { DataGridPro, DataGridProProps, GridRowId } from "@mui/x-data-grid-pro"
import ExpandIndicators from "../indicators/ExpandIndicators"
import FieldWithValue from "../../common/FieldWithValue"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useTheme } from "@mui/material"
import Divider from "@mui/material/Divider"
import { FindingDto, FindingDtoCategory, FindingDtoType } from "../../apis/model"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"
import { NavigateFunction, useNavigate } from "react-router"
import Avatar from "@mui/material/Avatar"
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined"
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined"
import VendorTimeline from "./VendorTimeline"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
const useStyles = makeStyles()(() => ({
  vendor: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  bottomPaper: {
    padding: "1rem",
    marginBottom: "1rem",
  },
  table: {
    height: "auto",
  },
  boxDetails: {
    height: "26.25rem",
    border: "1px solid #E7EEFA ",
    padding: "1rem 0.85rem",
    borderRadius: "10px",
  },
  vendorResult: {
    height: "7rem",
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
  },
  expand: {
    padding: "2rem 1rem",
  },
}))
const Vendor = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()

  const [risks, setRisks] = useState({ bankAccount: false, sanction: false, cyber: false })

  const vendorId = parseInt(useParams().id || "0")

  const { data, isLoading } = useVendorsControllerVendor(vendorId)
  const { data: findings, isLoading: isLoadingFindings } = useFindingsControllerFindingsByExaminedId(vendorId)

  const isAtRisk = useMemo(() => {
    const tempRisks = { bankAccount: false, sanction: false, cyber: false }

    findings?.forEach((finding: FindingDto) => {
      if (finding.type === FindingDtoType.Alert) {
        if (finding.category === FindingDtoCategory.Cyber) {
          tempRisks.cyber = true
        } else if (finding.category === FindingDtoCategory.BankAccountVerification) {
          tempRisks.bankAccount = true
        } else if (finding.category === FindingDtoCategory.Sanctions) {
          tempRisks.sanction = true
        }
      }
    })
    setRisks(tempRisks)

    return tempRisks.cyber || tempRisks.sanction || tempRisks.bankAccount
  }, [findings])

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const handleClose = () => setAnchorEl(null)
  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandIndicators row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const ExpandableCell = ({ description }: { description: string }) => {
    const [expanded, setExpanded] = React.useState(false)

    return (
      <Box component="div" key={description}>
        {expanded ? description : `${description.slice(0, 0)}`}
        {description.length > 0 && (
          <Link
            sx={{ paddingLeft: expanded ? "0.5rem" : 0, fontSize: "0.5", cursor: "pointer" }}
            type="button"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </Box>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const navigateTo = (url: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    navigate(url)
  }

  const getRiskResult = (type: string): JSX.Element => {
    if (type === FindingDtoCategory.BankAccountVerification) {
      const alert = findings?.find(
        (finding: FindingDto) =>
          finding.type === FindingDtoType.Alert && finding.category === FindingDtoCategory.BankAccountVerification,
      )

      if (alert) {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(219, 97, 97, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppMaybeOutlinedIcon color={"error"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"} fontWeight={600}>
                Bank account at risk
              </Typography>
              <Typography variant={"body1"}>{alert.title}</Typography>
            </Stack>
          </Stack>
        )
      } else {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(129, 186, 108, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppGoodOutlinedIcon color={"success"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"} fontWeight={600}>
                No Bank account risk
              </Typography>
              <Typography variant={"body1"}>No bank account risk detected</Typography>
            </Stack>
          </Stack>
        )
      }
    } else if (type === FindingDtoCategory.Cyber) {
      const alert = findings?.find(
        (finding: FindingDto) => finding.type === FindingDtoType.Alert && finding.category === FindingDtoCategory.Cyber,
      )

      if (alert) {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(219, 97, 97, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppMaybeOutlinedIcon color={"error"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"}>Cyber at risk</Typography>
              <Typography variant={"body1"}>{alert.title}</Typography>
            </Stack>
          </Stack>
        )
      } else {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(129, 186, 108, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppGoodOutlinedIcon color={"success"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"} fontWeight={600}>
                No cyber risk
              </Typography>
              <Typography variant={"body1"}>No cyber risk detected</Typography>
            </Stack>
          </Stack>
        )
      }
    } else if (type === FindingDtoCategory.Sanctions) {
      const alert = findings?.find(
        (finding: FindingDto) =>
          finding.type === FindingDtoType.Alert && finding.category === FindingDtoCategory.Sanctions,
      )

      if (alert) {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(219, 97, 97, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppMaybeOutlinedIcon color={"error"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"}>Sanctions at risk</Typography>
              <Typography variant={"body1"}>{alert.title}</Typography>
            </Stack>
          </Stack>
        )
      } else {
        return (
          <Stack direction={"row"} spacing={3}>
            <Avatar sx={{ bgcolor: "rgba(129, 186, 108, 0.2)", height: "3.5rem", width: "3.5rem", margin: "auto" }}>
              <GppGoodOutlinedIcon color={"success"} fontSize={"large"} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant={"h5"} fontWeight={600}>
                No sanctions risk
              </Typography>
              <Typography variant={"body1"}>No sanctions risk detected</Typography>
            </Stack>
          </Stack>
        )
      }
    }
    return <div />
  }

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "title",
      headerName: "Alert",
      flex: 1.2,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography gutterBottom fontWeight={400} variant={"body1"} data-testid={params.value}>
            {params.value}
          </Typography>
          <ExpandableCell description={params.row.description} />
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      headerName: t.General.actions,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            color={"error"}
            size={"small"}
            variant={"contained"}
            sx={{ padding: "0 1rem" }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Action
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            marginThreshold={20}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack sx={{ width: "17rem" }}>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Hold</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Inspect</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Upload to bank</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Ignore</Typography>
            </Stack>
          </Popover>
          <Button size={"small"} variant={"outlined"} onClick={navigateTo.bind(null, `/indicators/${params?.row.id}`)}>
            Details
          </Button>
        </Stack>
      ),
    },
  ]

  if (isLoading || isLoadingFindings || !data || !findings) return <VendorSkeleton />

  return (
    <Grid container className={classes.vendor} spacing={3} data-testid={"vendor"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent={"space-between"} columnSpacing={2}>
            <Grid item xs={11} alignItems={"center"} sx={{ marginBottom: "1rem" }}>
              <Stack direction="row">
                <Groups2OutlinedIcon color={"primary"} fontSize={"large"} sx={{ marginRight: "0.5rem" }} />
                <Typography
                  variant="h5"
                  data-testid={"vendor-name"}
                  sx={{ marginLeft: "0.5rem", verticalAlign: "middle" }}
                >
                  {data.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1} alignItems={"flex-end"}>
              <Button
                variant="outlined"
                startIcon={<ModeEditOutlineOutlinedIcon />}
                onClick={navigateTo.bind(null, `/vendor/${vendorId}`)}
              >
                Edit
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  Status
                </Typography>
                <Box className={classes.boxDetails}>
                  <FieldWithValue
                    name={"Status"}
                    value={isAtRisk ? "At Risk" : "No Risk"}
                    testId={"internal-id"}
                    style={{
                      backgroundColor: isAtRisk ? "rgba(219,97,97,0.2)" : "transparent",
                      borderRadius: "5px",
                    }}
                    icon={isAtRisk ? <InfoOutlinedIcon color={"error"} /> : undefined}
                  />
                  <FieldWithValue name={"Source"} value={"ERP"} testId={"internal-id"} />
                  <FieldWithValue name={"Owner"} value={"Johnny Deutsch"} testId={"internal-id"} />
                  <Divider sx={{ margin: "1rem 0" }} />
                  <FieldWithValue
                    name={t.General.createdAt}
                    value={data?.dateCreated ? formatDate(data.dateCreated) : "-"}
                  />
                  <FieldWithValue
                    name={t.General.lastEdited}
                    value={data?.lastModified ? formatDate(data.lastModified) : "-"}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  General details
                </Typography>
                <Box className={classes.boxDetails}>
                  <Box>
                    <FieldWithValue name={t.General.internalId} value={data.internalId} testId={"internal-id"} />
                    <FieldWithValue name={t.General.email} value={data.email} />
                    <FieldWithValue name={t.General.phone} value={data.phone} />
                    <FieldWithValue name={t.General.taxpayerID} value={data.taxpayerID} />
                    <FieldWithValue name={t.General.registrationCode} value={data.registrationCode} />
                    <FieldWithValue name={t.General.currency} value={data.currency} />
                  </Box>
                  <Divider sx={{ margin: "1rem 0" }} />
                  <Box>
                    <FieldWithValue
                      name={t.General.primaryContact}
                      value={data.primaryContact}
                      testId={"primary-contact"}
                    />
                    <FieldWithValue name={t.General.primarySubsidiary} value={data.primarySubsidiary} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  Payment details
                </Typography>
                <Box className={classes.boxDetails}>
                  <Box>
                    <FieldWithValue name={t.General.iban} value={data.iban} testId={"iban"} />
                    <FieldWithValue name={t.General.swiftCode} value={data.swift} testId={"swift"} />
                    <FieldWithValue name={t.General.abaNumber} value={data.abaNumber} />
                    <FieldWithValue name={t.General.paymentMethod} value={data.vendorPaymentMethod} />
                    <FieldWithValue name={t.General.paypalAccount} value={data.paypalAccount} />
                  </Box>
                  <Divider sx={{ margin: "1rem 0" }} />
                  <Box>
                    <FieldWithValue name={t.General.address} value={data.address} />
                    <FieldWithValue name={t.General.countryCode} value={data.billingCountry} />
                    <FieldWithValue name={t.General.billingRegion} value={data.billingRegion} />
                    <FieldWithValue name={t.General.billingZipCode} value={data.billingZip} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  Bank account details
                </Typography>
                <Box className={classes.boxDetails}>
                  <FieldWithValue name={t.General.bankAccountName} value={data.bankAccountName} />
                  <FieldWithValue name={t.General.bankAccountType} value={data.bankAccountType} />
                  <FieldWithValue name={t.General.bankCode} value={data.bankCode} />
                  <FieldWithValue name={t.General.bankAccountNumber} value={data.bankAccountNumber} />
                  <FieldWithValue name={t.General.bankAddress} value={data.bankAddress} />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent={"space-between"}>
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack direction={"row"}>
                <Box className={classes.vendorResult}>{getRiskResult(FindingDtoCategory.BankAccountVerification)}</Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack>
                <Box className={classes.vendorResult}>{getRiskResult(FindingDtoCategory.Sanctions)}</Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack>
                <Box className={classes.vendorResult}>{getRiskResult(FindingDtoCategory.Cyber)}</Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <VendorTimeline risks={risks} vendorName={data.name} />
      </Grid>
      {isAtRisk && (
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              sx={{ height: "5rem", fontSize: "1.3rem", borderRadius: "1rem" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {`${findings.length} Risks`}
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.table} data-testid={"vendor-table"}>
                <DataGridPro
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  rows={findings}
                  columns={columns}
                  slotProps={slotProps}
                  sx={dataGridSx}
                  getEstimatedRowHeight={() => 200}
                  getRowHeight={() => "auto"}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={getDetailPanelHeight}
                  detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  )
}

export default Vendor
