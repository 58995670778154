import * as React from "react"
import { Button, Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Stack from "@mui/material/Stack"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { useState } from "react"
import Typography from "@mui/material/Typography"
import LoadingButton from "@mui/lab/LoadingButton"

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  // width: 1,
})

interface IProps {
  openImport: boolean
  close: any
}

const MoneyRecoveryDrawer = ({ openImport, close }: IProps): JSX.Element => {
  const [selectedBai, setSelectedBai] = useState<File | null>(null)
  const [selectedEmail, setSelectedEmail] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleChangeBai = (e: any): void => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setSelectedBai(e.target.files[0])
    }
  }

  const handleChangeEmail = (e: any): void => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setSelectedEmail(e.target.files[0])
    }
  }

  const save = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      close()
    }, 1500)
  }

  return (
    <Dialog open={openImport} onClose={close}>
      <DialogTitle>Money Recovery</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "2rem" }}>
          In order to send money recovery you need to upload BAI2 file and email if exist
        </DialogContentText>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Stack direction={"column"}>
              <Button
                sx={{ width: "10rem", margin: "1rem 0 0 0" }}
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                onChange={handleChangeBai}
              >
                BAI file
                <VisuallyHiddenInput type="file" />
              </Button>
              <Typography variant={"caption"} sx={{ width: "10rem", margin: "1rem 0 3rem 0" }}>
                {selectedBai?.name}
              </Typography>
              <Button
                sx={{ width: "10rem", margin: "1rem 0 0 0" }}
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                onChange={handleChangeEmail}
                startIcon={<CloudUploadIcon />}
              >
                Email
                <VisuallyHiddenInput type="file" />
              </Button>
              <Typography variant={"caption"} sx={{ margin: "1rem 0 3rem 0" }}>
                {selectedEmail?.name}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <LoadingButton loading={loading} variant="contained" onClick={save}>
          Send Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default MoneyRecoveryDrawer
