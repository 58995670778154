import * as React from "react"
import { useState } from "react"
import { ITheme } from "theme/theme"
import { OrganizationDto } from "../../apis/model"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import {
  entitiesControllerRemoveAll,
  entitiesControllerRemoveByOrganizationId,
  useEntitiesControllerRemoveAll,
  useEntitiesControllerRemoveByOrganizationId,
} from "apis/endpoints/edgeService"

interface IProps {
  open: boolean
  organization: OrganizationDto
  closeDialog: () => void
}

const DeleteDialog = ({ open, organization, closeDialog }: IProps): JSX.Element => {
  const theme: ITheme = useTheme()
  const t = theme.props
  const [deleting, setDeleting] = useState<boolean>(false)

  const deleteData = () => {
    setDeleting(true)
    organization?.id ? deleteOrganizationData.mutate({ id: organization.id }) : deleteAllData.mutate()
  }

  const deleteAllData = useEntitiesControllerRemoveAll({
    mutation: {
      mutationFn: () => entitiesControllerRemoveAll(),
      onSuccess: () => {
        setDeleting(false)
        closeDialog()
      },
      onError: (e): void => {
        setDeleting(false)
        console.log(e)
      },
    },
  })

  const deleteOrganizationData = useEntitiesControllerRemoveByOrganizationId({
    mutation: {
      mutationFn: (organization: { id: number }) => entitiesControllerRemoveByOrganizationId(organization.id),
      onSuccess: () => {
        setDeleting(false)
        closeDialog()
      },
      onError: (e): void => {
        setDeleting(false)
        console.log(e)
      },
    },
  })

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {organization ? t.General.deleteOrganizationData : t.General.deleteAllCompaniesData}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant={"body1"} gutterBottom>
            {`Are you sure you want to delete ${organization.name} data?`}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={closeDialog}>
          {t.General.cancel}
        </Button>
        <LoadingButton size="medium" loading={deleting} variant={"contained"} color={"error"} onClick={deleteData}>
          {t.General.delete}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
