import { Localization } from "locale"
import { createTheme, Theme, ThemeOptions } from "@mui/material/styles"
import { Palette, PaletteColor } from "@mui/material/styles/createPalette"
import themeLight from "./theme-light"
import themeDark from "./theme-dark"

export interface IButtons {
  mainBackground: string
  configBackground: string
}

export interface IHeader {
  logoutBackground: string
}

interface IGeneral {
  bodyHeight: string
  border: string
}

interface IOverflowText {
  overflow: string
  display: string
  textOverflow: string
  WebkitLineClamp: number
  WebkitBoxOrient: any
}

interface ISeverities {
  high: string
  medium: string
  low: string
  info: string
  success: string
}

interface IPalette extends Palette {
  buttons: IButtons
  header: IHeader
  default: PaletteColor
  severities: ISeverities
}

export interface ITheme extends Theme, Localization {
  palette: IPalette
  general: IGeneral
  overflowText: IOverflowText
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette
  general: IGeneral
  overflowText: IOverflowText
  components: any
  typography: any
  props: any
}

const creednzTheme: any = createTheme(
  localStorage.getItem("mode") === "dark" ? (themeDark as IThemeOptions) : (themeLight as IThemeOptions),
)

creednzTheme.components = {
  ...creednzTheme.components,
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: "none",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "inherit",
        },
      },
      sizeMedium: {
        minWidth: "9rem",
      },
      iconSizeSmall: {
        "& > *:first-of-type": {
          fontSize: 14,
        },
      },
      textSecondary: {
        "&:hover": {
          color: creednzTheme.palette.primary.main,
        },
      },
    },
  },
}

export default creednzTheme
