/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthControllerHandleMicrosoftCallbackParams,
  AuthControllerRedirectToMicrosoftLoginParams,
  CompanyDto,
  CreateCompanyDto,
  CreateOrganizationDto,
  CreatePermissionDto,
  CreateRoleDto,
  CreateRuleConfigurationDto,
  CreateRuleDto,
  CreateUserDTO,
  CreateVendorDto,
  DashboardDto,
  EmailConnectionOptionsDto,
  EmailDto,
  FindingDto,
  InviteVendorOnBoardingDto,
  LedgerEntryDto,
  MeDto,
  MvfFileDto,
  OrganizationDto,
  PaymentDto,
  PermissionDto,
  RoleDto,
  RuleConfigurationDto,
  RuleDto,
  UpdateOrganizationDto,
  UpdatePermissionDto,
  UpdateRoleDto,
  UpdateRuleConfigurationDto,
  UpdateRuleDto,
  UpdateUserDTO,
  UpdateVendorDto,
  UserDto,
  VendorDto,
  VendorOnBoardingDto,
  VendorOnBoardingMeDto,
  WelcomeDto
} from '../model'
import { customInstance } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';



export const appControllerEmpty = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/`, method: 'GET', signal
    },
      );
    }
  

export const getAppControllerEmptyQueryKey = () => {
    return [`/`] as const;
    }

    
export const getAppControllerEmptyQueryOptions = <TData = Awaited<ReturnType<typeof appControllerEmpty>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof appControllerEmpty>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppControllerEmptyQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appControllerEmpty>>> = ({ signal }) => appControllerEmpty(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appControllerEmpty>>, TError, TData> & { queryKey: QueryKey }
}

export type AppControllerEmptyQueryResult = NonNullable<Awaited<ReturnType<typeof appControllerEmpty>>>
export type AppControllerEmptyQueryError = ErrorType<unknown>

export const useAppControllerEmpty = <TData = Awaited<ReturnType<typeof appControllerEmpty>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof appControllerEmpty>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppControllerEmptyQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const appControllerHealth = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/health`, method: 'GET', signal
    },
      );
    }
  

export const getAppControllerHealthQueryKey = () => {
    return [`/health`] as const;
    }

    
export const getAppControllerHealthQueryOptions = <TData = Awaited<ReturnType<typeof appControllerHealth>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof appControllerHealth>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppControllerHealthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appControllerHealth>>> = ({ signal }) => appControllerHealth(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appControllerHealth>>, TError, TData> & { queryKey: QueryKey }
}

export type AppControllerHealthQueryResult = NonNullable<Awaited<ReturnType<typeof appControllerHealth>>>
export type AppControllerHealthQueryError = ErrorType<unknown>

export const useAppControllerHealth = <TData = Awaited<ReturnType<typeof appControllerHealth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof appControllerHealth>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppControllerHealthQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const paymentControllerPayments = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaymentDto[]>(
      {url: `/payments`, method: 'GET', signal
    },
      );
    }
  

export const getPaymentControllerPaymentsQueryKey = () => {
    return [`/payments`] as const;
    }

    
export const getPaymentControllerPaymentsQueryOptions = <TData = Awaited<ReturnType<typeof paymentControllerPayments>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayments>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentControllerPaymentsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentControllerPayments>>> = ({ signal }) => paymentControllerPayments(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayments>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentControllerPaymentsQueryResult = NonNullable<Awaited<ReturnType<typeof paymentControllerPayments>>>
export type PaymentControllerPaymentsQueryError = ErrorType<unknown>

export const usePaymentControllerPayments = <TData = Awaited<ReturnType<typeof paymentControllerPayments>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayments>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPaymentControllerPaymentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const paymentControllerPayment = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaymentDto>(
      {url: `/payments/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getPaymentControllerPaymentQueryKey = (id: number,) => {
    return [`/payments/${id}`] as const;
    }

    
export const getPaymentControllerPaymentQueryOptions = <TData = Awaited<ReturnType<typeof paymentControllerPayment>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayment>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPaymentControllerPaymentQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentControllerPayment>>> = ({ signal }) => paymentControllerPayment(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayment>>, TError, TData> & { queryKey: QueryKey }
}

export type PaymentControllerPaymentQueryResult = NonNullable<Awaited<ReturnType<typeof paymentControllerPayment>>>
export type PaymentControllerPaymentQueryError = ErrorType<unknown>

export const usePaymentControllerPayment = <TData = Awaited<ReturnType<typeof paymentControllerPayment>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentControllerPayment>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPaymentControllerPaymentQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetProfile = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<MeDto>(
      {url: `/auth/me`, method: 'GET', signal
    },
      );
    }
  

export const getAuthControllerGetProfileQueryKey = () => {
    return [`/auth/me`] as const;
    }

    
export const getAuthControllerGetProfileQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetProfile>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetProfile>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetProfile>>> = ({ signal }) => authControllerGetProfile(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetProfileQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetProfile>>>
export type AuthControllerGetProfileQueryError = ErrorType<unknown>

export const useAuthControllerGetProfile = <TData = Awaited<ReturnType<typeof authControllerGetProfile>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetProfile>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuthControllerGetProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerHandleMicrosoftCallback = (
    params: AuthControllerHandleMicrosoftCallbackParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/auth/microsoft/callback`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getAuthControllerHandleMicrosoftCallbackQueryKey = (params: AuthControllerHandleMicrosoftCallbackParams,) => {
    return [`/auth/microsoft/callback`, ...(params ? [params]: [])] as const;
    }

    
export const getAuthControllerHandleMicrosoftCallbackQueryOptions = <TData = Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>, TError = ErrorType<unknown>>(params: AuthControllerHandleMicrosoftCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerHandleMicrosoftCallbackQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>> = ({ signal }) => authControllerHandleMicrosoftCallback(params, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerHandleMicrosoftCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>>
export type AuthControllerHandleMicrosoftCallbackQueryError = ErrorType<unknown>

export const useAuthControllerHandleMicrosoftCallback = <TData = Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>, TError = ErrorType<unknown>>(
 params: AuthControllerHandleMicrosoftCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerHandleMicrosoftCallback>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuthControllerHandleMicrosoftCallbackQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerRedirectToMicrosoftLogin = (
    params: AuthControllerRedirectToMicrosoftLoginParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/auth/microsoft/login`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getAuthControllerRedirectToMicrosoftLoginQueryKey = (params: AuthControllerRedirectToMicrosoftLoginParams,) => {
    return [`/auth/microsoft/login`, ...(params ? [params]: [])] as const;
    }

    
export const getAuthControllerRedirectToMicrosoftLoginQueryOptions = <TData = Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>, TError = ErrorType<unknown>>(params: AuthControllerRedirectToMicrosoftLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerRedirectToMicrosoftLoginQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>> = ({ signal }) => authControllerRedirectToMicrosoftLogin(params, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerRedirectToMicrosoftLoginQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>>
export type AuthControllerRedirectToMicrosoftLoginQueryError = ErrorType<unknown>

export const useAuthControllerRedirectToMicrosoftLogin = <TData = Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>, TError = ErrorType<unknown>>(
 params: AuthControllerRedirectToMicrosoftLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRedirectToMicrosoftLogin>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuthControllerRedirectToMicrosoftLoginQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerDisconnectMicrosoftAccount = (
    email: string,
 ) => {
      
      
      return customInstance<void>(
      {url: `/auth/microsoft/disconnect/${email}`, method: 'DELETE'
    },
      );
    }
  


export const getAuthControllerDisconnectMicrosoftAccountMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerDisconnectMicrosoftAccount>>, TError,{email: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authControllerDisconnectMicrosoftAccount>>, TError,{email: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerDisconnectMicrosoftAccount>>, {email: string}> = (props) => {
          const {email} = props ?? {};

          return  authControllerDisconnectMicrosoftAccount(email,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthControllerDisconnectMicrosoftAccountMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerDisconnectMicrosoftAccount>>>
    
    export type AuthControllerDisconnectMicrosoftAccountMutationError = ErrorType<unknown>

    export const useAuthControllerDisconnectMicrosoftAccount = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerDisconnectMicrosoftAccount>>, TError,{email: string}, TContext>, }
) => {

      const mutationOptions = getAuthControllerDisconnectMicrosoftAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const findingsControllerFindings = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<FindingDto[]>(
      {url: `/findings`, method: 'GET', signal
    },
      );
    }
  

export const getFindingsControllerFindingsQueryKey = () => {
    return [`/findings`] as const;
    }

    
export const getFindingsControllerFindingsQueryOptions = <TData = Awaited<ReturnType<typeof findingsControllerFindings>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindings>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFindingsControllerFindingsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof findingsControllerFindings>>> = ({ signal }) => findingsControllerFindings(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindings>>, TError, TData> & { queryKey: QueryKey }
}

export type FindingsControllerFindingsQueryResult = NonNullable<Awaited<ReturnType<typeof findingsControllerFindings>>>
export type FindingsControllerFindingsQueryError = ErrorType<unknown>

export const useFindingsControllerFindings = <TData = Awaited<ReturnType<typeof findingsControllerFindings>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindings>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFindingsControllerFindingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const findingsControllerFinding = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<FindingDto>(
      {url: `/findings/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getFindingsControllerFindingQueryKey = (id: number,) => {
    return [`/findings/${id}`] as const;
    }

    
export const getFindingsControllerFindingQueryOptions = <TData = Awaited<ReturnType<typeof findingsControllerFinding>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFinding>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFindingsControllerFindingQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof findingsControllerFinding>>> = ({ signal }) => findingsControllerFinding(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFinding>>, TError, TData> & { queryKey: QueryKey }
}

export type FindingsControllerFindingQueryResult = NonNullable<Awaited<ReturnType<typeof findingsControllerFinding>>>
export type FindingsControllerFindingQueryError = ErrorType<unknown>

export const useFindingsControllerFinding = <TData = Awaited<ReturnType<typeof findingsControllerFinding>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFinding>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFindingsControllerFindingQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const findingsControllerFindingsByExaminedId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<FindingDto[]>(
      {url: `/findings/examinedId/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getFindingsControllerFindingsByExaminedIdQueryKey = (id: number,) => {
    return [`/findings/examinedId/${id}`] as const;
    }

    
export const getFindingsControllerFindingsByExaminedIdQueryOptions = <TData = Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFindingsControllerFindingsByExaminedIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>> = ({ signal }) => findingsControllerFindingsByExaminedId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>, TError, TData> & { queryKey: QueryKey }
}

export type FindingsControllerFindingsByExaminedIdQueryResult = NonNullable<Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>>
export type FindingsControllerFindingsByExaminedIdQueryError = ErrorType<unknown>

export const useFindingsControllerFindingsByExaminedId = <TData = Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof findingsControllerFindingsByExaminedId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFindingsControllerFindingsByExaminedIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const companyControllerUsersByCompany = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserDto[]>(
      {url: `/company/users`, method: 'GET', signal
    },
      );
    }
  

export const getCompanyControllerUsersByCompanyQueryKey = () => {
    return [`/company/users`] as const;
    }

    
export const getCompanyControllerUsersByCompanyQueryOptions = <TData = Awaited<ReturnType<typeof companyControllerUsersByCompany>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompany>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyControllerUsersByCompanyQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyControllerUsersByCompany>>> = ({ signal }) => companyControllerUsersByCompany(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompany>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyControllerUsersByCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof companyControllerUsersByCompany>>>
export type CompanyControllerUsersByCompanyQueryError = ErrorType<unknown>

export const useCompanyControllerUsersByCompany = <TData = Awaited<ReturnType<typeof companyControllerUsersByCompany>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompany>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyControllerUsersByCompanyQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const companyControllerUsersByCompanyId = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserDto[]>(
      {url: `/company/${id}/users`, method: 'GET', signal
    },
      );
    }
  

export const getCompanyControllerUsersByCompanyIdQueryKey = (id: number,) => {
    return [`/company/${id}/users`] as const;
    }

    
export const getCompanyControllerUsersByCompanyIdQueryOptions = <TData = Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyControllerUsersByCompanyIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>> = ({ signal }) => companyControllerUsersByCompanyId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyControllerUsersByCompanyIdQueryResult = NonNullable<Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>>
export type CompanyControllerUsersByCompanyIdQueryError = ErrorType<unknown>

export const useCompanyControllerUsersByCompanyId = <TData = Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerUsersByCompanyId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyControllerUsersByCompanyIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const companyControllerCompany = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompanyDto>(
      {url: `/company/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getCompanyControllerCompanyQueryKey = (id: number,) => {
    return [`/company/${id}`] as const;
    }

    
export const getCompanyControllerCompanyQueryOptions = <TData = Awaited<ReturnType<typeof companyControllerCompany>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerCompany>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyControllerCompanyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyControllerCompany>>> = ({ signal }) => companyControllerCompany(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyControllerCompany>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyControllerCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof companyControllerCompany>>>
export type CompanyControllerCompanyQueryError = ErrorType<unknown>

export const useCompanyControllerCompany = <TData = Awaited<ReturnType<typeof companyControllerCompany>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyControllerCompany>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyControllerCompanyQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const companyControllerCreateCompany = (
    createCompanyDto: CreateCompanyDto,
 ) => {
      
      
      return customInstance<CompanyDto>(
      {url: `/company`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCompanyDto
    },
      );
    }
  


export const getCompanyControllerCreateCompanyMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyControllerCreateCompany>>, TError,{data: CreateCompanyDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof companyControllerCreateCompany>>, TError,{data: CreateCompanyDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyControllerCreateCompany>>, {data: CreateCompanyDto}> = (props) => {
          const {data} = props ?? {};

          return  companyControllerCreateCompany(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CompanyControllerCreateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof companyControllerCreateCompany>>>
    export type CompanyControllerCreateCompanyMutationBody = CreateCompanyDto
    export type CompanyControllerCreateCompanyMutationError = ErrorType<unknown>

    export const useCompanyControllerCreateCompany = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyControllerCreateCompany>>, TError,{data: CreateCompanyDto}, TContext>, }
) => {

      const mutationOptions = getCompanyControllerCreateCompanyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const companyControllerUpdateConnectedOptions = (
    id: number,
    emailConnectionOptionsDto: EmailConnectionOptionsDto,
 ) => {
      
      
      return customInstance<CompanyDto>(
      {url: `/company/${id}/connected-options`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: emailConnectionOptionsDto
    },
      );
    }
  


export const getCompanyControllerUpdateConnectedOptionsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyControllerUpdateConnectedOptions>>, TError,{id: number;data: EmailConnectionOptionsDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof companyControllerUpdateConnectedOptions>>, TError,{id: number;data: EmailConnectionOptionsDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyControllerUpdateConnectedOptions>>, {id: number;data: EmailConnectionOptionsDto}> = (props) => {
          const {id,data} = props ?? {};

          return  companyControllerUpdateConnectedOptions(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CompanyControllerUpdateConnectedOptionsMutationResult = NonNullable<Awaited<ReturnType<typeof companyControllerUpdateConnectedOptions>>>
    export type CompanyControllerUpdateConnectedOptionsMutationBody = EmailConnectionOptionsDto
    export type CompanyControllerUpdateConnectedOptionsMutationError = ErrorType<unknown>

    export const useCompanyControllerUpdateConnectedOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyControllerUpdateConnectedOptions>>, TError,{id: number;data: EmailConnectionOptionsDto}, TContext>, }
) => {

      const mutationOptions = getCompanyControllerUpdateConnectedOptionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerCreateUser = (
    createUserDTO: CreateUserDTO,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUserDTO
    },
      );
    }
  


export const getUserControllerCreateUserMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateUser>>, TError,{data: CreateUserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateUser>>, TError,{data: CreateUserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerCreateUser>>, {data: CreateUserDTO}> = (props) => {
          const {data} = props ?? {};

          return  userControllerCreateUser(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerCreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerCreateUser>>>
    export type UserControllerCreateUserMutationBody = CreateUserDTO
    export type UserControllerCreateUserMutationError = ErrorType<unknown>

    export const useUserControllerCreateUser = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateUser>>, TError,{data: CreateUserDTO}, TContext>, }
) => {

      const mutationOptions = getUserControllerCreateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerUpdateUser = (
    updateUserDTO: UpdateUserDTO,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/update`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserDTO
    },
      );
    }
  


export const getUserControllerUpdateUserMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{data: UpdateUserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{data: UpdateUserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdateUser>>, {data: UpdateUserDTO}> = (props) => {
          const {data} = props ?? {};

          return  userControllerUpdateUser(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdateUser>>>
    export type UserControllerUpdateUserMutationBody = UpdateUserDTO
    export type UserControllerUpdateUserMutationError = ErrorType<unknown>

    export const useUserControllerUpdateUser = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateUser>>, TError,{data: UpdateUserDTO}, TContext>, }
) => {

      const mutationOptions = getUserControllerUpdateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerUserCompanies = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompanyDto[]>(
      {url: `/users/companies`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerUserCompaniesQueryKey = () => {
    return [`/users/companies`] as const;
    }

    
export const getUserControllerUserCompaniesQueryOptions = <TData = Awaited<ReturnType<typeof userControllerUserCompanies>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerUserCompanies>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerUserCompaniesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerUserCompanies>>> = ({ signal }) => userControllerUserCompanies(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerUserCompanies>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerUserCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerUserCompanies>>>
export type UserControllerUserCompaniesQueryError = ErrorType<unknown>

export const useUserControllerUserCompanies = <TData = Awaited<ReturnType<typeof userControllerUserCompanies>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerUserCompanies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerUserCompaniesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerAssignRoleToUser = (
    userId: number,
    roleId: number,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/${userId}/role/${roleId}`, method: 'PUT'
    },
      );
    }
  


export const getUserControllerAssignRoleToUserMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignRoleToUser>>, TError,{userId: number;roleId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignRoleToUser>>, TError,{userId: number;roleId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerAssignRoleToUser>>, {userId: number;roleId: number}> = (props) => {
          const {userId,roleId} = props ?? {};

          return  userControllerAssignRoleToUser(userId,roleId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerAssignRoleToUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerAssignRoleToUser>>>
    
    export type UserControllerAssignRoleToUserMutationError = ErrorType<unknown>

    export const useUserControllerAssignRoleToUser = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignRoleToUser>>, TError,{userId: number;roleId: number}, TContext>, }
) => {

      const mutationOptions = getUserControllerAssignRoleToUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerAssignPermissionToUser = (
    userId: number,
    permissionId: number,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/${userId}/permission/${permissionId}`, method: 'PUT'
    },
      );
    }
  


export const getUserControllerAssignPermissionToUserMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignPermissionToUser>>, TError,{userId: number;permissionId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignPermissionToUser>>, TError,{userId: number;permissionId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerAssignPermissionToUser>>, {userId: number;permissionId: number}> = (props) => {
          const {userId,permissionId} = props ?? {};

          return  userControllerAssignPermissionToUser(userId,permissionId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerAssignPermissionToUserMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerAssignPermissionToUser>>>
    
    export type UserControllerAssignPermissionToUserMutationError = ErrorType<unknown>

    export const useUserControllerAssignPermissionToUser = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerAssignPermissionToUser>>, TError,{userId: number;permissionId: number}, TContext>, }
) => {

      const mutationOptions = getUserControllerAssignPermissionToUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerGetUserPermissions = (
    userId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<string[]>(
      {url: `/users/${userId}/permissions`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerGetUserPermissionsQueryKey = (userId: number,) => {
    return [`/users/${userId}/permissions`] as const;
    }

    
export const getUserControllerGetUserPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetUserPermissions>>, TError = ErrorType<unknown>>(userId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserPermissions>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetUserPermissionsQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUserPermissions>>> = ({ signal }) => userControllerGetUserPermissions(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerGetUserPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetUserPermissions>>>
export type UserControllerGetUserPermissionsQueryError = ErrorType<unknown>

export const useUserControllerGetUserPermissions = <TData = Awaited<ReturnType<typeof userControllerGetUserPermissions>>, TError = ErrorType<unknown>>(
 userId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserPermissions>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetUserPermissionsQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerSetSelectedCompany = (
    selectedCompanyId: number,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/selectedCompany/${selectedCompanyId}`, method: 'PUT'
    },
      );
    }
  


export const getUserControllerSetSelectedCompanyMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerSetSelectedCompany>>, TError,{selectedCompanyId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerSetSelectedCompany>>, TError,{selectedCompanyId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerSetSelectedCompany>>, {selectedCompanyId: number}> = (props) => {
          const {selectedCompanyId} = props ?? {};

          return  userControllerSetSelectedCompany(selectedCompanyId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerSetSelectedCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerSetSelectedCompany>>>
    
    export type UserControllerSetSelectedCompanyMutationError = ErrorType<unknown>

    export const useUserControllerSetSelectedCompany = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerSetSelectedCompany>>, TError,{selectedCompanyId: number}, TContext>, }
) => {

      const mutationOptions = getUserControllerSetSelectedCompanyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const userControllerUser = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getUserControllerUserQueryKey = (id: number,) => {
    return [`/users/${id}`] as const;
    }

    
export const getUserControllerUserQueryOptions = <TData = Awaited<ReturnType<typeof userControllerUser>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerUser>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerUserQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerUser>>> = ({ signal }) => userControllerUser(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userControllerUser>>, TError, TData> & { queryKey: QueryKey }
}

export type UserControllerUserQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerUser>>>
export type UserControllerUserQueryError = ErrorType<unknown>

export const useUserControllerUser = <TData = Awaited<ReturnType<typeof userControllerUser>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userControllerUser>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerUserQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const userControllerUpdate = (
    id: number,
    updateUserDTO: UpdateUserDTO,
 ) => {
      
      
      return customInstance<UserDto>(
      {url: `/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserDTO
    },
      );
    }
  


export const getUserControllerUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{id: number;data: UpdateUserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{id: number;data: UpdateUserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdate>>, {id: number;data: UpdateUserDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  userControllerUpdate(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdate>>>
    export type UserControllerUpdateMutationBody = UpdateUserDTO
    export type UserControllerUpdateMutationError = ErrorType<unknown>

    export const useUserControllerUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdate>>, TError,{id: number;data: UpdateUserDTO}, TContext>, }
) => {

      const mutationOptions = getUserControllerUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const uploadControllerUploadMvf = (
    
 ) => {
      
      
      return customInstance<void>(
      {url: `/upload/mvf`, method: 'POST'
    },
      );
    }
  


export const getUploadControllerUploadMvfMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadMvf>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadMvf>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadControllerUploadMvf>>, void> = () => {
          

          return  uploadControllerUploadMvf()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadControllerUploadMvfMutationResult = NonNullable<Awaited<ReturnType<typeof uploadControllerUploadMvf>>>
    
    export type UploadControllerUploadMvfMutationError = ErrorType<unknown>

    export const useUploadControllerUploadMvf = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadMvf>>, TError,void, TContext>, }
) => {

      const mutationOptions = getUploadControllerUploadMvfMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const uploadControllerUploadPaymentFile = (
    
 ) => {
      
      
      return customInstance<void>(
      {url: `/upload/payment`, method: 'POST'
    },
      );
    }
  


export const getUploadControllerUploadPaymentFileMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadPaymentFile>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadPaymentFile>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadControllerUploadPaymentFile>>, void> = () => {
          

          return  uploadControllerUploadPaymentFile()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadControllerUploadPaymentFileMutationResult = NonNullable<Awaited<ReturnType<typeof uploadControllerUploadPaymentFile>>>
    
    export type UploadControllerUploadPaymentFileMutationError = ErrorType<unknown>

    export const useUploadControllerUploadPaymentFile = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadPaymentFile>>, TError,void, TContext>, }
) => {

      const mutationOptions = getUploadControllerUploadPaymentFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const uploadControllerUploadLedgerFile = (
    
 ) => {
      
      
      return customInstance<void>(
      {url: `/upload/ledger`, method: 'POST'
    },
      );
    }
  


export const getUploadControllerUploadLedgerFileMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadLedgerFile>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadLedgerFile>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadControllerUploadLedgerFile>>, void> = () => {
          

          return  uploadControllerUploadLedgerFile()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadControllerUploadLedgerFileMutationResult = NonNullable<Awaited<ReturnType<typeof uploadControllerUploadLedgerFile>>>
    
    export type UploadControllerUploadLedgerFileMutationError = ErrorType<unknown>

    export const useUploadControllerUploadLedgerFile = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadControllerUploadLedgerFile>>, TError,void, TContext>, }
) => {

      const mutationOptions = getUploadControllerUploadLedgerFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const vendorsControllerVendors = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<VendorDto[]>(
      {url: `/vendors`, method: 'GET', signal
    },
      );
    }
  

export const getVendorsControllerVendorsQueryKey = () => {
    return [`/vendors`] as const;
    }

    
export const getVendorsControllerVendorsQueryOptions = <TData = Awaited<ReturnType<typeof vendorsControllerVendors>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendors>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorsControllerVendorsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorsControllerVendors>>> = ({ signal }) => vendorsControllerVendors(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendors>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorsControllerVendorsQueryResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerVendors>>>
export type VendorsControllerVendorsQueryError = ErrorType<unknown>

export const useVendorsControllerVendors = <TData = Awaited<ReturnType<typeof vendorsControllerVendors>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendors>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVendorsControllerVendorsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorsControllerSave = (
    createVendorDto: CreateVendorDto,
 ) => {
      
      
      return customInstance<VendorDto>(
      {url: `/vendors`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createVendorDto
    },
      );
    }
  


export const getVendorsControllerSaveMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSave>>, TError,{data: CreateVendorDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSave>>, TError,{data: CreateVendorDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vendorsControllerSave>>, {data: CreateVendorDto}> = (props) => {
          const {data} = props ?? {};

          return  vendorsControllerSave(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VendorsControllerSaveMutationResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerSave>>>
    export type VendorsControllerSaveMutationBody = CreateVendorDto
    export type VendorsControllerSaveMutationError = ErrorType<unknown>

    export const useVendorsControllerSave = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSave>>, TError,{data: CreateVendorDto}, TContext>, }
) => {

      const mutationOptions = getVendorsControllerSaveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const vendorsControllerVendor = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<VendorDto>(
      {url: `/vendors/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getVendorsControllerVendorQueryKey = (id: number,) => {
    return [`/vendors/${id}`] as const;
    }

    
export const getVendorsControllerVendorQueryOptions = <TData = Awaited<ReturnType<typeof vendorsControllerVendor>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendor>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorsControllerVendorQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorsControllerVendor>>> = ({ signal }) => vendorsControllerVendor(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendor>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorsControllerVendorQueryResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerVendor>>>
export type VendorsControllerVendorQueryError = ErrorType<unknown>

export const useVendorsControllerVendor = <TData = Awaited<ReturnType<typeof vendorsControllerVendor>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendor>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVendorsControllerVendorQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorsControllerUpdateWithAnalyze = (
    id: number,
    updateVendorDto: UpdateVendorDto,
 ) => {
      
      
      return customInstance<VendorDto>(
      {url: `/vendors/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateVendorDto
    },
      );
    }
  


export const getVendorsControllerUpdateWithAnalyzeMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerUpdateWithAnalyze>>, TError,{id: number;data: UpdateVendorDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerUpdateWithAnalyze>>, TError,{id: number;data: UpdateVendorDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vendorsControllerUpdateWithAnalyze>>, {id: number;data: UpdateVendorDto}> = (props) => {
          const {id,data} = props ?? {};

          return  vendorsControllerUpdateWithAnalyze(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VendorsControllerUpdateWithAnalyzeMutationResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerUpdateWithAnalyze>>>
    export type VendorsControllerUpdateWithAnalyzeMutationBody = UpdateVendorDto
    export type VendorsControllerUpdateWithAnalyzeMutationError = ErrorType<unknown>

    export const useVendorsControllerUpdateWithAnalyze = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerUpdateWithAnalyze>>, TError,{id: number;data: UpdateVendorDto}, TContext>, }
) => {

      const mutationOptions = getVendorsControllerUpdateWithAnalyzeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const vendorsControllerVendorByName = (
    name: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<VendorDto>(
      {url: `/vendors/name/${name}`, method: 'GET', signal
    },
      );
    }
  

export const getVendorsControllerVendorByNameQueryKey = (name: string,) => {
    return [`/vendors/name/${name}`] as const;
    }

    
export const getVendorsControllerVendorByNameQueryOptions = <TData = Awaited<ReturnType<typeof vendorsControllerVendorByName>>, TError = ErrorType<unknown>>(name: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendorByName>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorsControllerVendorByNameQueryKey(name);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorsControllerVendorByName>>> = ({ signal }) => vendorsControllerVendorByName(name, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(name),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendorByName>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorsControllerVendorByNameQueryResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerVendorByName>>>
export type VendorsControllerVendorByNameQueryError = ErrorType<unknown>

export const useVendorsControllerVendorByName = <TData = Awaited<ReturnType<typeof vendorsControllerVendorByName>>, TError = ErrorType<unknown>>(
 name: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorsControllerVendorByName>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVendorsControllerVendorByNameQueryOptions(name,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorsControllerSaveWithAnalyze = (
    createVendorDto: CreateVendorDto,
 ) => {
      
      
      return customInstance<VendorDto>(
      {url: `/vendors/save-with-analyze`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createVendorDto
    },
      );
    }
  


export const getVendorsControllerSaveWithAnalyzeMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSaveWithAnalyze>>, TError,{data: CreateVendorDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSaveWithAnalyze>>, TError,{data: CreateVendorDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vendorsControllerSaveWithAnalyze>>, {data: CreateVendorDto}> = (props) => {
          const {data} = props ?? {};

          return  vendorsControllerSaveWithAnalyze(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VendorsControllerSaveWithAnalyzeMutationResult = NonNullable<Awaited<ReturnType<typeof vendorsControllerSaveWithAnalyze>>>
    export type VendorsControllerSaveWithAnalyzeMutationBody = CreateVendorDto
    export type VendorsControllerSaveWithAnalyzeMutationError = ErrorType<unknown>

    export const useVendorsControllerSaveWithAnalyze = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorsControllerSaveWithAnalyze>>, TError,{data: CreateVendorDto}, TContext>, }
) => {

      const mutationOptions = getVendorsControllerSaveWithAnalyzeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const dashboardControllerDashboard = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<DashboardDto>(
      {url: `/dashboard`, method: 'GET', signal
    },
      );
    }
  

export const getDashboardControllerDashboardQueryKey = () => {
    return [`/dashboard`] as const;
    }

    
export const getDashboardControllerDashboardQueryOptions = <TData = Awaited<ReturnType<typeof dashboardControllerDashboard>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerDashboard>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDashboardControllerDashboardQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dashboardControllerDashboard>>> = ({ signal }) => dashboardControllerDashboard(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerDashboard>>, TError, TData> & { queryKey: QueryKey }
}

export type DashboardControllerDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof dashboardControllerDashboard>>>
export type DashboardControllerDashboardQueryError = ErrorType<unknown>

export const useDashboardControllerDashboard = <TData = Awaited<ReturnType<typeof dashboardControllerDashboard>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerDashboard>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDashboardControllerDashboardQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const dashboardControllerWelcome = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<WelcomeDto>(
      {url: `/dashboard/welcome`, method: 'GET', signal
    },
      );
    }
  

export const getDashboardControllerWelcomeQueryKey = () => {
    return [`/dashboard/welcome`] as const;
    }

    
export const getDashboardControllerWelcomeQueryOptions = <TData = Awaited<ReturnType<typeof dashboardControllerWelcome>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerWelcome>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDashboardControllerWelcomeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dashboardControllerWelcome>>> = ({ signal }) => dashboardControllerWelcome(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerWelcome>>, TError, TData> & { queryKey: QueryKey }
}

export type DashboardControllerWelcomeQueryResult = NonNullable<Awaited<ReturnType<typeof dashboardControllerWelcome>>>
export type DashboardControllerWelcomeQueryError = ErrorType<unknown>

export const useDashboardControllerWelcome = <TData = Awaited<ReturnType<typeof dashboardControllerWelcome>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dashboardControllerWelcome>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDashboardControllerWelcomeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const mvfFileControllerMvfFiles = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<MvfFileDto[]>(
      {url: `/mvf-files`, method: 'GET', signal
    },
      );
    }
  

export const getMvfFileControllerMvfFilesQueryKey = () => {
    return [`/mvf-files`] as const;
    }

    
export const getMvfFileControllerMvfFilesQueryOptions = <TData = Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMvfFileControllerMvfFilesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>> = ({ signal }) => mvfFileControllerMvfFiles(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type MvfFileControllerMvfFilesQueryResult = NonNullable<Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>>
export type MvfFileControllerMvfFilesQueryError = ErrorType<unknown>

export const useMvfFileControllerMvfFiles = <TData = Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof mvfFileControllerMvfFiles>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getMvfFileControllerMvfFilesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const entitiesControllerRemoveAll = (
    
 ) => {
      
      
      return customInstance<boolean>(
      {url: `/entities`, method: 'DELETE'
    },
      );
    }
  


export const getEntitiesControllerRemoveAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveAll>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveAll>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof entitiesControllerRemoveAll>>, void> = () => {
          

          return  entitiesControllerRemoveAll()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type EntitiesControllerRemoveAllMutationResult = NonNullable<Awaited<ReturnType<typeof entitiesControllerRemoveAll>>>
    
    export type EntitiesControllerRemoveAllMutationError = ErrorType<unknown>

    export const useEntitiesControllerRemoveAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveAll>>, TError,void, TContext>, }
) => {

      const mutationOptions = getEntitiesControllerRemoveAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const entitiesControllerRemoveByOrganizationId = (
    id: number,
 ) => {
      
      
      return customInstance<boolean>(
      {url: `/entities/organization/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getEntitiesControllerRemoveByOrganizationIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByOrganizationId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByOrganizationId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof entitiesControllerRemoveByOrganizationId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  entitiesControllerRemoveByOrganizationId(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type EntitiesControllerRemoveByOrganizationIdMutationResult = NonNullable<Awaited<ReturnType<typeof entitiesControllerRemoveByOrganizationId>>>
    
    export type EntitiesControllerRemoveByOrganizationIdMutationError = ErrorType<unknown>

    export const useEntitiesControllerRemoveByOrganizationId = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByOrganizationId>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = getEntitiesControllerRemoveByOrganizationIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const entitiesControllerRemoveByCompanyId = (
    id: number,
 ) => {
      
      
      return customInstance<boolean>(
      {url: `/entities/company/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getEntitiesControllerRemoveByCompanyIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByCompanyId>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByCompanyId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof entitiesControllerRemoveByCompanyId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  entitiesControllerRemoveByCompanyId(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type EntitiesControllerRemoveByCompanyIdMutationResult = NonNullable<Awaited<ReturnType<typeof entitiesControllerRemoveByCompanyId>>>
    
    export type EntitiesControllerRemoveByCompanyIdMutationError = ErrorType<unknown>

    export const useEntitiesControllerRemoveByCompanyId = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof entitiesControllerRemoveByCompanyId>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = getEntitiesControllerRemoveByCompanyIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const roleControllerCreateRole = (
    createRoleDto: CreateRoleDto,
 ) => {
      
      
      return customInstance<RoleDto>(
      {url: `/roles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createRoleDto
    },
      );
    }
  


export const getRoleControllerCreateRoleMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerCreateRole>>, TError,{data: CreateRoleDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof roleControllerCreateRole>>, TError,{data: CreateRoleDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof roleControllerCreateRole>>, {data: CreateRoleDto}> = (props) => {
          const {data} = props ?? {};

          return  roleControllerCreateRole(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RoleControllerCreateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof roleControllerCreateRole>>>
    export type RoleControllerCreateRoleMutationBody = CreateRoleDto
    export type RoleControllerCreateRoleMutationError = ErrorType<unknown>

    export const useRoleControllerCreateRole = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerCreateRole>>, TError,{data: CreateRoleDto}, TContext>, }
) => {

      const mutationOptions = getRoleControllerCreateRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const roleControllerGetAllRoles = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<RoleDto[]>(
      {url: `/roles`, method: 'GET', signal
    },
      );
    }
  

export const getRoleControllerGetAllRolesQueryKey = () => {
    return [`/roles`] as const;
    }

    
export const getRoleControllerGetAllRolesQueryOptions = <TData = Awaited<ReturnType<typeof roleControllerGetAllRoles>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetAllRoles>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRoleControllerGetAllRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof roleControllerGetAllRoles>>> = ({ signal }) => roleControllerGetAllRoles(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetAllRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type RoleControllerGetAllRolesQueryResult = NonNullable<Awaited<ReturnType<typeof roleControllerGetAllRoles>>>
export type RoleControllerGetAllRolesQueryError = ErrorType<unknown>

export const useRoleControllerGetAllRoles = <TData = Awaited<ReturnType<typeof roleControllerGetAllRoles>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetAllRoles>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRoleControllerGetAllRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const roleControllerGetRoleById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<RoleDto>(
      {url: `/roles/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getRoleControllerGetRoleByIdQueryKey = (id: number,) => {
    return [`/roles/${id}`] as const;
    }

    
export const getRoleControllerGetRoleByIdQueryOptions = <TData = Awaited<ReturnType<typeof roleControllerGetRoleById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetRoleById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRoleControllerGetRoleByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof roleControllerGetRoleById>>> = ({ signal }) => roleControllerGetRoleById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetRoleById>>, TError, TData> & { queryKey: QueryKey }
}

export type RoleControllerGetRoleByIdQueryResult = NonNullable<Awaited<ReturnType<typeof roleControllerGetRoleById>>>
export type RoleControllerGetRoleByIdQueryError = ErrorType<unknown>

export const useRoleControllerGetRoleById = <TData = Awaited<ReturnType<typeof roleControllerGetRoleById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof roleControllerGetRoleById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRoleControllerGetRoleByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const roleControllerUpdateRole = (
    id: number,
    updateRoleDto: UpdateRoleDto,
 ) => {
      
      
      return customInstance<RoleDto>(
      {url: `/roles/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateRoleDto
    },
      );
    }
  


export const getRoleControllerUpdateRoleMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerUpdateRole>>, TError,{id: number;data: UpdateRoleDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof roleControllerUpdateRole>>, TError,{id: number;data: UpdateRoleDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof roleControllerUpdateRole>>, {id: number;data: UpdateRoleDto}> = (props) => {
          const {id,data} = props ?? {};

          return  roleControllerUpdateRole(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RoleControllerUpdateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof roleControllerUpdateRole>>>
    export type RoleControllerUpdateRoleMutationBody = UpdateRoleDto
    export type RoleControllerUpdateRoleMutationError = ErrorType<unknown>

    export const useRoleControllerUpdateRole = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerUpdateRole>>, TError,{id: number;data: UpdateRoleDto}, TContext>, }
) => {

      const mutationOptions = getRoleControllerUpdateRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const roleControllerDeleteRole = (
    id: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/roles/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getRoleControllerDeleteRoleMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerDeleteRole>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof roleControllerDeleteRole>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof roleControllerDeleteRole>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  roleControllerDeleteRole(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RoleControllerDeleteRoleMutationResult = NonNullable<Awaited<ReturnType<typeof roleControllerDeleteRole>>>
    
    export type RoleControllerDeleteRoleMutationError = ErrorType<unknown>

    export const useRoleControllerDeleteRole = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roleControllerDeleteRole>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = getRoleControllerDeleteRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const permissionControllerCreatePermission = (
    createPermissionDto: CreatePermissionDto,
 ) => {
      
      
      return customInstance<PermissionDto>(
      {url: `/permissions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPermissionDto
    },
      );
    }
  


export const getPermissionControllerCreatePermissionMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerCreatePermission>>, TError,{data: CreatePermissionDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof permissionControllerCreatePermission>>, TError,{data: CreatePermissionDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof permissionControllerCreatePermission>>, {data: CreatePermissionDto}> = (props) => {
          const {data} = props ?? {};

          return  permissionControllerCreatePermission(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PermissionControllerCreatePermissionMutationResult = NonNullable<Awaited<ReturnType<typeof permissionControllerCreatePermission>>>
    export type PermissionControllerCreatePermissionMutationBody = CreatePermissionDto
    export type PermissionControllerCreatePermissionMutationError = ErrorType<unknown>

    export const usePermissionControllerCreatePermission = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerCreatePermission>>, TError,{data: CreatePermissionDto}, TContext>, }
) => {

      const mutationOptions = getPermissionControllerCreatePermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const permissionControllerGetAllPermissions = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<PermissionDto[]>(
      {url: `/permissions`, method: 'GET', signal
    },
      );
    }
  

export const getPermissionControllerGetAllPermissionsQueryKey = () => {
    return [`/permissions`] as const;
    }

    
export const getPermissionControllerGetAllPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPermissionControllerGetAllPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>> = ({ signal }) => permissionControllerGetAllPermissions(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type PermissionControllerGetAllPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>>
export type PermissionControllerGetAllPermissionsQueryError = ErrorType<unknown>

export const usePermissionControllerGetAllPermissions = <TData = Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetAllPermissions>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPermissionControllerGetAllPermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const permissionControllerGetPermissionById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PermissionDto>(
      {url: `/permissions/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getPermissionControllerGetPermissionByIdQueryKey = (id: number,) => {
    return [`/permissions/${id}`] as const;
    }

    
export const getPermissionControllerGetPermissionByIdQueryOptions = <TData = Awaited<ReturnType<typeof permissionControllerGetPermissionById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetPermissionById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPermissionControllerGetPermissionByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof permissionControllerGetPermissionById>>> = ({ signal }) => permissionControllerGetPermissionById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetPermissionById>>, TError, TData> & { queryKey: QueryKey }
}

export type PermissionControllerGetPermissionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof permissionControllerGetPermissionById>>>
export type PermissionControllerGetPermissionByIdQueryError = ErrorType<unknown>

export const usePermissionControllerGetPermissionById = <TData = Awaited<ReturnType<typeof permissionControllerGetPermissionById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof permissionControllerGetPermissionById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPermissionControllerGetPermissionByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const permissionControllerUpdatePermission = (
    id: number,
    updatePermissionDto: UpdatePermissionDto,
 ) => {
      
      
      return customInstance<PermissionDto>(
      {url: `/permissions/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updatePermissionDto
    },
      );
    }
  


export const getPermissionControllerUpdatePermissionMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerUpdatePermission>>, TError,{id: number;data: UpdatePermissionDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof permissionControllerUpdatePermission>>, TError,{id: number;data: UpdatePermissionDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof permissionControllerUpdatePermission>>, {id: number;data: UpdatePermissionDto}> = (props) => {
          const {id,data} = props ?? {};

          return  permissionControllerUpdatePermission(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PermissionControllerUpdatePermissionMutationResult = NonNullable<Awaited<ReturnType<typeof permissionControllerUpdatePermission>>>
    export type PermissionControllerUpdatePermissionMutationBody = UpdatePermissionDto
    export type PermissionControllerUpdatePermissionMutationError = ErrorType<unknown>

    export const usePermissionControllerUpdatePermission = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerUpdatePermission>>, TError,{id: number;data: UpdatePermissionDto}, TContext>, }
) => {

      const mutationOptions = getPermissionControllerUpdatePermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const permissionControllerDeletePermission = (
    id: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/permissions/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getPermissionControllerDeletePermissionMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerDeletePermission>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof permissionControllerDeletePermission>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof permissionControllerDeletePermission>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  permissionControllerDeletePermission(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PermissionControllerDeletePermissionMutationResult = NonNullable<Awaited<ReturnType<typeof permissionControllerDeletePermission>>>
    
    export type PermissionControllerDeletePermissionMutationError = ErrorType<unknown>

    export const usePermissionControllerDeletePermission = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof permissionControllerDeletePermission>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = getPermissionControllerDeletePermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerOrganizations = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<OrganizationDto[]>(
      {url: `/organization`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerOrganizationsQueryKey = () => {
    return [`/organization`] as const;
    }

    
export const getOrganizationControllerOrganizationsQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerOrganizations>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganizations>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerOrganizationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerOrganizations>>> = ({ signal }) => organizationControllerOrganizations(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganizations>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerOrganizations>>>
export type OrganizationControllerOrganizationsQueryError = ErrorType<unknown>

export const useOrganizationControllerOrganizations = <TData = Awaited<ReturnType<typeof organizationControllerOrganizations>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganizations>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerOrganizationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerCreateOrganization = (
    createOrganizationDto: CreateOrganizationDto,
 ) => {
      
      
      return customInstance<OrganizationDto>(
      {url: `/organization`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrganizationDto
    },
      );
    }
  


export const getOrganizationControllerCreateOrganizationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateOrganization>>, TError,{data: CreateOrganizationDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateOrganization>>, TError,{data: CreateOrganizationDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerCreateOrganization>>, {data: CreateOrganizationDto}> = (props) => {
          const {data} = props ?? {};

          return  organizationControllerCreateOrganization(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerCreateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerCreateOrganization>>>
    export type OrganizationControllerCreateOrganizationMutationBody = CreateOrganizationDto
    export type OrganizationControllerCreateOrganizationMutationError = ErrorType<unknown>

    export const useOrganizationControllerCreateOrganization = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerCreateOrganization>>, TError,{data: CreateOrganizationDto}, TContext>, }
) => {

      const mutationOptions = getOrganizationControllerCreateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerCompanies = (
    organizationId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompanyDto[]>(
      {url: `/organization/${organizationId}/companies`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerCompaniesQueryKey = (organizationId: number,) => {
    return [`/organization/${organizationId}/companies`] as const;
    }

    
export const getOrganizationControllerCompaniesQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerCompanies>>, TError = ErrorType<unknown>>(organizationId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerCompanies>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerCompaniesQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerCompanies>>> = ({ signal }) => organizationControllerCompanies(organizationId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerCompanies>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerCompanies>>>
export type OrganizationControllerCompaniesQueryError = ErrorType<unknown>

export const useOrganizationControllerCompanies = <TData = Awaited<ReturnType<typeof organizationControllerCompanies>>, TError = ErrorType<unknown>>(
 organizationId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerCompanies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerCompaniesQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerOrganization = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<OrganizationDto>(
      {url: `/organization/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerOrganizationQueryKey = (id: number,) => {
    return [`/organization/${id}`] as const;
    }

    
export const getOrganizationControllerOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerOrganization>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganization>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerOrganizationQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerOrganization>>> = ({ signal }) => organizationControllerOrganization(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerOrganization>>>
export type OrganizationControllerOrganizationQueryError = ErrorType<unknown>

export const useOrganizationControllerOrganization = <TData = Awaited<ReturnType<typeof organizationControllerOrganization>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerOrganization>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerOrganizationQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const organizationControllerUpdateOrganization = (
    id: number,
    updateOrganizationDto: UpdateOrganizationDto,
 ) => {
      
      
      return customInstance<OrganizationDto>(
      {url: `/organization/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateOrganizationDto
    },
      );
    }
  


export const getOrganizationControllerUpdateOrganizationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganization>>, TError,{id: number;data: UpdateOrganizationDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganization>>, TError,{id: number;data: UpdateOrganizationDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUpdateOrganization>>, {id: number;data: UpdateOrganizationDto}> = (props) => {
          const {id,data} = props ?? {};

          return  organizationControllerUpdateOrganization(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type OrganizationControllerUpdateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUpdateOrganization>>>
    export type OrganizationControllerUpdateOrganizationMutationBody = UpdateOrganizationDto
    export type OrganizationControllerUpdateOrganizationMutationError = ErrorType<unknown>

    export const useOrganizationControllerUpdateOrganization = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganization>>, TError,{id: number;data: UpdateOrganizationDto}, TContext>, }
) => {

      const mutationOptions = getOrganizationControllerUpdateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const organizationControllerUsers = (
    organizationId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserDto[]>(
      {url: `/organization/${organizationId}/users`, method: 'GET', signal
    },
      );
    }
  

export const getOrganizationControllerUsersQueryKey = (organizationId: number,) => {
    return [`/organization/${organizationId}/users`] as const;
    }

    
export const getOrganizationControllerUsersQueryOptions = <TData = Awaited<ReturnType<typeof organizationControllerUsers>>, TError = ErrorType<unknown>>(organizationId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerUsers>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrganizationControllerUsersQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerUsers>>> = ({ signal }) => organizationControllerUsers(organizationId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof organizationControllerUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type OrganizationControllerUsersQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUsers>>>
export type OrganizationControllerUsersQueryError = ErrorType<unknown>

export const useOrganizationControllerUsers = <TData = Awaited<ReturnType<typeof organizationControllerUsers>>, TError = ErrorType<unknown>>(
 organizationId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof organizationControllerUsers>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOrganizationControllerUsersQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorOnBoardingControllerAuth = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<VendorOnBoardingMeDto>(
      {url: `/vendor-on-boarding/auth`, method: 'GET', signal
    },
      );
    }
  

export const getVendorOnBoardingControllerAuthQueryKey = () => {
    return [`/vendor-on-boarding/auth`] as const;
    }

    
export const getVendorOnBoardingControllerAuthQueryOptions = <TData = Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorOnBoardingControllerAuthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>> = ({ signal }) => vendorOnBoardingControllerAuth(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorOnBoardingControllerAuthQueryResult = NonNullable<Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>>
export type VendorOnBoardingControllerAuthQueryError = ErrorType<unknown>

export const useVendorOnBoardingControllerAuth = <TData = Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAuth>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVendorOnBoardingControllerAuthQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorOnBoardingControllerInvite = (
    inviteVendorOnBoardingDto: InviteVendorOnBoardingDto,
 ) => {
      
      
      return customInstance<void>(
      {url: `/vendor-on-boarding/invite`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inviteVendorOnBoardingDto
    },
      );
    }
  


export const getVendorOnBoardingControllerInviteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerInvite>>, TError,{data: InviteVendorOnBoardingDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerInvite>>, TError,{data: InviteVendorOnBoardingDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vendorOnBoardingControllerInvite>>, {data: InviteVendorOnBoardingDto}> = (props) => {
          const {data} = props ?? {};

          return  vendorOnBoardingControllerInvite(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VendorOnBoardingControllerInviteMutationResult = NonNullable<Awaited<ReturnType<typeof vendorOnBoardingControllerInvite>>>
    export type VendorOnBoardingControllerInviteMutationBody = InviteVendorOnBoardingDto
    export type VendorOnBoardingControllerInviteMutationError = ErrorType<unknown>

    export const useVendorOnBoardingControllerInvite = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerInvite>>, TError,{data: InviteVendorOnBoardingDto}, TContext>, }
) => {

      const mutationOptions = getVendorOnBoardingControllerInviteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const vendorOnBoardingControllerVendorsOnBoarding = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<VendorOnBoardingDto[]>(
      {url: `/vendor-on-boarding`, method: 'GET', signal
    },
      );
    }
  

export const getVendorOnBoardingControllerVendorsOnBoardingQueryKey = () => {
    return [`/vendor-on-boarding`] as const;
    }

    
export const getVendorOnBoardingControllerVendorsOnBoardingQueryOptions = <TData = Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVendorOnBoardingControllerVendorsOnBoardingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>> = ({ signal }) => vendorOnBoardingControllerVendorsOnBoarding(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>, TError, TData> & { queryKey: QueryKey }
}

export type VendorOnBoardingControllerVendorsOnBoardingQueryResult = NonNullable<Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>>
export type VendorOnBoardingControllerVendorsOnBoardingQueryError = ErrorType<unknown>

export const useVendorOnBoardingControllerVendorsOnBoarding = <TData = Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerVendorsOnBoarding>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVendorOnBoardingControllerVendorsOnBoardingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const vendorOnBoardingControllerAnalyzeAndSave = (
    createVendorDto: CreateVendorDto,
 ) => {
      
      
      return customInstance<VendorOnBoardingDto>(
      {url: `/vendor-on-boarding/analyze-and-save`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createVendorDto
    },
      );
    }
  


export const getVendorOnBoardingControllerAnalyzeAndSaveMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAnalyzeAndSave>>, TError,{data: CreateVendorDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAnalyzeAndSave>>, TError,{data: CreateVendorDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vendorOnBoardingControllerAnalyzeAndSave>>, {data: CreateVendorDto}> = (props) => {
          const {data} = props ?? {};

          return  vendorOnBoardingControllerAnalyzeAndSave(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VendorOnBoardingControllerAnalyzeAndSaveMutationResult = NonNullable<Awaited<ReturnType<typeof vendorOnBoardingControllerAnalyzeAndSave>>>
    export type VendorOnBoardingControllerAnalyzeAndSaveMutationBody = CreateVendorDto
    export type VendorOnBoardingControllerAnalyzeAndSaveMutationError = ErrorType<unknown>

    export const useVendorOnBoardingControllerAnalyzeAndSave = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vendorOnBoardingControllerAnalyzeAndSave>>, TError,{data: CreateVendorDto}, TContext>, }
) => {

      const mutationOptions = getVendorOnBoardingControllerAnalyzeAndSaveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const ledgerControllerLedger = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<LedgerEntryDto[]>(
      {url: `/ledger`, method: 'GET', signal
    },
      );
    }
  

export const getLedgerControllerLedgerQueryKey = () => {
    return [`/ledger`] as const;
    }

    
export const getLedgerControllerLedgerQueryOptions = <TData = Awaited<ReturnType<typeof ledgerControllerLedger>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedger>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerControllerLedgerQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerControllerLedger>>> = ({ signal }) => ledgerControllerLedger(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedger>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerControllerLedgerQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerControllerLedger>>>
export type LedgerControllerLedgerQueryError = ErrorType<unknown>

export const useLedgerControllerLedger = <TData = Awaited<ReturnType<typeof ledgerControllerLedger>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedger>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerControllerLedgerQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ledgerControllerLedgerEntry = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<LedgerEntryDto>(
      {url: `/ledger/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getLedgerControllerLedgerEntryQueryKey = (id: number,) => {
    return [`/ledger/${id}`] as const;
    }

    
export const getLedgerControllerLedgerEntryQueryOptions = <TData = Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerControllerLedgerEntryQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>> = ({ signal }) => ledgerControllerLedgerEntry(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerControllerLedgerEntryQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>>
export type LedgerControllerLedgerEntryQueryError = ErrorType<unknown>

export const useLedgerControllerLedgerEntry = <TData = Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerControllerLedgerEntry>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerControllerLedgerEntryQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const emailControllerGetEmails = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<EmailDto[]>(
      {url: `/email`, method: 'GET', signal
    },
      );
    }
  

export const getEmailControllerGetEmailsQueryKey = () => {
    return [`/email`] as const;
    }

    
export const getEmailControllerGetEmailsQueryOptions = <TData = Awaited<ReturnType<typeof emailControllerGetEmails>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof emailControllerGetEmails>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEmailControllerGetEmailsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof emailControllerGetEmails>>> = ({ signal }) => emailControllerGetEmails(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof emailControllerGetEmails>>, TError, TData> & { queryKey: QueryKey }
}

export type EmailControllerGetEmailsQueryResult = NonNullable<Awaited<ReturnType<typeof emailControllerGetEmails>>>
export type EmailControllerGetEmailsQueryError = ErrorType<unknown>

export const useEmailControllerGetEmails = <TData = Awaited<ReturnType<typeof emailControllerGetEmails>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof emailControllerGetEmails>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getEmailControllerGetEmailsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ruleControllerRules = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<RuleDto[]>(
      {url: `/rule`, method: 'GET', signal
    },
      );
    }
  

export const getRuleControllerRulesQueryKey = () => {
    return [`/rule`] as const;
    }

    
export const getRuleControllerRulesQueryOptions = <TData = Awaited<ReturnType<typeof ruleControllerRules>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRules>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRuleControllerRulesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ruleControllerRules>>> = ({ signal }) => ruleControllerRules(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRules>>, TError, TData> & { queryKey: QueryKey }
}

export type RuleControllerRulesQueryResult = NonNullable<Awaited<ReturnType<typeof ruleControllerRules>>>
export type RuleControllerRulesQueryError = ErrorType<unknown>

export const useRuleControllerRules = <TData = Awaited<ReturnType<typeof ruleControllerRules>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRules>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRuleControllerRulesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ruleControllerCreate = (
    createRuleDto: CreateRuleDto,
 ) => {
      
      
      return customInstance<RuleDto>(
      {url: `/rule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createRuleDto
    },
      );
    }
  


export const getRuleControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreate>>, TError,{data: CreateRuleDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreate>>, TError,{data: CreateRuleDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ruleControllerCreate>>, {data: CreateRuleDto}> = (props) => {
          const {data} = props ?? {};

          return  ruleControllerCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RuleControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ruleControllerCreate>>>
    export type RuleControllerCreateMutationBody = CreateRuleDto
    export type RuleControllerCreateMutationError = ErrorType<unknown>

    export const useRuleControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreate>>, TError,{data: CreateRuleDto}, TContext>, }
) => {

      const mutationOptions = getRuleControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const ruleControllerUpdate = (
    id: number,
    updateRuleDto: UpdateRuleDto,
 ) => {
      
      
      return customInstance<RuleDto>(
      {url: `/rule/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateRuleDto
    },
      );
    }
  


export const getRuleControllerUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdate>>, TError,{id: number;data: UpdateRuleDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdate>>, TError,{id: number;data: UpdateRuleDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ruleControllerUpdate>>, {id: number;data: UpdateRuleDto}> = (props) => {
          const {id,data} = props ?? {};

          return  ruleControllerUpdate(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RuleControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ruleControllerUpdate>>>
    export type RuleControllerUpdateMutationBody = UpdateRuleDto
    export type RuleControllerUpdateMutationError = ErrorType<unknown>

    export const useRuleControllerUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdate>>, TError,{id: number;data: UpdateRuleDto}, TContext>, }
) => {

      const mutationOptions = getRuleControllerUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const ruleControllerRulesConfiguration = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<RuleConfigurationDto[]>(
      {url: `/rule/configuration`, method: 'GET', signal
    },
      );
    }
  

export const getRuleControllerRulesConfigurationQueryKey = () => {
    return [`/rule/configuration`] as const;
    }

    
export const getRuleControllerRulesConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRuleControllerRulesConfigurationQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>> = ({ signal }) => ruleControllerRulesConfiguration(signal);

      

      

   return  { queryKey, queryFn,   staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type RuleControllerRulesConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>>
export type RuleControllerRulesConfigurationQueryError = ErrorType<unknown>

export const useRuleControllerRulesConfiguration = <TData = Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerRulesConfiguration>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRuleControllerRulesConfigurationQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ruleControllerUpdateRulesConfiguration = (
    id: number,
    updateRuleConfigurationDto: UpdateRuleConfigurationDto,
 ) => {
      
      
      return customInstance<RuleConfigurationDto>(
      {url: `/rule/configuration/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateRuleConfigurationDto
    },
      );
    }
  


export const getRuleControllerUpdateRulesConfigurationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdateRulesConfiguration>>, TError,{id: number;data: UpdateRuleConfigurationDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdateRulesConfiguration>>, TError,{id: number;data: UpdateRuleConfigurationDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ruleControllerUpdateRulesConfiguration>>, {id: number;data: UpdateRuleConfigurationDto}> = (props) => {
          const {id,data} = props ?? {};

          return  ruleControllerUpdateRulesConfiguration(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RuleControllerUpdateRulesConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof ruleControllerUpdateRulesConfiguration>>>
    export type RuleControllerUpdateRulesConfigurationMutationBody = UpdateRuleConfigurationDto
    export type RuleControllerUpdateRulesConfigurationMutationError = ErrorType<unknown>

    export const useRuleControllerUpdateRulesConfiguration = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerUpdateRulesConfiguration>>, TError,{id: number;data: UpdateRuleConfigurationDto}, TContext>, }
) => {

      const mutationOptions = getRuleControllerUpdateRulesConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const ruleControllerAdminRulesConfiguration = (
    companyId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<RuleConfigurationDto[]>(
      {url: `/rule/admin/configuration/${companyId}`, method: 'GET', signal
    },
      );
    }
  

export const getRuleControllerAdminRulesConfigurationQueryKey = (companyId: number,) => {
    return [`/rule/admin/configuration/${companyId}`] as const;
    }

    
export const getRuleControllerAdminRulesConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>, TError = ErrorType<unknown>>(companyId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRuleControllerAdminRulesConfigurationQueryKey(companyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>> = ({ signal }) => ruleControllerAdminRulesConfiguration(companyId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyId),  staleTime: 900000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type RuleControllerAdminRulesConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>>
export type RuleControllerAdminRulesConfigurationQueryError = ErrorType<unknown>

export const useRuleControllerAdminRulesConfiguration = <TData = Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>, TError = ErrorType<unknown>>(
 companyId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ruleControllerAdminRulesConfiguration>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRuleControllerAdminRulesConfigurationQueryOptions(companyId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const ruleControllerCreateRulesConfiguration = (
    createRuleConfigurationDto: CreateRuleConfigurationDto,
 ) => {
      
      
      return customInstance<RuleConfigurationDto>(
      {url: `/rule/admin/configuration`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createRuleConfigurationDto
    },
      );
    }
  


export const getRuleControllerCreateRulesConfigurationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreateRulesConfiguration>>, TError,{data: CreateRuleConfigurationDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreateRulesConfiguration>>, TError,{data: CreateRuleConfigurationDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ruleControllerCreateRulesConfiguration>>, {data: CreateRuleConfigurationDto}> = (props) => {
          const {data} = props ?? {};

          return  ruleControllerCreateRulesConfiguration(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RuleControllerCreateRulesConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof ruleControllerCreateRulesConfiguration>>>
    export type RuleControllerCreateRulesConfigurationMutationBody = CreateRuleConfigurationDto
    export type RuleControllerCreateRulesConfigurationMutationError = ErrorType<unknown>

    export const useRuleControllerCreateRulesConfiguration = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ruleControllerCreateRulesConfiguration>>, TError,{data: CreateRuleConfigurationDto}, TContext>, }
) => {

      const mutationOptions = getRuleControllerCreateRulesConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
