import React from "react"
import { useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import TableTextCell from "common/TableTextCell"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import { slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { formatDate, getCompanyNames } from "utils/utils"
import { useOrganizationControllerUsers } from "../../apis/endpoints/edgeService"
import TablePageSkeleton from "../../common/TablePageSkeleton"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"

const useStyles = makeStyles()(() => ({
  users: {
    padding: "1rem 1rem",
  },
  table: {
    height: "80vh",
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))

const OrganizationUsers = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const params = useParams()
  const companyId: number = Number(params.id)
  const navigate: NavigateFunction = useNavigate()

  const { data, isLoading } = useOrganizationControllerUsers(companyId, {
    query: {
      refetchOnMount: "always",
    },
  })

  const navigateTo = (url: string) => navigate(url)

  const columns: any[] = [
    {
      field: "id",
      headerName: t.General.id,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "name",
      headerName: t.General.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "email",
      headerName: t.General.email,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "roleDto",
      headerName: t.General.role,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value.name} />,
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      field: "companiesDto",
      headerName: t.General.companies,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={getCompanyNames(params.value)} />,
    },
    {
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <EditIcon
            fontSize={"small"}
            className={classes.actionIcon}
            onClick={navigateTo.bind(null, `${params.row.id}`)}
          />
        </Box>
      ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.users} spacing={1}>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent={"space-between"}>
          <Typography
            variant="h6"
            data-testid={"title"}
          >{`${t.General.createUser} for company id:${companyId}`}</Typography>
          <Button variant={"contained"} onClick={navigateTo.bind(null, "new")} data-testid={"create-user-button"}>
            {t.General.createUser}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.table}>
          <DataGrid
            disableDensitySelector
            disableColumnSelector
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            slotProps={slotProps}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OrganizationUsers
