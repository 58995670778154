/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type FindingDtoCategory = typeof FindingDtoCategory[keyof typeof FindingDtoCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FindingDtoCategory = {
  BankAccountVerification: 'BankAccountVerification',
  Sanctions: 'Sanctions',
  DataValidation: 'DataValidation',
  Cyber: 'Cyber',
  Vulnerability: 'Vulnerability',
} as const;
