import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { formatDate } from "utils/utils"
import { VendorDto } from "../../apis/model"
import FieldWithValue from "../../common/FieldWithValue"

const useStyles = makeStyles()(() => ({
  expand: {
    padding: "1.5rem",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0.75rem",
  },
}))

interface IProps {
  row: VendorDto
}
const ExpandVendor = ({ row }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Grid container className={classes.expand}>
      <Grid item xs={12} md={5}>
        <FieldWithValue name={t.General.primaryContact} value={row.primaryContact || "-"} />
        <FieldWithValue name={t.General.primarySubsidiary} value={row.primarySubsidiary || "-"} />
        <FieldWithValue name={t.General.phone} value={row.phone || "-"} />
        <FieldWithValue name={t.General.billingZipCode} value={row.billingZip || "-"} />
        <FieldWithValue name={t.General.billingRegion} value={row.billingRegion || "-"} />
        <FieldWithValue name={t.General.billingCity} value={row.billingCity || "-"} />
      </Grid>
      <Grid item xs={12} md={4}>
        <FieldWithValue name={t.General.swiftCode} value={row.swift || "-"} testId={"swift"} />
        <FieldWithValue name={t.General.abaNumber} value={row.abaNumber || "-"} />
        <FieldWithValue name={t.General.bankAccountNumber} value={row.bankAccountNumber || "-"} />
      </Grid>
      <Grid item xs={12} md={3}>
        <FieldWithValue name={t.General.dateChanged} value={row?.dateCreated ? formatDate(row.dateCreated) : "-"} />
        <FieldWithValue name={t.General.lastModified} value={row?.lastModified ? formatDate(row.lastModified) : "-"} />
      </Grid>
    </Grid>
  )
}

export default ExpandVendor
