/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type CreateCompanyDtoMvfFormat = typeof CreateCompanyDtoMvfFormat[keyof typeof CreateCompanyDtoMvfFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCompanyDtoMvfFormat = {
  NetSuite: 'NetSuite',
  NetSuite_Claroty: 'NetSuite_Claroty',
} as const;
