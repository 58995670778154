export interface INavItem {
  title: string
  path: string
  badge?: number
}

export interface Asset {
  name: string
  total: number
  linkTo: string
  alerts: number
  type: string
}

export enum UploadType {
  Mvf = "mvf",
  Payment = "payment",
  Ledger = "ledger",
  Transaction = "transaction",
  VendorQuestionnaire = "VendorQuestionnaire",
}

export interface SideMenuItem {
  title: string
  icon: JSX.Element
  url: string
}

export enum VendorFormType {
  NewVendor = "NewVendor",
  VendorOnBoarding = "VendorOnBoarding",
  UpdateVendor = "UpdateVendor",
}
