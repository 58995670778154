import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import { ITheme } from "theme/theme"
import { useCompanyControllerCompany } from "../../apis/endpoints/edgeService"
import CompanyForm from "./CompanyForm"
import { CreateCompanyDto, CreateCompanyDtoMvfFormat, CreateCompanyDtoPaymentFormat } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  company: {
    padding: "2rem 1rem",
  },
  form: {
    height: "40vh",
    padding: "1rem 0",
  },
  save: {
    margin: "0 2rem 0 0",
  },
}))

const CompanyEdit = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const companyId: number = params.companyId === "new" ? -1 : Number(params.companyId)
  const organizationId: number = Number(params.id)

  const [company, setCompany] = useState<CreateCompanyDto>({
    domain: "",
    name: "",
    mvfFormat: CreateCompanyDtoMvfFormat.NetSuite,
    paymentFormat: CreateCompanyDtoPaymentFormat.CHASE,
    organizationId,
  })

  const { data, isSuccess } = useCompanyControllerCompany(companyId, {
    query: {
      enabled: companyId > 0,
    },
  })

  useEffect(() => {
    isSuccess && setCompany(data)
  }, [isSuccess, data])

  const backToCompanies = () => navigate(-1)

  return (
    <Grid container className={classes.company} spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant={"h6"}>{company?.name ? `Edit ${company.name}` : t.General.newCompany}</Typography>
      </Grid>
      <Grid item md={6} xs={12} spacing={3}>
        <CompanyForm nextStep={backToCompanies} company={company} setCompany={setCompany} />
      </Grid>
    </Grid>
  )
}

export default CompanyEdit
