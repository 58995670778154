import React from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import SkeletonLTable from "./SkeletonTable"
import { DataGrid } from "@mui/x-data-grid"

const useStyles = makeStyles()(() => ({
  page: {
    padding: "1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    height: "78vh",
  },
  tableTop: {
    height: "10rem",
  },
}))

interface IProps {
  columns?: any[]
}

const TablePageSkeleton = ({ columns = [] }: IProps): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Grid container className={classes.paper} spacing={1} data-testid={"loading"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Skeleton height={"3.5rem"} variant={"rectangular"} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <Grid item className={classes.tableTop} />
            <DataGrid
              columns={columns}
              rows={[]}
              loading={true}
              components={{
                LoadingOverlay: SkeletonLTable,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TablePageSkeleton
