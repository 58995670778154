import React from "react"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Upload from "../../common/Upload"
import Box from "@mui/material/Box"
import { UploadType } from "../../utils/types"

const useStyles = makeStyles()(() => ({
  paper: {
    padding: "1rem",
  },
  upload: {
    height: "80vh",
    padding: "1rem",
  },
  head: {
    padding: "1rem",
    height: "auto",
  },
  file: {
    marginTop: "1rem",
  },
}))

const UploadBankTransactions = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Box className={classes.upload}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.head}>
            <Typography variant="h6">{t.General.uploadBankTransactions}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container className={classes.upload} paddingTop={10}>
              <Upload type={UploadType.Payment} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UploadBankTransactions
