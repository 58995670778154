import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import TableTextCell from "common/TableTextCell"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { useAuthControllerGetProfile, useVendorsControllerVendors } from "apis/endpoints/edgeService"
import { formatDate } from "utils/utils"
import { NavigateFunction, useNavigate } from "react-router"
import TablePageSkeleton from "common/TablePageSkeleton"
import { slotProps } from "theme/data-grid"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import Button from "@mui/material/Button"
import { DataGridPro, DataGridProProps, GridRowId, GridToolbar } from "@mui/x-data-grid-pro"
import { GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-premium"
import Link from "@mui/material/Link"
import ExpandVendor from "./ExpandVendor"
import Stack from "@mui/material/Stack"
// import GppMaybeIcon from "@mui/icons-material/GppMaybe"
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined"
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined"

const useStyles = makeStyles()(() => ({
  vendors: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    height: "80vh",
    width: "100%",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
  actionIcon: {
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))

const Vendors = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()

  const navigateTo = (url: number | string) => navigate(`${url}`)

  const goToVendor = (id: number, e: any) => {
    e.stopPropagation()
    navigate(`/vendor/${id}`)
  }

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const { data, isLoading } = useVendorsControllerVendors({
    query: {
      refetchOnMount: "always",
    },
  })
  // const { data: inviteData, isLoading: isLoadingInvite } = useVendorOnBoardingControllerVendorsOnBoarding()
  const { data: meData, isLoading: isLoadingMe } = useAuthControllerGetProfile()

  const handleRowEvent: GridEventListener<"rowClick"> = (params: GridRowParams<any>) =>
    navigateTo(params.id !== "" ? `/vendors/${params.id}` : `/vendor/${params.id}`)

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandVendor row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "atRisk",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"}>
          {params.value ? (
            <GppMaybeOutlinedIcon color={"error"} sx={{ verticalAlign: "middle" }} />
          ) : (
            <GppGoodOutlinedIcon color={"success"} sx={{ verticalAlign: "middle" }} />
          )}
          <TableTextCell style={{ margin: "auto auto auto 0.75rem" }} content={params.value ? "At Risk" : " No Risk"} />
        </Stack>
      ),
    },
    {
      field: "name",
      headerName: t.General.name,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <Box data-testid={params.value}>
          <TableTextCell content={params.value || "N/A"} style={{ fontWeight: 600 }} testId={params.value} />
          <Link
            type="email"
            sx={{ fontSize: "0.75rem", lineHeight: "1rem", cursor: "pointer" }}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.stopPropagation()
              window.location.replace(`mailto:${params.row.email}`)
            }}
          >
            {params.row.email || ""}
          </Link>
        </Box>
      ),
    },
    {
      field: "mvfFileId",
      headerName: "Source",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value ? "ERP" : "Manual"} testId={params.value} />
      ),
    },
    {
      field: "internalId",
      headerName: "Owner",
      flex: 1.2,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={meData?.user.name || ""} testId={params.value} />
      ),
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      headerName: t.General.actions,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <EditIcon fontSize={"small"} className={classes.actionIcon} onClick={goToVendor.bind(this, params.row.id)} />
        </Box>
      ),
    },
  ]

  // const inviteColumns: any[] = [
  //   { field: "id", headerName: t.General.id, flex: 2 },
  //   {
  //     field: "vendorName",
  //     headerName: t.General.vendorName,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) =>
  //       params.row.status === VendorOnBoardingDtoStatus.Success ? (
  //         <Link type="button" component="button" onClick={navigateTo.bind(null, `/vendors/${params.row.vendorDto.id}`)}>
  //           <TableTextCell content={params.value} />
  //         </Link>
  //       ) : (
  //         <TableTextCell content={params.value} />
  //       ),
  //   },
  //   {
  //     field: "primaryContact",
  //     headerName: t.General.primaryContact,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
  //   },
  //   {
  //     field: "email",
  //     headerName: t.General.email,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell testId={params.value} content={params.value} />,
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: t.General.createdAt,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
  //   },
  //   {
  //     field: "updatedAt",
  //     headerName: t.General.updatedAt,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
  //   },
  //   {
  //     field: "status",
  //     headerName: t.General.status,
  //     flex: 2,
  //     renderCell: (params: GridRenderCellParams) => (
  //       <TableTextCell content={params.value === "Success" ? "Evaluation Completed" : params.value} />
  //     ),
  //   },
  //   {
  //     flex: 0.8,
  //     renderCell: (params: GridRenderCellParams) =>
  //       params.row.status === VendorOnBoardingDtoStatus.Success ? (
  //         <BarChartIcon onClick={navigateTo.bind(null, `/vendors/${params.row.vendorDto.id}`)} />
  //       ) : null,
  //   },
  // ]

  if (isLoading || !data /*|| !inviteData || isLoadingInvite*/ || !meData || isLoadingMe)
    return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.vendors} spacing={1} data-testid={"vendors"}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Button variant={"contained"} onClick={navigateTo.bind(null, "/vendor")} data-testid={"create-new"}>
              {t.General.createNewVendor}
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Typography variant="h6" className={classes.title} data-testid={"title"}>
              {data?.length.toLocaleString()} {t.General.vendors}
            </Typography>
            <Grid container className={classes.table}>
              <DataGridPro
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                rows={data || []}
                pagination
                columns={columns}
                onRowClick={handleRowEvent}
                slotProps={slotProps}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                initialState={{
                  // columns: {
                  //   columnVisibilityModel: {
                  //     id: false,
                  //   },
                  // },
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
              />
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Vendors
