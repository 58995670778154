import React from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"

const useStyles = makeStyles()(() => ({
  vendor: {
    padding: "1rem",
  },
  paper: {
    padding: "1rem",
  },
}))

const VendorSkeleton = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Grid container className={classes.vendor} spacing={1} data-testid={"loading"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Skeleton variant={"rectangular"} height={"35rem"} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant={"rectangular"} height={"20rem"} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Skeleton variant={"rectangular"} height={"20rem"} />
      </Grid>
    </Grid>
  )
}

export default VendorSkeleton
