import React, { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import { Skeleton, useTheme } from "@mui/material"
import OrganizationForm from "./OrganizationForm"
import { useNavigate } from "react-router"
import Typography from "@mui/material/Typography"
import { useOrganizationControllerOrganization } from "../../apis/endpoints/edgeService"
import { useParams } from "react-router-dom"
import { OrganizationDto } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  orgEdit: {
    padding: "2rem 1rem",
  },
}))

const OrganizationEdit = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate = useNavigate()
  const params = useParams()
  const organizationId: number = Number(params.id)

  const [organization, setOrganization] = useState<OrganizationDto | null>(null)

  const { data, isSuccess } = useOrganizationControllerOrganization<OrganizationDto>(organizationId, {
    query: {
      enabled: organizationId !== null,
    },
  })

  useEffect(() => {
    isSuccess && setOrganization(data)
  }, [isSuccess, data])

  const handleNext = () => navigate("/settings/admin")

  if (organizationId && !organization?.id)
    return (
      <Grid container spacing={3} className={classes.orgEdit}>
        <Grid item xs={12} md={8}>
          <Skeleton variant={"rectangular"} height={"3rem"} width={"25rem"} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Skeleton variant={"rectangular"} height={"3rem"} width={"25rem"} />
        </Grid>
      </Grid>
    )

  return (
    <Grid container spacing={3} className={classes.orgEdit}>
      <Grid item xs={12} md={8}>
        <Typography variant={"h6"}>
          {organization?.name ? `Edit ${organization.name}` : t.General.createOrganization}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        {organization?.id && (
          <OrganizationForm handleNext={handleNext} saveOrganization={setOrganization} organizationDto={organization} />
        )}
      </Grid>
    </Grid>
  )
}

export default OrganizationEdit
