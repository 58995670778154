import React from "react"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Skeleton from "@mui/material/Skeleton"

const useStyles = makeStyles()(() => ({
  riskAnalyzerForm: {
    padding: "1rem",
  },
  paper: {
    padding: "1rem",
  },
  formPaper: {
    padding: "2rem 1rem",
  },
}))
const RuleFormSkeleton = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Grid container className={classes.riskAnalyzerForm} spacing={1} data-testid={"loading"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Skeleton variant={"rectangular"} height={"3rem"}></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Skeleton variant={"rectangular"} height={"16rem"}></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Skeleton variant={"rectangular"} height={"16rem"}></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Skeleton variant={"rectangular"} height={"3rem"}></Skeleton>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RuleFormSkeleton
