import React, { useEffect } from "react"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useAuthControllerGetProfile, useDashboardControllerDashboard } from "apis/endpoints/edgeService"
import { makeStyles } from "makeStyles"
import DashboardSkeleton from "../dashboard/DashboardSkeleton"
import Assets from "./Assets"
import FindingsByType from "./FindingsByType"
import { ITheme } from "../../theme/theme"
import PaymentCycle from "./PaymentsCycle"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { Fab, useTheme } from "@mui/material"
import RiskAlerts from "./RiskAlerts"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import { useNavigate } from "react-router"

const useStyles = makeStyles()(() => ({
  overview: {
    padding: "0rem 2rem",
  },
  paperTitle: {
    textAlign: "left",
  },
  topPaper: {
    height: "30rem",
  },
}))

const Dashboard = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const { data: meData, isLoading: isLoadingMe } = useAuthControllerGetProfile()
  const { data, isLoading } = useDashboardControllerDashboard({
    query: {
      refetchInterval: 2000,
    },
  })

  const navigateTo = (url: string) => {
    navigate(url)
  }

  useEffect(() => {
    if (localStorage.getItem("welcome") !== "false") {
      setOpen(true)
    }
  }, [])

  if (!data || !meData || isLoading || isLoadingMe) return <DashboardSkeleton />

  const topVendorsAffected = {
    series: !data?.topAffectedVendors
      ? []
      : [
          {
            name: "Alerts",
            data: data?.topAffectedVendors.slice(0, 5).map((i: any) => i.alerts),
          },
          {
            name: "Gaps",
            data: data?.topAffectedVendors.slice(0, 5).map((i: any) => i.gaps),
          },
          {
            name: "Insights",
            data: data?.topAffectedVendors.slice(0, 5).map((i: any) => i.insights),
          },
        ],
    options: {
      colors: [theme.palette.severities.high, theme.palette.severities.medium],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          //   click: (event: any, chartContext: any, config: any) => {
          //     if (config.seriesIndex === -1 && config.dataPointIndex === -1) {
          //       return
          //     } else {
          //       navigate(
          //         `/alerts?=${config.config.xaxis.categories[config.dataPointIndex]}&risk-score=${
          //           config.config.series[config.seriesIndex].name
          //         }`,
          //       )
          //     }
          //   },
          //   xAxisLabelClick: (event: any, chartContext: any, config: any) => {
          //     if (config.labelIndex !== undefined) {
          //       navigate(`/alerts?entityID=${config.config.xaxis.categories[config.labelIndex]}`)
          //     }
          //   },
          //   dataPointMouseEnter: (event: any) => {
          //     event.target.style.cursor = "pointer"
          //   },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          borderRadius: 5,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "all",
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: "on",
        type: "category",
        categories: data?.topAffectedVendors.slice(0, 5).map((i: any) => i.name),
        labels: {
          show: false,
          style: {
            colors: [
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
            ],
          },
        },
      },
      yaxis: {
        labels: {
          minWidth: 100,
          maxWidth: 300,
          style: {
            colors: [
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
              theme.palette.text.primary,
            ],
          },
        },
      },
      lines: {
        show: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        theme: theme.palette.mode,
      },
      grid: {
        show: false,
      },
    },
  }

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => {
    localStorage.setItem("welcome", "false")
    setOpen(false)
  }

  return (
    <Grid container className={classes.overview} spacing={3}>
      {!open && (
        <Fab
          size="medium"
          sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          color="primary"
          onClick={handleClickOpen}
        >
          <TurnedInIcon />
        </Fab>
      )}
      <Grid item xs={12} md={12}>
        <RiskAlerts entitiesWidget={data.entitiesWidget} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Assets entitiesWidget={data.entitiesWidget} />
          </Grid>
          <Grid item xs={4}>
            <FindingsByType findingsWidget={data.findingsWidget} />
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ padding: "1rem", height: "15.5rem" }}>
              <Typography variant={"body1"} className={classes.paperTitle}>
                {"Top Vendors Affected"}
              </Typography>
              <ReactApexChart
                options={topVendorsAffected.options as ApexOptions}
                series={topVendorsAffected.series}
                type="bar"
                width="100%"
                height={"155"}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PaymentCycle paymentCycle={data.paymentCycles} paymentWidget={data.paymentWidget} />
          </Grid>
        </Grid>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Typography variant={"h5"} sx={{ fontWeight: 400, textAlign: "left" }}>*/}
      {/*    Coverage*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={12}>*/}
      {/*  <Grid container spacing={3}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Coverage />*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={4}>*/}
      {/*  <Grid container spacing={3}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <FindingsByType*/}
      {/*        findings={{ alerts: data?.topPaymentsAffected.length, gaps: data?.paymentsIndications.length }}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Assets company={data?.company} />*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={4}>*/}
      {/*  <Grid container direction="row">*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Paper sx={{ padding: "1rem" }}>*/}
      {/*        <Typography variant={"h5"} fontWeight={400}>*/}
      {/*          Gaps*/}
      {/*        </Typography>*/}
      {/*        <PaymentsIndications indications={data?.paymentsIndications || []} />*/}
      {/*      </Paper>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12}>*/}
      {/*  <Paper sx={{ padding: "1rem", marginBottom: "2rem" }}>*/}
      {/*    <Typography variant={"h5"} className={classes.paperTitle}>*/}
      {/*      {"Top Vendors Affected"}*/}
      {/*    </Typography>*/}
      {/*    <ReactApexChart*/}
      {/*      options={topVendorsAffected.options as ApexOptions}*/}
      {/*      series={topVendorsAffected.series}*/}
      {/*      type="bar"*/}
      {/*      width="100%"*/}
      {/*      height={400}*/}
      {/*    />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"} sx={{ fontSize: "2rem" }}>
          Welcome to Creednz
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign={"center"} fontSize={"1.3rem"}>
            What would you like to do today?
          </DialogContentText>
          <Grid container sx={{ marginTop: "2rem" }} spacing={3} justifyContent={"space-evenly"}>
            <Grid item xs={5} sx={{ backgroundColor: "#F1F5FB", padding: "2rem", borderRadius: "1rem" }}>
              <Typography variant={"h6"} sx={{ marginBottom: "2rem", textAlign: "center" }}>
                Bank account due diligence
              </Typography>
              <Stack direction={"column"} spacing={3} justifyContent={"space-between"}>
                <Card sx={{ minWidth: "auto" }}>
                  <CardContent>
                    <Typography sx={{ fontSize: "1rem" }} color="text.secondary" gutterBottom>
                      Getting a new vendor inspected
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant={"outlined"} onClick={navigateTo.bind(null, "/vendor")}>
                      Create new vendor
                    </Button>
                    <Button
                      size="small"
                      variant={"outlined"}
                      onClick={navigateTo.bind(null, "/vendor-evaluation")}
                      sx={{ textAlign: "right" }}
                    >
                      Send vendor evaluation invite
                    </Button>
                  </CardActions>
                </Card>
                <Card sx={{ minWidth: "auto" }}>
                  <CardContent>
                    <Typography sx={{ fontSize: "1rem" }} color="text.secondary" gutterBottom>
                      Making update to an existing vendor
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant={"outlined"} onClick={navigateTo.bind(null, "/vendors")}>
                      Vendor Update
                    </Button>
                    <Button size="small" variant={"outlined"} onClick={navigateTo.bind(null, "/emails")}>
                      Email requests
                    </Button>
                  </CardActions>
                </Card>
                {/*<Card sx={{ minWidth: "auto" }}>*/}
                {/*  <CardContent>*/}
                {/*    <Typography sx={{ fontSize: "1rem" }} color="text.secondary" gutterBottom>*/}
                {/*      Checking vendor's email update request*/}
                {/*    </Typography>*/}
                {/*  </CardContent>*/}
                {/*  <CardActions>*/}
                {/*    <Button size="small" variant={"outlined"} onClick={navigateTo.bind(null, "/emails")}>*/}
                {/*      Emails*/}
                {/*    </Button>*/}
                {/*  </CardActions>*/}
                {/*</Card>*/}
                {/*  <Stack direction={"column"} spacing={0}>*/}
                {/*    <Typography color="text.secondary" variant={"body1"} fontWeight={"600"}>*/}
                {/*      Forward to: acme-ap-inbox@creednzisr.onmicrosoft.com*/}
                {/*    </Typography>*/}
                {/*    <Typography color="text.secondary" variant={"body1"} marginTop={"0rem"}>*/}
                {/*      the vendor email update request*/}
                {/*    </Typography>*/}
                {/*  </Stack>*/}
              </Stack>
            </Grid>
            <Grid item xs={5} sx={{ backgroundColor: "#F1F5FB", padding: "2rem", borderRadius: "1rem" }}>
              <Typography variant={"h6"} sx={{ lineHeight: "2rem", marginBottom: "2rem", textAlign: "center" }}>
                Payment assurance protection
              </Typography>
              <Stack direction={"column"} spacing={3} justifyContent={"space-between"}>
                <Card sx={{ minWidth: "auto" }}>
                  <CardContent>
                    <Typography sx={{ fontSize: "1rem" }} color="text.secondary" gutterBottom>
                      Monitoring each payment file in real time
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant={"outlined"}>
                      Upload payment file
                    </Button>
                  </CardActions>
                </Card>
                <Card sx={{ minWidth: "auto" }}>
                  <CardContent>
                    <Typography sx={{ fontSize: "1rem" }} color="text.secondary" gutterBottom>
                      When things don't go as planned, how to respond
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant={"outlined"}>
                      Money recovery
                    </Button>
                  </CardActions>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default Dashboard
