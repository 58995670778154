import * as React from "react"
import { useState } from "react"
import { ITheme } from "theme/theme"
import { CompanyDto } from "../../apis/model"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { entitiesControllerRemoveByCompanyId, useEntitiesControllerRemoveByCompanyId } from "apis/endpoints/edgeService"

interface IProps {
  open: boolean
  company: CompanyDto | null
  closeDialog: () => void
}

const DeleteDialog = ({ open, company, closeDialog }: IProps): JSX.Element => {
  const theme: ITheme = useTheme()
  const t = theme.props
  const [deleting, setDeleting] = useState<boolean>(false)

  const deleteData = () => {
    setDeleting(true)
    if (company?.id) {
      deleteCompanyData.mutate({ id: company.id })
    }
  }

  const deleteCompanyData = useEntitiesControllerRemoveByCompanyId({
    mutation: {
      mutationFn: (company: { id: number }) => entitiesControllerRemoveByCompanyId(company.id),
      onSuccess: () => {
        setDeleting(false)
        closeDialog()
      },
      onError: (e): void => {
        setDeleting(false)
        console.log(e)
      },
    },
  })

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ padding: "1rem" }}
    >
      <DialogTitle id="alert-dialog-title">{t.General.deleteCompanyData}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant={"body1"} gutterBottom>
            {`Are you sure you want to delete ${company?.name} data?`}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={closeDialog}>
          {t.General.cancel}
        </Button>
        <LoadingButton size="medium" loading={deleting} variant={"contained"} color={"error"} onClick={deleteData}>
          {t.General.delete}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
