const creednzThemeDark: any = {
  palette: {
    mode: "dark",
    background: {
      default: "#15161A",
      paper: "#1E2329",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#C9CED6",
    },
    common: {
      black: "#17181A",
      white: "#FFFFFF",
    },
    primary: {
      light: "#447efd",
      main: "#2F75FD",
      dark: "#0c58ff",
    },
    secondary: {
      main: "#C9CED6",
    },
    severities: {
      high: "#DB6161",
      medium: "#F5B161",
      low: "#FFE870",
      info: "#A3ADBE",
      success: "#9ADB83",
    },
    // drawer: {
    //   default: "#3C4146",
    //   text: "#FFFFFF",
    //   border: "rgba(255, 255, 255, 0.12)",
    // },
    warning: {
      main: "#E56503",
    },
    error: {
      main: "#E13A44",
    },
    success: {
      main: "#81BA6C",
    },
  },
  general: {
    bodyHeight: "100vh",
  },
  overflowText: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  typography: {
    fontFamily: ["Lufga", "sans-serif"].join(","),
  },
}

creednzThemeDark.components = {
  ...creednzThemeDark.components,
  MuiDataGrid: {
    styleOverrides: {
      columnHeadersInner: {},
      columnHeaders: {
        backgroundColor: "#3C4146",
        minHeight: "40px !important",
        maxHeight: "40px !important",
        marginTop: "1rem",
      },
      columnHeaderTitle: {
        fontWeight: 400,
      },
      root: {
        border: "none",
      },
    },
  },
  MuiCssBaseline: {
    root: {
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: `1px solid slategrey`,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: "1rem",
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: "#fff",
        textTransform: "capitalize",
        height: "2rem",
        padding: "0 0.75rem",
        borderRadius: "20px",
        borderColor: "#2E75FD",
        "&.Mui-selected": {
          borderColor: "#2E75FD",
          backgroundColor: "#2A3B5B",
          fontWeight: 600,
        },
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        borderRadius: "1rem",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "capitalize",
        "&.Mui-selected": {
          color: creednzThemeDark.palette.common.white,
          fontWeight: 600,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        textTransform: "capitalize",
        borderRadius: "6.25rem",
        "&:hover": {
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "inherit",
        },
      },
      sizeMedium: {
        minWidth: "9rem",
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: 14,
        },
      },
      textSecondary: {
        "&:hover": {
          color: creednzThemeDark.palette.primary.main,
        },
      },
    },
  },
}

export default creednzThemeDark
