import dayjs from "dayjs"
import { CompanyDto } from "../apis/model"

const numAbbreviation: { [key: number]: string } = {
  0: "",
  1: "K",
  2: "M",
  3: "B",
  4: "T",
}

export const abbreviateNumber = (num: number, trigger: number = 1000): string => {
  if (!num || num === 0) {
    return "0"
  } else if (num < 0) {
    return "-" + abbreviateNumber(-1 * num)
  } else if (num < trigger) {
    return num.toString()
  }

  let newValue: number = num
  let suffixNum: number = 0
  while (newValue >= 1000) {
    newValue /= 1000
    suffixNum++
  }
  return `${parseFloat(newValue.toFixed(1))}${numAbbreviation[suffixNum]}`
}

export const formatDate = (date: string | Date, format: string = "DD/MM/YYYY HH:mm A"): string => {
  return dayjs(date).format(format)
}

export const statusCodes: Record<number, string> = {
  401: "Unauthorized",
  422: "Data is not valid",
  500: "Server Error",
}

export function getErrorString(statusCode: number): string {
  return statusCodes[statusCode] ?? "Unable to proceed"
}

export const getCompanyNames = (companies: CompanyDto[]): string => {
  return companies.map((company) => company.name).join(", ")
}
