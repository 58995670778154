/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type CreateRuleDtoType = typeof CreateRuleDtoType[keyof typeof CreateRuleDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateRuleDtoType = {
  BankAccountVerification: 'BankAccountVerification',
  Sanctions: 'Sanctions',
  DataValidation: 'DataValidation',
  Cyber: 'Cyber',
  Vulnerability: 'Vulnerability',
} as const;
