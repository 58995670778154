import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { NavigateFunction } from "react-router"
import { useTheme } from "@mui/material"

const useStyles = makeStyles()((theme: ITheme) => ({
  mainSidebar: {
    marginTop: "5rem",
    height: "calc(100vh - 5rem)",
    position: "fixed",
    top: 0,
    boxShadow: "rgb(0 0 0 / 15%) 1.15px 1.15px 1.6px",
  },
  sidebarElement: {
    color: theme.palette.text.primary,
    padding: "7px 1rem",
    textAlign: "left",
    cursor: "pointer",
  },
}))

interface IMainSidebarProps {
  width: number
  type?: any
  sidebarElements: any
}

function MainSidebar({ width, type, sidebarElements }: IMainSidebarProps) {
  const theme: ITheme = useTheme()
  const { classes } = useStyles()

  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const { category } = useParams()

  return (
    <div className={classes.mainSidebar} style={{ width: width }}>
      {sidebarElements.map((item: any) => {
        return (
          <div
            key={item}
            onClick={() => {
              navigate(`/${location.pathname.split("/")[1]}/${item.toLowerCase()}`)
            }}
            style={{
              color:
                category?.toLowerCase() === item.toLowerCase()
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
            }}
            className={classes.sidebarElement}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default MainSidebar
