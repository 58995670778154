import React from "react"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import { useCompanyControllerUsersByCompany } from "apis/endpoints/edgeService"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { NavigateFunction, useNavigate } from "react-router"
import TableTextCell from "common/TableTextCell"
import TablePageSkeleton from "common/TablePageSkeleton"
import { useTheme } from "@mui/material"
import { CreateUserDTORoleName } from "../../apis/model"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import { usePermission } from "../../providers/AuthorizedUserPermissionsProvider"

const useStyles = makeStyles()(() => ({
  users: {
    padding: "1rem",
  },
  table: {
    height: "80vh",
  },
  title: {
    fontWeight: 600,
  },
  paper: {
    padding: "1rem",
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))

const Users = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const { isRole } = usePermission()

  const { data, isLoading } = useCompanyControllerUsersByCompany()

  const navigateTo = (url: string) => navigate(url)

  const columns: any[] = [
    {
      field: "id",
      headerName: t.General.id,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "name",
      headerName: t.General.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "email",
      headerName: t.General.email,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "roleDto",
      headerName: t.General.role,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const roleValue = params.value.name
        return <TableTextCell content={t.Consts[roleValue as CreateUserDTORoleName]} />
      },
    },
    {
      renderCell: (params: GridRenderCellParams) =>
        !isRole(CreateUserDTORoleName.CustomerUser) &&
        !isRole(CreateUserDTORoleName.CreednzUser) && (
          <Box>
            <EditIcon
              fontSize={"small"}
              className={classes.actionIcon}
              onClick={navigateTo.bind(null, `${params.row.id}`)}
            />
            <DeleteIcon
              fontSize={"small"}
              className={classes.actionIcon}
              onClick={navigateTo.bind(null, `${params.row.id}`)}
            />
          </Box>
        ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton />

  return (
    <Grid container className={classes.users} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h6" className={classes.title}>
              {t.General.users}
            </Typography>
            {!isRole(CreateUserDTORoleName.CustomerUser) && !isRole(CreateUserDTORoleName.CreednzUser) && (
              <Button variant={"contained"} onClick={navigateTo.bind(null, "new")}>
                Create user
              </Button>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <DataGrid
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnSelector
              slots={{ toolbar: GridToolbar }}
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Users
