import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "../../makeStyles"
import EmptyDashboardImage from "images/empty-dahsboard.svg"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles()(() => ({
  paper: {
    width: "100%",
    height: "calc(100vh - 6rem)",
    backgroundColor: "transparent",
  },
  content: {
    marginTop: "1rem",
  },
}))

const EmptyPayments = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Grid container sx={{ padding: "1rem" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        className={classes.content}
      >
        <Stack direction={"column"}>
          <Typography variant={"h4"} sx={{ textAlign: "center", marginBottom: "2rem" }}>
            No payments detected
          </Typography>
          <img src={EmptyDashboardImage} alt={"empty-payments"} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default EmptyPayments
