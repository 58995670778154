import NetSuiteLogo from "images/Integration Logos/oracle-netsuite-logo.png"
import AcumaticaLogo from "images/Integration Logos/acumatica-logo.png"
import SageIntacctLogo from "images/Integration Logos/sage-intacct-logo.png"
import IntuitQuickBooksLogo from "images/Integration Logos/intuit-quickbooks_logo.png"
import AffiseLogo from "images/Integration Logos/affise-logo.png"
import MicrosoftDynamics from "images/Integration Logos/microsoft-dynamics.png"
import LinkTrustLogo from "images/Integration Logos/link-trust-logo.png"
import HasOffersLogo from "images/Integration Logos/has-offers-logo.png"
import HitpathLogo from "images/Integration Logos/hitpath-logo.png"
import PaladinLogo from "images/Integration Logos/paladin-logo.png"

export const eprIntegrationCompanies = [
  {
    name: "NetSuite",
    logo: NetSuiteLogo,
  },
  {
    name: "Acumatica",
    logo: AcumaticaLogo,
  },
  {
    name: "Sage Intacct",
    logo: SageIntacctLogo,
  },
  {
    name: "Intuit QuickBooks",
    logo: IntuitQuickBooksLogo,
  },
  {
    name: "Affise",
    logo: AffiseLogo,
  },
  {
    name: "Microsoft Dynamics",
    logo: MicrosoftDynamics,
  },
  {
    name: "Link Trust",
    logo: LinkTrustLogo,
  },
  {
    name: "Has Offers",
    logo: HasOffersLogo,
  },
  {
    name: "Hitpath",
    logo: HitpathLogo,
  },
  {
    name: "Paladin",
    logo: PaladinLogo,
  },
]
