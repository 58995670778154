import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { NavigateFunction, useNavigate } from "react-router"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import LoadingButton from "@mui/lab/LoadingButton"
import MenuItem from "@mui/material/MenuItem"
import {
  useVendorOnBoardingControllerAnalyzeAndSave,
  useVendorsControllerSave,
  useVendorsControllerSaveWithAnalyze,
  useVendorsControllerUpdateWithAnalyze,
  useVendorsControllerVendor,
  vendorOnBoardingControllerAnalyzeAndSave,
  vendorsControllerSave,
  vendorsControllerSaveWithAnalyze,
  vendorsControllerUpdateWithAnalyze,
} from "apis/endpoints/edgeService"
import {
  stringContainsOnlyNumber,
  stringContainsValidEmail,
  stringContainsValidPhone,
  stringContainsValidSwiftCode,
} from "../../utils/regax"
import { CreateVendorDto, UpdateVendorDto, VendorOnBoardingMeDto } from "apis/model"
import { COUNTRIES } from "./const"
import { useToast } from "../../providers/ToastContextProvider"
import { getErrorString } from "../../utils/utils"
import Upload from "../../common/Upload"
import { UploadType, VendorFormType } from "../../utils/types"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

const useStyles = makeStyles()(() => ({
  vendorsForm: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  formPaper: {
    padding: "1rem 1rem 2rem 1rem",
  },
  inputField: {
    marginBottom: "1rem",
  },
  save: {
    margin: "2rem 2rem 0 0",
  },
  paperHeader: {
    marginBottom: "1rem",
  },
  stack: {
    width: "70%",
  },
  title: {
    fontWeight: 400,
  },
}))

interface IProps {
  vendorDetails?: VendorOnBoardingMeDto
  onBoardingSuccess?: () => void
  vendorFormType: VendorFormType
}

const VendorForm = ({ vendorDetails, onBoardingSuccess, vendorFormType }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const vendorId: number = Number(params.id)
  const [isToAnalyze] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { addToast } = useToast()
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    internalId: "",
    name: "",
    primaryContact: "",
    email: "",
    phone: "",
    address: "",
    billingCity: "",
    billingZip: "",
    iban: "",
    vendorPaymentMethod: "",
    abaNumber: "",
    swift: "",
    primarySubsidiary: "",
    billingRegion: "",
    taxpayerID: "",
    billingCountry: "",
    currency: "",
    bankAccountName: "",
    bankAccountType: "",
    bankCode: "",
    bankAccountNumber: "",
    bankAddress: "",
    paypalAccount: "",
  })

  const [vendor, setVendor] = useState<CreateVendorDto>({
    internalId: 0,
    companyId: 0,
    name: vendorDetails ? vendorDetails.vendorName : "",
    primaryContact: vendorDetails ? vendorDetails.primaryContact : "",
    primarySubsidiary: "",
    email: vendorDetails ? vendorDetails.email : "",
    phone: "",
    address: "",
    billingCity: "",
    billingZip: "",
    iban: "",
    vendorPaymentMethod: "",
    abaNumber: "",
    swift: "",
    billingRegion: "",
    taxpayerID: "",
    billingCountry: "",
    currency: "",
    bankAccountName: "",
    bankAccountType: "",
    bankCode: "",
    bankAccountNumber: "",
    bankAddress: "",
    paypalAccount: "",
  })

  const { data } = useVendorsControllerVendor(vendorId, {
    query: {
      refetchOnMount: "always",
    },
  })

  useEffect(() => {
    if (data) {
      const { id, enrichedVendorDto, atRisk, ...updateVendor } = data
      updateVendor.bankCode = searchParams.get("routing") || updateVendor.bankCode
      setVendor(updateVendor)
    }
  }, [data, searchParams])

  const createVendor = useVendorsControllerSave({
    mutation: {
      mutationFn: (vendorInput) => {
        return vendorsControllerSave(vendorInput.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate("/vendors")
      },
    },
  })

  const createVendorWithAnalyze = useVendorsControllerSaveWithAnalyze({
    mutation: {
      mutationFn: (vendorInput) => {
        return vendorsControllerSaveWithAnalyze(vendorInput.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate("/vendors")
      },
    },
  })

  const create = useVendorOnBoardingControllerAnalyzeAndSave({
    mutation: {
      mutationFn: (vendor: { data: CreateVendorDto }) => {
        return vendorOnBoardingControllerAnalyzeAndSave(vendor.data)
      },
      onSuccess: () => {
        if (onBoardingSuccess) onBoardingSuccess()
      },
      onError: (error) => {
        setIsSaving(false)
        addToast("error", `Error: ${getErrorString(error.request.status)}`)
      },
    },
  })

  const update = useVendorsControllerUpdateWithAnalyze({
    mutation: {
      mutationFn: (vendor: { id: number; data: UpdateVendorDto }) => {
        return vendorsControllerUpdateWithAnalyze(vendor.id, vendor.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate("/vendors")
      },
      onError: (error) => {
        setIsSaving(false)
        addToast("error", `Error: ${getErrorString(error.request.status)}`)
      },
    },
  })

  const validateForm = () => {
    const {
      internalId,
      name,
      primaryContact,
      email,
      phone,
      address,
      billingCity,
      billingZip,
      iban,
      vendorPaymentMethod,
      abaNumber,
      swift,
      primarySubsidiary,
      billingRegion,
      taxpayerID,
      billingCountry,
      currency,
      bankAccountName,
      bankAccountType,
      bankCode,
      bankAccountNumber,
      bankAddress,
      paypalAccount,
    } = vendor
    let errors = { ...formErrors }
    errors.internalId = !internalId
      ? ""
      : !stringContainsOnlyNumber(internalId)
        ? t.Errors.fieldContainsOnlyNumbers
        : ""

    errors.name = !name
      ? t.Errors.fieldInNotDefined
      : name?.length === 0
        ? t.Errors.emptyInputError
        : name?.length > 400
          ? t.Errors.inputIsTooLongError
          : ""

    errors.primaryContact = !primaryContact ? "" : primaryContact?.length > 1000 ? t.Errors.inputIsTooLongError : ""

    errors.email = !email
      ? t.Errors.fieldInNotDefined
      : email?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidEmail(email)
          ? t.Errors.fieldForEmailOnly
          : ""

    errors.paypalAccount = !paypalAccount
      ? ""
      : paypalAccount?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidEmail(paypalAccount)
          ? t.Errors.fieldForEmailOnly
          : ""

    errors.phone = !phone
      ? ""
      : phone?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidPhone(phone)
          ? t.Errors.fieldInNotDefined
          : ""

    errors.address = !address ? "" : address?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.billingCity = !billingCity ? "" : billingCity?.length > 1000 ? t.Errors.inputIsTooLongError : ""

    errors.billingZip = !billingZip ? "" : String(billingZip)?.length > 1000 ? t.Errors.inputIsTooLongError : ""

    errors.iban = !iban ? "" : iban?.length > 1000 ? t.Errors.inputIsTooLongError : ""

    errors.vendorPaymentMethod = !vendorPaymentMethod
      ? ""
      : vendorPaymentMethod?.length > 100
        ? t.Errors.inputIsTooLongError
        : ""

    errors.abaNumber = !abaNumber
      ? ""
      : abaNumber?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsOnlyNumber(abaNumber)
          ? t.Errors.fieldContainsOnlyNumbers
          : ""

    errors.swift = !swift
      ? ""
      : swift?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidSwiftCode(swift)
          ? t.Errors.fieldContainsUnValidSwiftCode
          : ""

    errors.billingCountry = !billingCountry ? "" : billingCountry?.length > 100 ? t.Errors.inputIsTooLongError : ""

    errors.primarySubsidiary = !primarySubsidiary
      ? ""
      : String(primarySubsidiary).length > 499
        ? t.Errors.inputIsTooLongError
        : ""

    errors.billingRegion = !billingRegion ? "" : billingRegion?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.taxpayerID = !taxpayerID
      ? ""
      : taxpayerID?.length > 1000
        ? t.Errors.inputIsTooLongError
        : !stringContainsOnlyNumber(taxpayerID)
          ? t.Errors.fieldContainsOnlyNumbers
          : ""

    errors.currency = !currency ? "" : currency?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.bankAccountName = !bankAccountName ? "" : bankAccountName?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.bankAccountType = !bankAccountType ? "" : bankAccountType?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.bankCode = !bankCode ? "" : bankCode?.length > 400 ? t.Errors.inputIsTooLongError : ""

    errors.bankAccountNumber = !bankAccountNumber
      ? ""
      : bankAccountNumber?.length > 400
        ? t.Errors.inputIsTooLongError
        : ""

    errors.bankAddress = !bankAddress ? "" : bankAddress?.length > 400 ? t.Errors.inputIsTooLongError : ""

    setFormErrors(errors)
    return !Object.values(errors).some((error: string) => !!error)
  }

  const save = () => {
    if (validateForm()) {
      setIsSaving(true)
      vendor.internalId = Number(vendor.internalId)
      if (vendorFormType === VendorFormType.UpdateVendor) {
        update.mutate({ id: vendorId, data: vendor })
      } else if (vendorFormType === VendorFormType.VendorOnBoarding) {
        create.mutate({ data: vendor })
      } else {
        if (isToAnalyze) {
          createVendorWithAnalyze.mutate({ data: vendor })
        } else {
          createVendor.mutate({ data: vendor })
        }
      }
    }
  }

  const goToVendors = () => navigate("/vendors")

  const onInternalIdChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, internalId: Number(e.target.value) })

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => setVendor({ ...vendor, name: e.target.value })

  const onPrimaryContactChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, primaryContact: e.target.value })

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, email: e.target.value })

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, phone: e.target.value })

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, address: e.target.value })

  const onBillingCityChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, billingCity: e.target.value })

  const onBillingZipChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendor({ ...vendor, billingZip: e.target.value })

  const onAbaNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, abaNumber: e.target.value })
  }
  const onIbanChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, iban: e.target.value })
  }
  const onVendorPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, vendorPaymentMethod: e.target.value })
  }
  const onSwiftCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, swift: e.target.value })
  }

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, currency: e.target.value })
  }
  const onPrimarySubsidiaryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // @ts-ignore
    setVendor({ ...vendor, primarySubsidiary: e.target.value })
  }
  const onBillingRegionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, billingRegion: e.target.value })
  }
  const onTaxPayerIDChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, taxpayerID: e.target.value })
  }

  const onBankAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, bankAccountName: e.target.value })
  }

  const onBankCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, bankCode: e.target.value })
  }

  const onBankAccountTypeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, bankAccountType: e.target.value })
  }

  const onBankAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, bankAccountNumber: e.target.value })
  }

  const onBankAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, bankAddress: e.target.value })
  }

  const onBillingCountryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, billingCountry: e.target.value })
  }

  const onPayPalAccountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVendor({ ...vendor, paypalAccount: e.target.value })
  }

  return (
    <Grid container className={classes.vendorsForm} spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant={"h6"} data-testid={"title"} className={classes.title}>
            {vendorFormType === VendorFormType.VendorOnBoarding
              ? "Vendor Evaluation Form"
              : vendor.name
                ? `Edit ${vendor.name}`
                : t.General.manualInspection}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Grid className={classes.paperHeader}>
            <Typography variant="h6" gutterBottom fontWeight={400}>
              {t.General.primaryInformation}
            </Typography>
          </Grid>
          <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.name}
                  onChange={onNameChange}
                  label={t.General.name}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.name}
                  error={formErrors.name !== ""}
                  required
                  data-testid={"vendor-name-text-field"}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label={t.General.phone}
                  value={vendor.phone}
                  onChange={onPhoneChange}
                  className={classes.inputField}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.phone}
                  placeholder={t.PlaceHolders.phone}
                  error={formErrors.phone !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  label={t.General.email}
                  value={vendor.email}
                  onChange={onEmailChange}
                  placeholder={t.PlaceHolders.email}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.email}
                  error={formErrors.email !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.primarySubsidiary}
                  onChange={onPrimarySubsidiaryChange}
                  inputProps={{ maxLength: 250 }}
                  label={t.General.primarySubsidiary}
                  helperText={formErrors.primarySubsidiary}
                  error={formErrors.primarySubsidiary !== ""}
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.taxpayerID}
                  label={t.General.taxpayerID}
                  className={classes.inputField}
                  onChange={onTaxPayerIDChange}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.taxpayerID}
                  error={formErrors.taxpayerID !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label={t.General.internalId}
                  value={vendor.internalId}
                  onChange={onInternalIdChange}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.internalId}
                  error={formErrors.internalId !== ""}
                  fullWidth
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label={t.General.primaryContact}
                  value={vendor.primaryContact}
                  onChange={onPrimaryContactChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.primaryContact}
                  error={formErrors.primaryContact !== ""}
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Grid className={classes.paperHeader}>
            <Typography variant="h6" gutterBottom fontWeight={400}>
              {t.General.bankDetails}
            </Typography>
          </Grid>
          <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.abaNumber}
                  label={t.General.abaNumber}
                  onChange={onAbaNumberChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.abaNumber}
                  error={formErrors.abaNumber !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.swift}
                  label={t.General.swiftCode}
                  onChange={onSwiftCodeChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.swift}
                  error={formErrors.swift !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.currency}
                  label={t.General.currency}
                  onChange={onCurrencyChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.currency}
                  error={formErrors.currency !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.iban}
                  label={t.General.iban}
                  onChange={onIbanChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.iban}
                  error={formErrors.iban !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.paypalAccount}
                  label={t.General.paypalAccount}
                  onChange={onPayPalAccountChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.paypalAccount}
                  error={formErrors.paypalAccount !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.vendorPaymentMethod}
                  label={t.General.vendorPaymentMethod}
                  onChange={onVendorPaymentMethodChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.vendorPaymentMethod}
                  error={formErrors.vendorPaymentMethod !== ""}
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.bankAccountName}
                  label={t.General.bankAccountName}
                  onChange={onBankAccountNameChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.bankAccountName}
                  error={formErrors.bankAccountName !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.bankAccountType}
                  label={t.General.bankAccountType}
                  onChange={onBankAccountTypeChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.bankAccountType}
                  error={formErrors.bankAccountType !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.bankCode}
                  label={"Routing number"}
                  onChange={onBankCodeChange}
                  inputProps={{ maxLength: 400 }}
                  helperText={formErrors.bankCode}
                  error={formErrors.bankCode !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.bankAccountNumber}
                  label={t.General.bankAccountNumber}
                  onChange={onBankAccountNumberChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.bankAccountNumber}
                  error={formErrors.bankAccountNumber !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.bankAddress}
                  label={t.General.bankAddress}
                  onChange={onBankAddressChange}
                  inputProps={{ maxLength: 100 }}
                  helperText={formErrors.bankAddress}
                  error={formErrors.bankAddress !== ""}
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Grid className={classes.paperHeader}>
            <Typography variant="h6" gutterBottom fontWeight={400}>
              {t.General.billingAddress}
            </Typography>
          </Grid>

          <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={t.General.address}
                  value={vendor.address}
                  onChange={onAddressChange}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.address}
                  error={formErrors.address !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.billingRegion}
                  label={t.General.billingRegion}
                  onChange={onBillingRegionChange}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.billingRegion}
                  error={formErrors.billingRegion !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.billingCity}
                  label={t.General.billingCity}
                  onChange={onBillingCityChange}
                  className={classes.inputField}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.billingCity}
                  error={formErrors.billingCity !== ""}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={vendor.billingZip}
                  label={t.General.billingZipCode}
                  onChange={onBillingZipChange}
                  className={classes.inputField}
                  inputProps={{ maxLength: 250 }}
                  helperText={formErrors.billingZip}
                  error={formErrors.billingZip !== ""}
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              <Stack spacing={2} className={classes.stack}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <TextField
                  size={"small"}
                  select
                  label={t.General.billingCountry}
                  helperText={formErrors.billingCountry}
                  error={formErrors.billingCountry !== ""}
                  onChange={onBillingCountryChange}
                  defaultValue="pick a country"
                  value={vendor.billingCountry}
                >
                  {COUNTRIES.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {!vendorId && (
        <Grid item xs={12}>
          <Paper className={classes.formPaper}>
            <Grid className={classes.paperHeader}>
              <Typography variant="h6" gutterBottom fontWeight={400}>
                {"Vendor Security Questionnaire"}
              </Typography>
            </Grid>
            <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="stretch">
              <Grid item md={6} xs={12} lg={6}>
                <Stack spacing={2} className={classes.stack}>
                  <Typography variant={"body1"}>
                    Will your tool store part of process customer data? If yes, How is data protected in transit? How is
                    data at rest protected on servers and backup media?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />

                  <Typography variant={"body1"}>
                    If data is processed/stored is it stored on-premsis or in the cloud?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />

                  <Typography variant={"body1"}>
                    Do you have ISO 27001 and or SOC2 Certification? If not, is there a formal information security
                    program in place?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />

                  <Typography variant={"body1"}>
                    Are penetration tests performed by a qualified third-party vendor? If so, how often are they
                    performed and when was the last test performed?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />
                </Stack>
              </Grid>
              <Grid item md={6} xs={12} lg={6}>
                <Stack spacing={2} className={classes.stack}>
                  <Typography variant={"body1"}>
                    Is there a formal process to review user access? If yes, can you provide high level details (example
                    when formal reviews take place, completion percentage, etc) Has your company implemented the
                    principle of least privilege and multi-factor authentication for remote access?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />
                  <Typography variant={"body1"}>
                    Are employees and contractors required to attend security training?
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />

                  <Typography variant={"body1"}>Is there a formal incident management program in place?</Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />

                  <Typography variant={"body1"}>
                    Does your company have technical prevention measures in place? (example WAF/firewall, endpoint
                    detection, SIEM, etc)
                  </Typography>
                  <TextField size="small" variant="outlined" inputProps={{ maxLength: 1000 }} multiline rows={3} />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Typography variant="h6" gutterBottom fontWeight={400}>
            {t.General.additionalInformation}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please add the following documentation to this form:
          </Typography>
          <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <Typography variant="body1" gutterBottom fontWeight={400}>
              Must attach:
            </Typography>
            <ListItem sx={{ display: "list-item" }}>
              Banking details or scan of the check (official documents issued by a bank to verify the details)
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Incorporation certification</ListItem>
          </List>
          <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
            <Typography variant="body1" gutterBottom fontWeight={400}>
              Recommended:
            </Typography>
            <ListItem sx={{ display: "list-item" }}>Compliance (ie. SOC2 or ISO 27001 Certification)</ListItem>
          </List>
          <Upload type={UploadType.VendorQuestionnaire} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.formPaper}>
          <Stack>
            {/*{vendorFormType === VendorFormType.NewVendor && (*/}
            {/*  <FormControlLabel*/}
            {/*    label={t.General.isNeedToAnalyze}*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        checked={isToAnalyze}*/}
            {/*        onChange={() => {*/}
            {/*          setIsToAnalyze((isToAnalyze) => !isToAnalyze)*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*)}*/}
            <Stack direction={"row"}>
              <LoadingButton
                size="medium"
                color="primary"
                variant="contained"
                loading={isSaving}
                className={classes.save}
                onClick={save}
                data-testid={"save-button"}
              >
                {t.General.save}
              </LoadingButton>
              {vendorFormType === VendorFormType.NewVendor && (
                <Button variant={"outlined"} onClick={goToVendors} className={classes.save}>
                  {t.General.cancel}
                </Button>
              )}
            </Stack>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default VendorForm
