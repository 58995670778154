import React from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"

const useStyles = makeStyles()(() => ({
  padding: {
    padding: "1rem",
  },
}))

const IndicatorSkeleton = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Grid container className={classes.padding} spacing={1} data-testid={"loading"}>
      <Grid item xs={12}>
        <Paper className={classes.padding}>
          <Skeleton variant={"rectangular"} height={"21rem"} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant={"rectangular"} height={"40rem"} />
      </Grid>
    </Grid>
  )
}

export default IndicatorSkeleton
