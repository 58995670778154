import React, { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { ITheme } from "theme/theme"
import { companyControllerCreateCompany, useCompanyControllerCreateCompany } from "../../apis/endpoints/edgeService"
import { CompanyDto, CreateCompanyDto } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  userForm: {
    padding: "1rem",
  },
  form: {
    height: "40vh",
    padding: "1rem 0",
  },
  save: {
    margin: "0 2rem 0 0",
  },
}))

interface IProps {
  nextStep: () => void
  company: CreateCompanyDto
  setCompany: (company: CreateCompanyDto) => void
}

const CompanyForm = ({ nextStep, company, setCompany }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setCompany({ ...company, name: e.target.value })

  const onDomainChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setCompany({ ...company, domain: e.target.value })

  const saveCompany = () => {
    setIsSaving(true)
    createCompany.mutate({ data: company })
  }

  const createCompany = useCompanyControllerCreateCompany<CompanyDto>({
    mutation: {
      mutationFn: (companyInput: { data: CreateCompanyDto }) => {
        return companyControllerCreateCompany(companyInput.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        nextStep ? nextStep() : navigate(-1)
      },
    },
  })

  return (
    <Grid container className={classes.form}>
      <Grid item md={8} xs={12}>
        <Stack spacing={3}>
          <TextField
            size="small"
            variant="outlined"
            value={company.name}
            label={t.General.name}
            onChange={onNameChange}
            inputProps={{ maxLength: 100 }}
            required
          />
          <TextField
            size="small"
            variant="outlined"
            value={company.domain}
            type={"email"}
            label={t.General.domain}
            onChange={onDomainChange}
            inputProps={{ maxLength: 100 }}
            required
          />
        </Stack>
      </Grid>
      <Grid item md={8} xs={12} justifyContent={"flex-start"}>
        <LoadingButton
          size="medium"
          color="primary"
          variant="contained"
          loading={isSaving}
          className={classes.save}
          onClick={saveCompany}
        >
          {t.General.save}
        </LoadingButton>
        <Button variant={"outlined"} onClick={nextStep} className={classes.save}>
          {t.General.cancel}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CompanyForm
