import React from "react"
import Grid from "@mui/material/Grid"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import { TextField, useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import {
  organizationControllerCreateOrganization,
  useOrganizationControllerCreateOrganization,
} from "../../apis/endpoints/edgeService"
import LoadingButton from "@mui/lab/LoadingButton"
import { CreateOrganizationDto, OrganizationDto } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  gridMargin: {
    margin: "0 0 1rem 0",
  },
  text: {
    width: "70%",
    marginBottom: "1.5rem",
  },
}))

interface IProps {
  handleNext: () => void
  saveOrganization: (organization: OrganizationDto) => void
  organizationDto?: OrganizationDto
}

const OrganizationForm = ({ handleNext, saveOrganization, organizationDto }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  console.log(organizationDto, "00000000000")
  const [organization, setOrganization] = React.useState<CreateOrganizationDto | OrganizationDto>(
    organizationDto
      ? organizationDto
      : {
          name: "",
        }
  )

  const handleOrganizationNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setOrganization({ name: e.target.value })

  const save = () => {
    setIsSaving(true)
    createOrganization.mutate({ data: organization })
  }

  const createOrganization = useOrganizationControllerCreateOrganization<CreateOrganizationDto>({
    mutation: {
      mutationFn: (create: { data: CreateOrganizationDto }) => {
        return organizationControllerCreateOrganization(create.data)
      },
      onSuccess: (org: OrganizationDto) => {
        saveOrganization(org)
        setIsSaving(false)
        handleNext()
      },
    },
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.gridMargin}>
        <Stack>
          <Typography className={classes.gridMargin}>{t.General.pleaseEnterOrganizationName}</Typography>
          <TextField
            onChange={handleOrganizationNameChange}
            className={classes.text}
            value={organization.name}
            size="small"
            label={t.General.organizationName}
            variant="outlined"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} className={classes.gridMargin}>
        <LoadingButton loading={isSaving} variant={"contained"} onClick={save}>
          {t.General.save}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default OrganizationForm
