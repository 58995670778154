import React from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import TableTextCell from "common/TableTextCell"
import TablePageSkeleton from "common/TablePageSkeleton"
import { useTheme } from "@mui/material"
import { slotProps } from "theme/data-grid"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import {
  ruleControllerUpdateRulesConfiguration,
  useRuleControllerRulesConfiguration,
  useRuleControllerUpdateRulesConfiguration,
} from "../../apis/endpoints/edgeService"
import { UpdateRuleConfigurationDto } from "../../apis/model"
import { useToast } from "../../providers/ToastContextProvider"

const useStyles = makeStyles()(() => ({
  rules: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    padding: "1rem",
    height: "80vh",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
}))
const RulesConfiguration = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const { addToast } = useToast()
  const t = theme.props

  const { data, isLoading, refetch } = useRuleControllerRulesConfiguration({
    query: {
      refetchOnMount: "always",
    },
  })

  const updateRule = useRuleControllerUpdateRulesConfiguration({
    mutation: {
      mutationFn: (toUpdate: { id: number; data: UpdateRuleConfigurationDto }) => {
        return ruleControllerUpdateRulesConfiguration(toUpdate.id, toUpdate.data)
      },
      onSuccess: () => {
        addToast("success", "Saved Successfully")
        refetch()
      },
      onError: (e: any) => {
        addToast("error", "Failed to save")
      },
    },
  })

  const toggleActive = (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
    updateRule.mutate({
      id,
      data: {
        active: e.target.checked,
      },
    })
  }

  const getName = (shortName: string): string => {
    const result = shortName.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const columns: any[] = [
    {
      field: "id",
      headerName: t.General.id,
    },
    {
      field: "shortName",
      headerName: "Rule name",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={getName(params.value)} testId={params.value} />
      ),
    },
    {
      field: "active",
      headerName: t.General.active,
      renderCell: (params: GridRenderCellParams) => (
        <Switch defaultChecked checked={params.value} onChange={toggleActive.bind(this, params.row.id)} />
      ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton />

  return (
    <Grid container className={classes.rules} spacing={1} data-testid={"rules"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={"h6"} data-testid={"title"} className={classes.title}>
              {data.length.toLocaleString()} {t.General.rules}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.table}>
          <DataGridPro
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={data}
            columns={columns}
            disableRowSelectionOnClick
            slotProps={slotProps}
            initialState={{
              columns: {
                ...columns,
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RulesConfiguration
