export interface Localization {
  props: {
    General: {
      admin: string
      learnMore: string
      output: string
      bankCountry: string
      bankZipCode: string
      enrichment: string
      bankName: string
      dateCreated: string
      payeeName: string
      payeeBankAccountNumber: string
      payerBankAccountNumber: string
      payerName: string
      objective: string
      payeeInternalId: string
      amount: string
      currencyCode: string
      transactionId: string
      paymentId: string
      active: string
      activeOffWillPreventTheRulesFromRunning: string
      selectCompanies: string
      youMustSelectAtLeastOneCompany: string
      assets: string
      dataProcessing: string
      registrationCode: string
      affectedVendor: string
      affectedPayment: string
      viewLess: string
      viewMore: string
      incidentsAndFindings: string
      dashboard: string
      uploadLedger: string
      manualInspection: string
      allEmails: string
      changeLog: string
      transactionAnalysis: string
      emailInspection: string
      creednzNetwork: string
      payments: string
      payment: string
      ledgers: string
      emails: string
      vendorsBreakdown: string
      rulesBreakdown: string
      paymentsBreakdown: string
      emailsBreakdown: string
      totalVendors: string
      totalIndicators: string
      totalPayments: string
      totalFindings: string
      totalEmails: string
      submit: string
      creednz: string
      alerts: string
      indications: string
      finding: string
      indication: string
      rules: string
      rulesConfiguration: string
      vendors: string
      logout: string
      settings: string
      exitSettings: string
      adminSettings: string
      uploadMasterVendorFile: string
      uploadPaymentFile: string
      uploadBankTemplate: string
      uploadBankTransactions: string
      uploadAFile: string
      dragAndDropYourFileHereOr: string
      someOfAreRulesCheckingYourVendors: string
      welcomeToCreednz: string
      saveAndContinue: string
      continue: string
      pleaseEnterOrganizationName: string
      organizationName: string
      welcomeSubtext: string
      contactSupport: string
      upload: string
      uploadType: string
      uploadMVF: string
      uploadMvf: string
      close: string
      indicators: string
      changeMode: string
      ruleId: string
      risksBySeverity: string
      highLevelInsights: string
      gaps: string
      title: string
      description: string
      presentedMessage: string
      validationMethod: string
      status: string
      riskStatus: string
      viewRulesDefinition: string
      responsibleDepartment: string
      remediation: string
      newRule: string
      newVendor: string
      riskScore: string
      risk: string
      save: string
      bankAccountValidation: string
      augmentedCyberWatch: string
      masterVendorInspection: string
      beneficiaryInformationValidation: string
      topVendorsAffected: string
      topVendorsAtRisk: string
      topRules: string
      examinedEntityType: string
      type: string
      examinedEntity: string
      shortName: string
      createNewRule: string
      cancel: string
      delete: string
      deleteCompanyData: string
      deleteOrganizationData: string
      category: string
      subCategory: string
      name: string
      email: string
      role: string
      comments: string
      companies: string
      createCompany: string
      company: string
      createOrganization: string
      goToUsersPage: string
      here: string
      newCompany: string
      deleteAllCompaniesData: string
      organization: string
      organizations: string
      domain: string
      users: string
      versionLog: string
      newUser: string
      createUser: string
      vendorChangeLog: string
      actions: string
      id: string
      fileName: string
      analyzedAt: string
      numberOfIndications: string
      uploadedBy: string
      createNewVendor: string
      legalName: string
      primarySubsidiary: string
      entityBankSubsidiary: string
      entityBankFormat: string
      isNeedToAnalyze: string
      iban: string
      createdAt: string
      updatedAt: string
      swiftCode: string
      abaNumber: string
      primaryContactName: string
      primaryContact: string
      phone: string
      address: string
      state: string
      billingZipCode: string
      billingCity: string
      internalId: string
      bankAccountNumber: string
      editedBy: string
      lastEdited: string
      dateChanged: string
      lastPaymentDate: string
      paymentMethod: string
      paymentTerms: string
      countryCode: string
      currency: string
      maxYearlyAmount: string
      contactInformation: string
      businessId: string
      primaryInformation: string
      billingAddress: string
      billingContactDetails: string
      bankDetails: string
      additionalInformation: string
      whtCertification: string
      taxpayerID: string
      billingRegion: string
      billingCountry: string
      bankDetailsContinue: string
      vendorPaymentMethod: string
      bankAccountType: string
      bankAccountName: string
      bankCode: string
      bankAddress: string
      paypalAccount: string
      lastModified: string
      inviteVendor: string
      sendVendorEvaluationRequest: string
      invite: string
      vendorName: string
      pleaseCompleteTheFormToInvite: string
      vendorId: string
      token: string
      ifYouHaveAnyQuestion: string
      sender: string
      dateReceived: string
      hasAttachments: string
      dateFetched: string
      keywords: string
      subject: string
      emailBody: string
      noContent: string
      yes: string
      no: string
    }
    Errors: {
      youMustSelectAtLeastOneCompany: string
      maxFileSizeIs10MB: string
      emptyInputError: string
      inputError: string
      inputIsTooLongError: string
      fieldInNotDefined: string
      fieldContainsOnlyNumbers: string
      fieldForPhoneOnly: string
      fieldForEmailOnly: string
      fieldContainsUnValidSwiftCode: string
      fieldForOnlyWebsite: string
      illegalCharactersError: string
    }
    PlaceHolders: {
      swiftCode: string
      phone: string
      email: string
    }
    Consts: {
      CreednzAdmin: string
      CreednzUser: string
      CustomerAdmin: string
      CustomerUser: string
    }
  }
}

export const EN: Localization = {
  props: {
    General: {
      admin: "Admin",
      learnMore: "Learn more",
      output: "Output",
      bankCountry: "Bank country",
      bankZipCode: "Bank zip code",
      enrichment: "Enrichment",
      bankName: "Bank name",
      dateCreated: "Date created",
      payeeName: "Payee name",
      payeeBankAccountNumber: "Payee bank account number",
      payerBankAccountNumber: "Payer bank account number",
      payerName: "Payer name",
      objective: "Objective",
      payeeInternalId: "Payee internal id",
      amount: "Amount",
      currencyCode: "Currency code",
      transactionId: "Transaction Id",
      paymentId: "Payment Id",
      active: "Active",
      activeOffWillPreventTheRulesFromRunning: "Active off will prevent the rules from running.",
      selectCompanies: "Select companies",
      youMustSelectAtLeastOneCompany: "You must select at least one company",
      assets: "Assets",
      dataProcessing: "Data processing",
      registrationCode: "Registration code",
      affectedVendor: "Affected vendor",
      affectedPayment: "Affected payment",
      viewLess: "View less",
      viewMore: "View more",
      incidentsAndFindings: "Incidents & Findings",
      dashboard: "Dashboard",
      uploadType: "Upload type",
      uploadMVF: "Upload MVF",
      uploadLedger: "Upload Ledger",
      manualInspection: "Manual inspection",
      allEmails: "All Emails",
      changeLog: "Change Log",
      rules: "Rules",
      rulesConfiguration: "Rules Configuration",
      transactionAnalysis: "Transaction analysis",
      emailInspection: "Email Inspection",
      creednzNetwork: "Creednz Network",
      emails: "Emails",
      payments: "Payments",
      payment: "Payment",
      ledgers: "Ledgers",
      vendorsBreakdown: "Vendors Breakdown",
      rulesBreakdown: "Rules Breakdown",
      paymentsBreakdown: "Payments breakdown",
      emailsBreakdown: "Email Breakdown",
      totalVendors: "Total Vendors",
      totalIndicators: "Total Incidents",
      totalPayments: "Total payments",
      totalFindings: "Total Findings",
      totalEmails: "Total Emails",
      submit: "Submit",
      creednz: "Creednz",
      alerts: "Alerts",
      indications: "Findings & Incidents",
      finding: "Finding",
      indication: "indication",
      vendors: "Vendors",
      logout: "Logout",
      settings: "Settings",
      exitSettings: "Exit Settings",
      adminSettings: "Admin Settings",
      uploadMasterVendorFile: "Upload Master Vendor File",
      uploadPaymentFile: "Upload Payment File",
      uploadBankTemplate: "Upload Bank Template",
      uploadBankTransactions: "Upload Bank Transactions",
      uploadAFile: "Upload a file",
      dragAndDropYourFileHereOr: "Drag and drop your file here or",
      someOfAreRulesCheckingYourVendors:
        "Some of are Rules checking your Vendors and Bank Account. In order for us to understand more and have better validation about your vendors and bank accounts, Please send us as many details as you can",
      welcomeToCreednz: "Welcome to creednz",
      welcomeSubtext: "Please upload master vendor file.",
      saveAndContinue: "Save & Continue",
      continue: "Continue",
      pleaseEnterOrganizationName: "Please enter organization name",
      organizationName: "Organization name",
      contactSupport: "For any questions or help please contact are support team at",
      upload: "Upload",
      uploadMvf: "Upload master vendor file",
      close: "Close",
      indicators: "Indicators",
      changeMode: "Change mode",
      ruleId: "Rule id",
      risksBySeverity: "Risks by severity",
      highLevelInsights: "High-level insights",
      gaps: "Gaps",
      title: "Title",
      description: "Description",
      presentedMessage: "Presented message",
      validationMethod: "Validation method",
      status: "Status",
      riskStatus: "Risk Status",
      viewRulesDefinition: "View rules definition",
      responsibleDepartment: "Responsible department",
      remediation: "Remediation",
      newRule: "New Rule",
      newVendor: "New Vendor",
      riskScore: "Risk score",
      risk: "Risk",
      save: "Save",
      bankAccountValidation: "Bank account validation",
      augmentedCyberWatch: "Augmented cyber watch",
      masterVendorInspection: "Master vendor inspection",
      beneficiaryInformationValidation: "Beneficiary information validation",
      topVendorsAffected: "Top vendors affected",
      topVendorsAtRisk: "Top vendors at risk",
      topRules: "Top rules",
      examinedEntityType: "Examined entity type",
      type: "Type",
      examinedEntity: "Examined entity",
      shortName: "Short Name",
      createNewRule: "Create new rule",
      cancel: "Cancel",
      delete: "Delete",
      deleteCompanyData: "Delete company data",
      deleteOrganizationData: "Delete organization data",
      category: "Category",
      name: "Name",
      email: "Email",
      role: "Role",
      comments: "Comments",
      subCategory: "Sub category",
      companies: "Companies",
      createCompany: "Create company",
      company: "company",
      createOrganization: "Create organization",
      goToUsersPage: "In order to open the first company user go to organization user page",
      here: "Here",
      newCompany: "New company",
      deleteAllCompaniesData: "Delete all companies data",
      organization: "Organization",
      organizations: "Organizations",
      domain: "Domain",
      users: "Users",
      versionLog: "Version Log",
      newUser: "New user",
      createUser: "Create user",
      vendorChangeLog: "Vendor change log",
      actions: "Actions",
      id: "ID",
      fileName: "File name",
      analyzedAt: "Analyzed at",
      numberOfIndications: "Number of indications",
      uploadedBy: "Uploaded by",
      createNewVendor: "Create new Vendor",
      legalName: "Legal name",
      primarySubsidiary: "Primary Subsidiary",
      entityBankSubsidiary: "Entity bank subsidiary",
      entityBankFormat: "Entity bank format",
      isNeedToAnalyze: "Analyze after save",
      iban: "Iban",
      vendorPaymentMethod: "Vendor payment method",
      createdAt: "Created at",
      updatedAt: "Updated at",
      swiftCode: "Swift code",
      abaNumber: "Aba number",
      primaryContactName: "Primary contact name",
      primaryContact: "Primary contact",
      phone: "Phone",
      address: "Address",
      state: "State",
      billingZipCode: "Billing zip code",
      billingCity: "Billing city",
      internalId: "Internal Id",
      bankAccountNumber: "Bank account number",
      editedBy: "Edited by",
      lastEdited: "Last edited",
      dateChanged: "Date changed",
      lastPaymentDate: "Last payment date",
      paymentMethod: "Payment method",
      paymentTerms: "Payment terms",
      countryCode: "Country code",
      currency: "Currency",
      maxYearlyAmount: "Max yearly amount",
      contactInformation: "Contact information",
      businessId: "Business id",
      primaryInformation: "Primary information",
      billingAddress: "Billing address",
      billingContactDetails: "Billing contact details",
      bankDetails: "Bank details",
      additionalInformation: "Additional information",
      whtCertification: "WHT Certification",
      taxpayerID: "Tax payer ID",
      billingRegion: "Billing region/state",
      billingCountry: "Billing country",
      bankDetailsContinue: "Bank details continue",
      bankAccountType: "Bank account type",
      bankAccountName: "Bank account name",
      bankCode: "Bank code",
      bankAddress: "Bank address",
      paypalAccount: "Paypal account",
      lastModified: "Last modified",
      inviteVendor: "Invite vendor",
      sendVendorEvaluationRequest: "Send vendor evaluation request",
      invite: "Invite",
      vendorName: "Vendor name",
      pleaseCompleteTheFormToInvite:
        "Vendor will receive an email with a link to insert their details. Please note that the link will expire within 7 day",
      vendorId: "Vendor Id",
      token: "Token",
      ifYouHaveAnyQuestion: "If you have any questions please don't hesitate to contact us at",
      sender: "Sender",
      dateReceived: "Received On",
      hasAttachments: "Has Attachments",
      dateFetched: "Date Fetched",
      keywords: "Keywords",
      subject: "Subject",
      emailBody: "Email Body",
      noContent: "No content",
      yes: "Yes",
      no: "No",
    },
    Errors: {
      youMustSelectAtLeastOneCompany: "You must select at least one company",
      maxFileSizeIs10MB: "Max file size is 10 mb",
      emptyInputError: "Input can not be empty.",
      inputError: "Input in not valid",
      inputIsTooLongError: "Input is too long.",
      fieldInNotDefined: "Field is not defined.",
      fieldContainsOnlyNumbers: "Input contains only numbers",
      fieldForPhoneOnly: "Input need to be a valid phone number",
      fieldForEmailOnly: "Input need to be a valid email ",
      fieldContainsUnValidSwiftCode: "Input need to be a valid swift code",
      fieldForOnlyWebsite: "Input need to be a valid website",
      illegalCharactersError:
        "Please select a title that doesn't contain the following characters ~!@#$%^&*(){}\"<>?:;+=-",
    },
    PlaceHolders: {
      swiftCode: "fill valid swift code",
      phone: "fill valid phone (only numbers nine digits)",
      email: "fill valid email",
    },
    Consts: {
      CreednzAdmin: "Creednz Admin",
      CreednzUser: "Creednz User",
      CustomerAdmin: "Customer Admin",
      CustomerUser: "Customer User",
    },
  },
}
