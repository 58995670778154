/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type CreateCompanyDtoPaymentFormat = typeof CreateCompanyDtoPaymentFormat[keyof typeof CreateCompanyDtoPaymentFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCompanyDtoPaymentFormat = {
  CHASE: 'CHASE',
  YOTPO: 'YOTPO',
} as const;
