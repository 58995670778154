import Typography from "@mui/material/Typography"
import { makeStyles } from "../makeStyles"
import { ITheme } from "../theme/theme"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import React from "react"

const useStyles = makeStyles()((theme: ITheme) => ({
  icon: {
    textAlign: "center",
    width: "14rem",
    height: "14rem",
    marginBottom: "1rem",
  },
  wrapper: {
    textAlign: "center",
    height: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  contentWrapper: {
    marginBottom: "6%",
    width: "50%",
  },
}))

export const UnauthorizedPage = (): JSX.Element => {
  const { classes } = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.contentWrapper}>
        <RemoveCircleOutlineIcon className={classes.icon} />
        <Typography variant={"h6"}>
          You do not have permission to access this page for any questions please contact{" "}
          <Link href="mailto:support@creednz.com">support@creednz.com</Link>.
        </Typography>
      </Box>
    </Box>
  )
}
