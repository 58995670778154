import React, { useState } from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import { DataGridPro, DataGridProProps, GridRowId, GridToolbar } from "@mui/x-data-grid-pro"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import TableTextCell from "common/TableTextCell"
import { formatDate } from "utils/utils"
import TablePageSkeleton from "common/TablePageSkeleton"
import { NavigateFunction, useNavigate } from "react-router"
import { dataGridSx, slotProps } from "theme/data-grid"
import { useLocation, useSearchParams } from "react-router-dom"
import ExpandIndicators from "./ExpandIndicators"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import { GridEventListener, GridRenderCellParams, GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium"
import { useFindingsControllerFindings } from "../../apis/endpoints/edgeService"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import Tab from "@mui/material/Tab"
import TabPanel from "@mui/lab/TabPanel"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"
import { FindingDto, FindingDtoType } from "../../apis/model"
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft"
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter"
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"

const useStyles = makeStyles()((theme: ITheme) => ({
  indicators: {
    padding: "0rem 1rem",
  },
  paper: {
    padding: "1rem",
  },
  title: {
    fontWeight: 400,
  },
  table: {
    height: "73vh",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}))

const Indicators = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [alignment, setAlignment] = React.useState<string | null>("Payment")

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment)
  }

  const apiRef = useGridApiRef()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const handleClose = () => setAnchorEl(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const getSearchParams = () => {
    const result = []
    if (searchParams.get("indicator") !== null) {
      result.push({ id: 1, field: "title", value: searchParams.get("indicator"), operator: "equals" })
    }
    if (searchParams.get("risk-analyzer-category") !== null) {
      result.push({
        id: 2,
        field: "riskAnalyzerCategory",
        value: searchParams.get("risk-analyzer-category"),
        operator: "equals",
      })
    }
    if (searchParams.get("risk-score") !== null) {
      result.push({ id: 3, field: "riskScore", value: searchParams.get("risk-score"), operator: "equals" })
    }

    if (searchParams.get("risk-analyzer-sub-category") !== null) {
      result.push({
        id: 4,
        field: "riskAnalyzerSubCategory",
        value: searchParams.get("risk-analyzer-sub-category"),
        operator: "equals",
      })
    }

    if (searchParams.get("entity-type") !== null) {
      result.push({ id: 5, field: "examinedEntityType", value: searchParams.get("entity-type"), operator: "equals" })
    }
    return result
  }

  const [filterParams] = useState(getSearchParams)

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]): void => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const navigate: NavigateFunction = useNavigate()

  const { data, isLoading } = useFindingsControllerFindings()

  const handleRowEvent: GridEventListener<"rowClick"> = (params: GridRowParams<any>) => navigate(`${params.row.id}`)

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandIndicators row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const navigateTo = (url: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    navigate(url)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/${newValue}`)
  }

  const ExpandableCell = ({ description }: { description: string }) => {
    const [expanded, setExpanded] = React.useState(false)

    return (
      <Box component="div" key={description}>
        {expanded ? description : `${description.slice(0, 0)}`}
        {description.length > 0 && (
          <Link
            sx={{ paddingLeft: expanded ? "0.5rem" : 0, fontSize: "0.5", cursor: "pointer" }}
            type="button"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </Box>
    )
  }

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "title",
      headerName: "Alert",
      flex: 1.2,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography gutterBottom fontWeight={400} variant={"body1"} data-testid={params.value}>
            {params.value}
          </Typography>
          <ExpandableCell description={params.row.description} />
        </Box>
      ),
    },
    {
      field: "examinedEntity",
      headerName: t.General.examinedEntity,
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography
            onClick={navigateTo.bind(
              null,
              `/${params.row.examinedEntity.toLowerCase()}s/${params.row.examinedEntityId}`,
            )}
            className={classes.link}
            variant={"subtitle2"}
          >
            {params.row.examinedEntity}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      headerName: t.General.actions,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            color={"error"}
            size={"small"}
            variant={"contained"}
            sx={{ padding: "0 1rem" }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Action
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            marginThreshold={20}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack sx={{ width: "17rem" }}>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Hold</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Inspect</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Upload to bank</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Ignore</Typography>
            </Stack>
          </Popover>
          <Button size={"small"} variant={"outlined"} onClick={navigateTo.bind(null, `/indicators/${params?.row.id}`)}>
            Details
          </Button>
        </Stack>
      ),
    },
  ]

  if (!data || isLoading) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.indicators} spacing={1} data-testid={"indicators"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <TabContext value={location.pathname.split("/").pop() || ""}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="Alerts" value="alerts" />
                <Tab label="Gaps" value="gaps" />
                <Tab label="Insights" value="insights" />
              </TabList>
            </Box>
            <TabPanel value="alerts" sx={{ paddingRight: "0rem", paddingLeft: "0rem" }}>
              <Stack direction={"row"} spacing={3}>
                <Typography variant="h6" data-testid={"title"} className={classes.title}>
                  {`${data.filter((alert: FindingDto) => alert.type === FindingDtoType.Alert).length} ${t.General.alerts}`}
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  size={"small"}
                >
                  <ToggleButton value="Payment" aria-label="Payment">
                    Payment
                  </ToggleButton>
                  <ToggleButton value="Vendor" aria-label="Vendor">
                    Vendor
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Grid container className={classes.table}>
                <DataGridPro
                  apiRef={apiRef}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  rows={data.filter(
                    (alert: FindingDto) => alert.type === FindingDtoType.Alert && alert.examinedEntity === alignment,
                  )}
                  onRowClick={handleRowEvent}
                  columns={columns}
                  pagination
                  slotProps={slotProps}
                  sx={dataGridSx}
                  getEstimatedRowHeight={() => 200}
                  getRowHeight={() => "auto"}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={getDetailPanelHeight}
                  detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                  pageSizeOptions={[25, 50, 100]}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: filterParams,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                />
              </Grid>
            </TabPanel>
            <TabPanel value="gaps" sx={{ paddingRight: "0rem", paddingLeft: 0 }}>
              <Stack direction={"row"} spacing={3}>
                <Typography variant="h6" data-testid={"title"} className={classes.title}>
                  {`${data.filter((alert: FindingDto) => alert.type === FindingDtoType.Gap).length} ${t.General.gaps}`}
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  size={"small"}
                >
                  <ToggleButton value="Payment" aria-label="Payment">
                    Payment
                  </ToggleButton>
                  <ToggleButton value="Vendor" aria-label="Vendor">
                    Vendor
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Grid container className={classes.table}>
                <DataGridPro
                  apiRef={apiRef}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  rows={data.filter(
                    (alert: FindingDto) => alert.type === FindingDtoType.Gap && alert.examinedEntity === alignment,
                  )}
                  onRowClick={handleRowEvent}
                  columns={columns}
                  pagination
                  slotProps={slotProps}
                  sx={dataGridSx}
                  getEstimatedRowHeight={() => 200}
                  getRowHeight={() => "auto"}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={getDetailPanelHeight}
                  detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                  pageSizeOptions={[25, 50, 100]}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: filterParams,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                />
              </Grid>
            </TabPanel>
            <TabPanel value="insights" sx={{ paddingRight: "0rem", paddingLeft: 0 }}>
              <Stack direction={"row"} spacing={3}>
                <Typography variant="h6" data-testid={"title"} className={classes.title}>
                  {`${data.filter((alert: FindingDto) => alert.type === FindingDtoType.Insight).length} Insights`}
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  size={"small"}
                >
                  <ToggleButton value="Payment" aria-label="Payment">
                    Payment
                  </ToggleButton>
                  <ToggleButton value="Vendor" aria-label="Vendor">
                    Vendor
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Grid container className={classes.table}>
                <DataGridPro
                  apiRef={apiRef}
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  rows={data.filter(
                    (alert: FindingDto) => alert.type === FindingDtoType.Insight && alert.examinedEntity === alignment,
                  )}
                  onRowClick={handleRowEvent}
                  columns={columns}
                  pagination
                  slotProps={slotProps}
                  sx={dataGridSx}
                  getEstimatedRowHeight={() => 200}
                  getRowHeight={() => "auto"}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={getDetailPanelHeight}
                  detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: filterParams,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                />
              </Grid>
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Indicators
