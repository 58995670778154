import React from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import Box from "@mui/material/Box"
import { EmailDto } from "../../apis/model"
import { formatDate } from "../../utils/utils"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import FieldWithValue from "../../common/FieldWithValue"
import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles()((theme: ITheme) => ({
  expand: {
    padding: "1.5rem",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0.75rem",
  },
  emailDetailsCard: {
    padding: "1rem",
    background: "none",
  },
  emailDetailsSection: {
    marginBottom: theme.spacing(2),
  },
  emailSubject: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: "1rem",
    borderRadius: "4px",
    overflow: "auto",
    maxHeight: "300px",
  },
  emailBody: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: "1rem",
    borderRadius: "4px",
    overflow: "auto",
    maxHeight: "300px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  detailItem: {
    marginBottom: theme.spacing(1),
  },
}))

interface IProps {
  row: EmailDto
}

const ExpandEmailDetails = ({ row }: IProps) => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props.General

  return (
    <Paper className={classes.emailDetailsCard} data-testid="email-details">
      {/*<CardContent>*/}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/*<Alert*/}
          {/*  severity="warning"*/}
          {/*  action={*/}
          {/*    <Button color="primary" variant={"contained"}>*/}
          {/*      Send for evaluation*/}
          {/*    </Button>*/}
          {/*  }*/}
          {/*>*/}
          {/*  Do you wish to update vendor bank account details?*/}
          {/*</Alert>*/}
          <FieldWithValue name={t.sender} value={row.sender} />
          <FieldWithValue name={t.dateReceived} value={formatDate(row.receivedDateTime)} />
          <FieldWithValue name={t.hasAttachments} value={row.hasAttachments ? t.yes : t.no} />
          <FieldWithValue name={t.dateFetched} value={formatDate(row.dateFetched)} />
          <Box className={classes.detailItem}>
            <Typography variant="body2" component="span">
              {t.keywords}:{" "}
            </Typography>
            {Array.isArray(row.keywords) &&
              row.keywords.map((keyword, index) => (
                <Chip key={index} label={keyword} className={classes.chip} size="small" />
              ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      {/*<Divider style={{ margin: "1rem 0 2rem 0" }} />*/}
      {/*<Box className={classes.emailDetailsSection}>*/}
      {/*  <Typography variant="subtitle1" gutterBottom>*/}
      {/*    {t.subject}*/}
      {/*  </Typography>*/}
      {/*  <Paper className={classes.emailSubject}>*/}
      {/*    <Typography variant="body2">{row.subject}</Typography>*/}
      {/*  </Paper>*/}
      {/*</Box>*/}
      {/*<Box className={classes.emailDetailsSection}>*/}
      {/*  <Typography variant="subtitle1" gutterBottom>*/}
      {/*    {t.emailBody}*/}
      {/*  </Typography>*/}
      {/*  <Paper className={classes.emailBody}>*/}
      {/*    <div dangerouslySetInnerHTML={{ __html: row.body || t.noContent }} />*/}
      {/*  </Paper>*/}
      {/*</Box>*/}
      {/*</CardContent>*/}
    </Paper>
  )
}

export default ExpandEmailDetails
