import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import { makeStyles } from "makeStyles"
import { Button, Card, CardActions, CardContent, useTheme } from "@mui/material"
import { NavigateFunction, useNavigate } from "react-router"
import { ITheme } from "../../theme/theme"

const useStyles = makeStyles()(() => ({
  admin: {
    padding: "1rem",
  },
  title: {
    fontWeight: 400,
    padding: "1rem",
  },
  learnMore: {
    marginTop: "10rem",
  },
  card: {
    height: "20rem",
    cursor: "pointer",
  },
}))

const Admin = () => {
  const { classes } = useStyles()
  const navigate: NavigateFunction = useNavigate()
  const theme: ITheme = useTheme()
  const t = theme.props

  const navigateTo = (url: string) => navigate(url)

  return (
    <Grid container className={classes.admin} spacing={3} justifyContent={"space-around"}>
      <Grid item xs={12} md={12}>
        <Paper>
          <Typography variant="h6" className={classes.title} data-testid={"title"}>
            {t.General.admin}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          className={classes.card}
          data-testid={"organizations-card"}
          onClick={navigateTo.bind(null, "organizations")}
        >
          <CardContent>
            <Typography variant="h5" component="div" data-testid={"organizations"}>
              {t.General.organizations}
            </Typography>
            <Typography color="text.secondary">list all organizations</Typography>
          </CardContent>
          <CardActions>
            <Button size="small" className={classes.learnMore}>
              {t.General.learnMore}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card} data-testid={"rules-card"} onClick={navigateTo.bind(null, "rules")}>
          <CardContent>
            <Typography variant="h5" component="div" data-testid={"rules"}>
              {t.General.rules}
            </Typography>
            <Typography color="text.secondary">list all rules</Typography>
          </CardContent>
          <CardActions>
            <Button size="small" className={classes.learnMore}>
              {t.General.learnMore}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Admin
