import * as React from "react"
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import ChecklistRtlOutlinedIcon from "@mui/icons-material/ChecklistRtlOutlined"
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined"
import { useTheme } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Popper from "@mui/material/Popper"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { makeStyles } from "makeStyles"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { ITheme } from "theme/theme"
import { CreateUserDTORoleName, MeDto } from "../../apis/model"
import { SideMenuItem } from "../../utils/types"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import Grid from "@mui/material/Grid"
import SelectCompany from "./SelectCompany"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Paper from "@mui/material/Paper"
import SettingsIcon from "@mui/icons-material/Settings"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import LogoutIcon from "@mui/icons-material/Logout"
import MenuItem from "@mui/material/MenuItem"
import { useAuth0 } from "@auth0/auth0-react"
import { usePermission } from "../../providers/AuthorizedUserPermissionsProvider"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined"
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import IconButton from "@mui/material/IconButton"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import MenuIcon from "@mui/icons-material/Menu"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import MoneyRecoveryDrawer from "./MoneyRecoveryDrawer"

const useStyles = makeStyles()((theme: ITheme) => ({
  drawerHeader: {
    minHeight: "5rem",
    "@media (min-width: 0px) and (orientation: landscape)": {
      minHeight: "5rem",
    },
    "@media (min-width: 600px)": {
      minHeight: "5rem",
    },
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
    "& .MuiDrawer-paper": {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
      },
    },
  },
  drawerOpen: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
  },
  list: {
    paddingBottom: "0",
    paddingTop: "0",
  },
  selectCompanyList: {
    paddingBottom: "0",
    paddingTop: "0",
    marginTop: "0rem",
    marginBottom: "2rem",
  },
  selectedCompany: {
    lineHeight: "2rem",
  },
  avatar: {
    width: "2rem",
    height: "2rem",
    fontSize: "0.875rem",
    margin: "0 1rem 0 0",
  },
  companyAvatar: {
    width: "2rem",
    height: "2rem",
    fontSize: "0.75rem",
    margin: "0 0.5rem 0 5px",
    backgroundColor: theme.palette.primary.main,
  },
  children: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - 218px)`,
    },
  },
  logo: {
    fontSize: "1.8rem",
    width: "160px",
    marginLeft: "1rem",
    cursor: "pointer",
    fontFamily: "Montserrat Alternates, sans-serif",
    color: theme.palette.primary.main,
  },
  listItem: {
    height: "3rem",
    display: "block",
  },
  chevronIcon: {
    color: theme.palette.common.black,
  },
  selectCompany: {
    height: "100%",
  },
  selectedCompanyButton: {
    minHeight: "2rem",
    paddingLeft: "0.5rem",
  },
  popper: {
    zIndex: 100,
    height: "auto",
    marginLeft: "1.7rem !important",
  },
  userDataMenu: {
    marginLeft: "2rem",
  },
  userDataMenuPaper: {
    padding: "1.3rem 0",
    width: "14rem",
    marginBottom: "0.3rem",
  },
  menuListItemText: {
    padding: "0",
    paddingRight: 8,
  },
  divider: {
    width: "80%",
    margin: "1rem auto",
  },
  listItemButton: {
    verticalAlign: "top",
    minHeight: 20,
  },
  listItemIcon: {
    minWidth: 0,
  },
  profileList: {
    position: "absolute",
    left: 5,
    bottom: 0,
    width: "100%",
  },
  listItemTextTypo: {
    marginLeft: "0.875rem",
    verticalAlign: "middle",
  },
}))

const drawerWidth = 218

interface IProps {
  me: MeDto
  open: boolean
  refetch: () => void
  toggleSideBar: (value: boolean) => void
  children: JSX.Element
}

const SideDrawer = ({ me, open, refetch, children, toggleSideBar }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const { isRole } = usePermission()
  const theme: ITheme = useTheme()
  const t = theme.props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openSelectCompany, setOpenSelectCompany] = useState<boolean>(false)
  const [userDataMenuAnchorEl, setUserDataMenuAnchorEl] = useState(null)
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false)
  const [openMoneyRecovery, setOpenMoneyRecovery] = React.useState(false)

  useEffect(() => {
    if (!open) {
      setOpenSelectCompany(false)
      setAnchorEl(null)
    }
  }, [open])

  const openMoneyRecoveryDialog = () => setOpenMoneyRecovery(true)
  const closeMoneyRecoveryDialog = () => setOpenMoneyRecovery(false)

  const goToLogout = () => {
    localStorage.removeItem("welcome")
    logout({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } })
  }

  const handleSelectCompanyClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpenSelectCompany((previousOpen) => !previousOpen)
  }

  const handleSelectCompanyClose = () => {
    setAnchorEl(null)
    setOpenSelectCompany(false)
  }

  const handleDrawerClose = () => toggleSideBar(false)
  const handleDrawerOpen = () => toggleSideBar(true)

  const navigateTo = (url: string): void => navigate(url)

  const handleUserMenuClick = (event: any) => {
    setUserDataMenuAnchorEl(event.currentTarget)
    setUserMenuOpen((previousOpen) => !previousOpen)
  }

  const handleUserMenuClose = () => {
    setUserDataMenuAnchorEl(null)
    setUserMenuOpen(false)
  }

  const dashboard: SideMenuItem[] = [
    {
      title: t.General.dashboard,
      icon: <DashboardOutlinedIcon data-testid={"dashboard"} />,
      url: "/",
    },
    {
      title: "Alerts",
      icon: <PrivacyTipOutlinedIcon data-testid={"indicators"} />,
      url: "/alerts",
    },
    {
      title: t.General.payments,
      icon: <PaidOutlinedIcon />,
      url: "/payments",
    },
    {
      title: t.General.ledgers,
      icon: <CollectionsBookmarkOutlinedIcon />,
      url: "/ledgers",
    },
  ]

  const vendors: SideMenuItem[] = [
    {
      title: t.General.vendors,
      icon: <Groups2OutlinedIcon />,
      url: "/vendors",
    },
    {
      title: "Vendor Evaluation",
      icon: <PersonAddAltOutlinedIcon />,
      url: "/vendor-evaluation",
    },
  ]

  const settings: SideMenuItem[] = [
    {
      title: t.General.emails,
      icon: <MarkEmailReadOutlinedIcon />,
      url: "/emails",
    },
    {
      title: t.General.changeLog,
      icon: <ChecklistRtlOutlinedIcon />,
      url: "/vendor-change-log",
    },
    {
      title: t.General.rulesConfiguration,
      icon: <QueryStatsOutlinedIcon />,
      url: "/rules-configuration",
    },
  ]

  const listItem = (items: any[], withDivider: boolean = true): JSX.Element => (
    <React.Fragment>
      {withDivider && <Divider className={classes.divider} />}
      <List className={classes.list}>
        {items.map((item: SideMenuItem) => (
          <ListItem key={item.title} disablePadding className={classes.listItem}>
            <ListItemButton
              onClick={navigateTo.bind(null, item.url)}
              className={classes.listItemButton}
              sx={{ justifyContent: open ? "initial" : "center" }}
            >
              <ListItemIcon
                className={classes.listItemIcon}
                sx={{ color: item.url === location.pathname ? theme.palette.primary.main : theme.palette.text.primary }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={classes.listItemTextTypo} sx={{ opacity: open ? 1 : 0 }}>
                    {item.title}
                  </Typography>
                }
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )

  const profile = (): JSX.Element => (
    <List className={classes.profileList}>
      <ListItem disablePadding>
        <ListItemButton
          onClick={handleUserMenuClick}
          className={classes.selectedCompanyButton}
          sx={{ justifyContent: open ? "initial" : "center" }}
        >
          <ListItemText
            disableTypography
            primary={
              <Stack direction={"row"}>
                <Avatar alt={me.user.name} className={classes.avatar} src={me.user.picture} variant="rounded" />
                {open && (
                  <Typography variant={"body1"} margin={"auto 0"}>
                    {me.user.name}
                  </Typography>
                )}
                {userMenuOpen && (
                  <ClickAwayListener onClickAway={handleUserMenuClose}>
                    <Popper
                      placement="right"
                      anchorEl={userDataMenuAnchorEl}
                      open={userMenuOpen}
                      className={classes.userDataMenu}
                    >
                      <Paper className={classes.userDataMenuPaper}>
                        <React.Fragment>
                          {!isRole(CreateUserDTORoleName.CustomerUser) && (
                            <MenuItem onClick={navigateTo.bind(null, "/settings/integrations")}>
                              <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                              </ListItemIcon>
                              <Typography variant="subtitle1" className={classes.menuListItemText}>
                                {t.General.settings}
                              </Typography>
                            </MenuItem>
                          )}
                          {isRole(CreateUserDTORoleName.CreednzAdmin) && (
                            <MenuItem onClick={navigateTo.bind(null, "/admin")}>
                              <ListItemIcon>
                                <ManageAccountsIcon fontSize="small" />
                              </ListItemIcon>
                              <Typography
                                variant="subtitle1"
                                className={classes.menuListItemText}
                                data-testid={"settings"}
                              >
                                {t.General.adminSettings}
                              </Typography>
                            </MenuItem>
                          )}
                        </React.Fragment>
                        <MenuItem onClick={goToLogout}>
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="subtitle1" className={classes.menuListItemText}>
                            {t.General.logout}
                          </Typography>
                        </MenuItem>
                      </Paper>
                    </Popper>
                  </ClickAwayListener>
                )}
              </Stack>
            }
          />
        </ListItemButton>
      </ListItem>
    </List>
  )

  return (
    <React.Fragment>
      {!location.pathname.includes("admin") && (
        <Drawer
          variant="permanent"
          className={open ? classes.drawerOpen : classes.drawer}
          data-testid={"side-drawer"}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          <Stack direction={"row"} sx={{ padding: "1.1rem 0" }}>
            {open && (
              <Typography variant={"h5"} className={classes.logo} onClick={navigateTo.bind(null, "/")}>
                {t.General.creednz}
              </Typography>
            )}
            <IconButton color="inherit" sx={{ margin: "0 auto" }}>
              {open ? (
                <MenuOpenIcon onClick={handleDrawerClose} fontSize={"medium"} />
              ) : (
                <MenuIcon onClick={handleDrawerOpen} fontSize={"medium"} />
              )}
            </IconButton>
          </Stack>
          <List className={classes.selectCompanyList} data-testid={"select-company"}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleSelectCompanyClick}
                className={classes.selectedCompanyButton}
                sx={{
                  justifyContent: open ? "initial" : "center",
                  backgroundColor: theme.palette.background.paper,
                  margin: open ? "0 1rem 0 0.4rem" : 0,
                  borderRadius: open ? "1rem" : 0,
                }}
              >
                <ListItemText
                  primary={
                    <Stack direction={"row"}>
                      <Avatar className={classes.companyAvatar} variant="rounded">
                        {me.company.name.substring(0, 3)}
                      </Avatar>
                      {open && (
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography variant={"caption"} className={classes.selectedCompany}>
                              {me.company.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <UnfoldMoreIcon className={classes.selectCompany} />
                          </Grid>
                        </Grid>
                      )}
                      {openSelectCompany && (
                        <ClickAwayListener onClickAway={handleSelectCompanyClose}>
                          <Popper
                            className={classes.popper}
                            id={"select-company"}
                            open={openSelectCompany}
                            anchorEl={anchorEl}
                            placement="right-start"
                            disablePortal={false}
                            modifiers={[
                              {
                                name: "preventOverflow",
                                enabled: true,
                              },
                            ]}
                          >
                            <SelectCompany selectedCompany={me.company} refetch={refetch} />
                          </Popper>
                        </ClickAwayListener>
                      )}
                    </Stack>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          {listItem(dashboard, false)}
          {listItem(vendors)}
          {listItem(settings)}
          {open && (
            <Paper
              onClick={openMoneyRecoveryDialog}
              sx={{
                cursor: "pointer",
                margin: "2rem 1rem 1rem 0.5rem",
                padding: "1rem 0.5rem",
              }}
            >
              <Stack direction={"column"} spacing={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CurrencyExchangeIcon fontSize={"small"} />
                  <Typography variant="body2">Money Recovery</Typography>
                </Stack>
                {/*<Typography color="text.secondary" variant={"caption"}>*/}
                {/*  Request reclaiming lost funds*/}
                {/*</Typography>*/}
              </Stack>
            </Paper>
          )}

          {profile()}
        </Drawer>
      )}
      <MoneyRecoveryDrawer openImport={openMoneyRecovery} close={closeMoneyRecoveryDialog} />

      <Box component="main" className={classes.children}>
        <Box component={"div"} className={classes.drawerHeader} />
        {children}
      </Box>
    </React.Fragment>
  )
}

export default SideDrawer
