import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { makeStyles } from "../../makeStyles"
import { ITheme } from "../../theme/theme"
import Link from "@mui/material/Link"
import { useTheme } from "@mui/material"
import { VendorOnBoardingMeDto } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  icon: {
    textAlign: "center",
    width: "5rem",
    height: "5rem",
    marginBottom: "1rem",
  },
  wrapper: {
    textAlign: "center",
    height: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  contentWrapper: {
    marginBottom: "6%",
    width: "50%",
  },
  subText: {
    marginTop: "1rem",
  },
}))

interface IProps {
  vendorDetails?: VendorOnBoardingMeDto
}

export const ThanksPage = ({ vendorDetails }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.contentWrapper}>
        <Typography variant={"h3"}>Thank you!</Typography>
        <Typography className={classes.subText} variant={"h6"}>
          Your vendor evaluation form has been submitted
        </Typography>
        <Typography className={classes.subText} variant={"body1"}>
          {t.General.ifYouHaveAnyQuestion} <Link href="mailto:support@creednz.com">support@creednz.com</Link>.
        </Typography>
      </Box>
    </Box>
  )
}
