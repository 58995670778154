import React from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import TableTextCell from "common/TableTextCell"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { formatDate } from "utils/utils"
import { useMvfFileControllerMvfFiles } from "apis/endpoints/edgeService"
import TablePageSkeleton from "common/TablePageSkeleton"
import { slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

const useStyles = makeStyles()(() => ({
  vendorChangeLog: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  title: {
    fontWeight: 400,
  },
  table: {
    height: "76vh",
    width: "100%",
  },
}))
const VendorFileChangeLog = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const { data, isLoading } = useMvfFileControllerMvfFiles()

  const columns: any[] = [
    { field: "id", headerName: "ID" },
    {
      field: "createdAt",
      headerName: t.General.analyzedAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      field: "fileName",
      headerName: t.General.fileName,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "vendors",
      headerName: t.General.vendors,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "numberOfIndications",
      headerName: t.General.numberOfIndications,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "uploadedBy",
      headerName: t.General.uploadedBy,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.vendorChangeLog} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.title}>
            {t.General.vendorChangeLog}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <DataGrid
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              columns={columns}
              rows={data}
              loading={isLoading}
              slotProps={slotProps}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default VendorFileChangeLog
