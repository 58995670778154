import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { makeStyles } from "../../makeStyles"
import { ITheme } from "../../theme/theme"
import { useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import InviteVendorDialog from "./InviteVendorDialog"
import TableTextCell from "../../common/TableTextCell"
import { DataGridPro, DataGridProProps, GridRowId, GridToolbar } from "@mui/x-data-grid-pro"
import { slotProps } from "../../theme/data-grid"
import { useVendorOnBoardingControllerVendorsOnBoarding } from "../../apis/endpoints/edgeService"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import { useNavigate } from "react-router-dom"
import { NavigateFunction } from "react-router"
import TablePageSkeleton from "common/TablePageSkeleton"
import { VendorOnBoardingDtoStatus } from "../../apis/model"
import ExpandInvite from "./ExpandInvite"

const useStyles = makeStyles()(() => ({
  wrapper: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  title: {
    fontWeight: 400,
  },
  table: {
    padding: "1rem",
    height: "83vh",
  },
}))

const InviteVendor = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const [openOnBoarding, setOpenOnBoarding] = useState<boolean>(false)
  const navigateTo = (url: string) => navigate(url)

  const { data, isLoading, refetch } = useVendorOnBoardingControllerVendorsOnBoarding()
  const closeDialog = async (isToRefetch: boolean): Promise<void> => {
    if (isToRefetch) refetch()
    setOpenOnBoarding(false)
  }

  const inviteVendor = (): void => setOpenOnBoarding(true)

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const getPendingStage = (id: number): string => {
    const options = ["Invite Sent"]
    return "Invite Sent"
  }

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandInvite row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "vendorName",
      headerName: t.General.name,
      flex: 2,
      renderCell: (params: GridRenderCellParams) =>
        params.row.status === VendorOnBoardingDtoStatus.Success ? (
          <Link type="button" component="button" onClick={navigateTo.bind(null, `/vendors/${params.row.vendorDto.id}`)}>
            <TableTextCell content={params.value} />
          </Link>
        ) : (
          <TableTextCell content={params.value} />
        ),
    },
    {
      field: "primaryContact",
      headerName: t.General.primaryContact,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "email",
      headerName: t.General.email,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => <TableTextCell testId={params.value} content={params.value} />,
    },
    {
      field: "token",
      headerName: "Assessment Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.row.status === "Pending" ? getPendingStage(params.row.id) : "At Risk"} />
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Risk Rating",
    //   flex: 1,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <TableTextCell
    //       content={params.value === "Pending" ? "Invite Sent" : params.value === "Success" ? "At Risk" : "Not At Risk"}
    //     />
    //   ),
    // },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.wrapper} spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" data-testid={"title"}>
            <Button variant={"contained"} onClick={inviteVendor} data-testid={"invite-vendor-button"}>
              {"New Vendor Evaluation"}
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.table}>
          <DataGridPro
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={data}
            slotProps={slotProps}
            columns={columns}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Paper>
      </Grid>
      <InviteVendorDialog open={openOnBoarding} closeDialog={closeDialog} />
    </Grid>
  )
}

export default InviteVendor
