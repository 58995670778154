import React from "react"
import Tooltip from "@mui/material/Tooltip"
import { makeStyles } from "makeStyles"
import Box from "@mui/material/Box"
import { ITheme } from "../theme/theme"
import { useTheme } from "@mui/material"

interface IProps {
  content: string
  style?: any
  testId?: string
}

const useStyles = makeStyles()((theme: ITheme) => ({
  cell: {
    display: "block",
    maxWidth: "20vw",
    overflow: "hidden",
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90vw",
      whiteSpace: "normal",
      WebkitLineClamp: 5,
    },
  },
}))

const TableTextCell = ({ content, style, testId }: IProps) => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()

  return (
    <Tooltip title={content} enterDelay={1000} placement="bottom-start">
      <Box
        component="div"
        key={content}
        className={classes.cell}
        style={style}
        sx={{ color: content === "N/A" ? theme.palette.secondary.main : theme.palette.text.primary }}
        data-testid={testId || "text-cell"}
      >
        {content}
      </Box>
    </Tooltip>
  )
}
export default React.memo(TableTextCell)
