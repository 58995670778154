import React from "react"
import { CreateRuleConfigurationDto, RuleConfigurationDto, RuleDto, UpdateRuleConfigurationDto } from "../../apis/model"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import { useToast } from "../../providers/ToastContextProvider"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import TablePageSkeleton from "common/TablePageSkeleton"
import TableTextCell from "common/TableTextCell"
import { makeStyles } from "makeStyles"
import { slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { useParams } from "react-router-dom"
import {
  ruleControllerCreateRulesConfiguration,
  ruleControllerUpdateRulesConfiguration,
  useRuleControllerAdminRulesConfiguration,
  useRuleControllerCreateRulesConfiguration,
  useRuleControllerRules,
  useRuleControllerUpdateRulesConfiguration,
} from "../../apis/endpoints/edgeService"

const useStyles = makeStyles()(() => ({
  companyRules: {
    padding: "1rem",
  },
  table: {
    height: "auto",
    maxHeight: "80vh",
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  btn: {
    marginRight: "1rem",
  },
}))

const CompanyRules = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const params = useParams()
  const companyId: number = Number(params.companyId)

  const { data, isLoading, refetch } = useRuleControllerAdminRulesConfiguration(companyId)
  const { data: rules, isLoading: isLoadingRules } = useRuleControllerRules()

  const { addToast } = useToast()

  const createRuleConfig = useRuleControllerCreateRulesConfiguration({
    mutation: {
      mutationFn: (toCreate: { data: CreateRuleConfigurationDto }) => {
        return ruleControllerCreateRulesConfiguration(toCreate.data)
      },
      onSuccess: () => {
        refetch()
        addToast("success", "Saved Successfully")
      },
      onError: (e: any) => addToast("error", "Failed to save"),
    },
  })

  const updateRuleConfig = useRuleControllerUpdateRulesConfiguration({
    mutation: {
      mutationFn: (toUpdate: { id: number; data: UpdateRuleConfigurationDto }) => {
        return ruleControllerUpdateRulesConfiguration(toUpdate.id, toUpdate.data)
      },
      onSuccess: () => {
        refetch()
        addToast("success", "Saved Successfully")
      },
      onError: () => addToast("error", "Failed to save"),
    },
  })

  const getChecked = (shortName: string) => {
    const ruleConfig = data?.find((ruleConfig: RuleConfigurationDto) => ruleConfig.shortName === shortName)

    return ruleConfig ? ruleConfig.active : false
  }

  const toggleActive = (rule: RuleDto, e: React.ChangeEvent<HTMLInputElement>) => {
    const ruleConfig = data?.find((ruleConf: RuleConfigurationDto) => ruleConf.shortName === rule.shortName)

    if (ruleConfig) {
      updateRuleConfig.mutate({
        id: ruleConfig.id,
        data: {
          active: e.target.checked,
        },
      })
    } else {
      createRuleConfig.mutate({
        data: {
          shortName: rule.shortName,
          companyId: companyId,
          active: e.target.checked,
        },
      })
    }
  }

  const columns: any[] = [
    {
      field: "id",
      headerName: t.General.id,
    },
    {
      field: "shortName",
      headerName: t.General.shortName,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "title",
      headerName: t.General.title,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "examinedEntity",
      headerName: t.General.examinedEntity,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "output",
      headerName: t.General.output,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "type",
      headerName: t.General.type,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "-",
      headerName: t.General.active,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Switch checked={getChecked(params.row.shortName)} onChange={toggleActive.bind(this, params.row)} />
      ),
    },
  ]

  if (isLoadingRules || isLoading || !data || !rules) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.companyRules} spacing={1} direction={"row"}>
      <Grid item xs={12} md={12}>
        <Grid container className={classes.table}>
          <DataGrid
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={rules || []}
            columns={columns}
            slotProps={slotProps}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompanyRules
