import Axios, { AxiosError, AxiosRequestConfig } from 'axios'

export var AXIOS_INSTANCE = Axios.create({ baseURL: process.env.REACT_APP_EDGE_SERVICE_URL  })

export var customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query")
  }

  return promise
}

export default customInstance

export interface ErrorType<Error> extends AxiosError<Error> {}
