import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { DataGridPro, DataGridProProps, GridRowId, GridToolbar } from "@mui/x-data-grid-pro"
import TableTextCell from "common/TableTextCell"
import TablePageSkeleton from "common/TablePageSkeleton"
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { NavigateFunction, useNavigate } from "react-router"
import Button from "@mui/material/Button"
import { slotProps } from "theme/data-grid"
import ExpandRule from "./ExpandRule"
import { useRuleControllerRules } from "../../apis/endpoints/edgeService"
import { useSearchParams } from "react-router-dom"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

const useStyles = makeStyles()(() => ({
  rules: {
    padding: "1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    padding: "1rem",
    height: "80vh",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
}))
const Rules = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const [searchParams] = useSearchParams()
  // const [ruleStatusGroups, setRuleStatusGroups] = useState<[string, number][]>([])

  const getSearchParams = () => {
    const result = []
    if (searchParams.get("title") !== null) {
      result.push({ id: 1, field: "title", value: searchParams.get("title"), operator: "equals" })
    }
    return result
  }

  const [filterParams] = useState(getSearchParams())
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const { data, isLoading } = useRuleControllerRules({
    query: {
      refetchOnMount: "always",
    },
  })

  // useEffect(() => {
  //   if (isSuccess) {
  //     const riskAnalyzerStatus = {
  //       [RiskAnalyzerDtoRiskStatus.Production]: 0,
  //       [RiskAnalyzerDtoRiskStatus.Engineering_working_on_it]: 0,
  //     }
  //     data.forEach((ra: RiskAnalyzerDto) => {
  //       riskAnalyzerStatus[ra.riskStatus]++
  //     })
  //     setRiskStatusGroups(Object.entries(riskAnalyzerStatus))
  //   }
  // }, [isSuccess, data])

  const goToRule = (id: number | string) => navigate(`${id}`)

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandRule row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const columns: any[] = [
    {
      field: "id",
      headerName: t.General.id,
    },
    {
      field: "shortName",
      headerName: t.General.shortName,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "title",
      headerName: t.General.title,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={params.value} />,
    },
    {
      field: "description",
      headerName: t.General.description,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} testId={`ra-title`} />,
    },
    {
      field: "type",
      headerName: t.General.type,
      flex: 0.8,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "examinedEntity",
      headerName: t.General.examinedEntity,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "remediation",
      headerName: t.General.remediation,
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <EditIcon onClick={goToRule.bind(null, params.id)} fontSize={"small"} className={classes.actionIcon} />
          <DeleteIcon fontSize={"small"} className={classes.actionIcon} />
        </Box>
      ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton />

  return (
    <Grid container className={classes.rules} spacing={1} data-testid={"rules"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={"h6"} data-testid={"title"} className={classes.title}>
              {data.length.toLocaleString()} {t.General.rules}
            </Typography>
            <Button variant={"contained"} onClick={goToRule.bind(null, "new")} data-testid={"create-new"}>
              {t.General.createNewRule}
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.table}>
          <DataGridPro
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={data}
            columns={columns}
            disableRowSelectionOnClick
            slotProps={slotProps}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            initialState={{
              filter: {
                filterModel: {
                  items: filterParams,
                },
              },
              sorting: {
                sortModel: [{ field: "active", sort: "desc" }],
              },
              columns: {
                ...columns,
                columnVisibilityModel: {
                  id: false,
                  remediation: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Rules
