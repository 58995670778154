import React, { useEffect } from "react"
import qs from "qs"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Box from "@mui/material/Box"

const useStyles = makeStyles()(() => ({
  fastlink: {
    padding: "0 2rem",
  },
  paper: {
    padding: "1rem 1rem",
  },
}))
const FastLink = (): JSX.Element => {
  const { classes } = useStyles()

  const getAccessToken = async (): Promise<string> => {
    const response = await axios.post(
      //@ts-ignore
      process.env.REACT_APP_YODLEE_CREATE_TOKEN_URL,
      qs.stringify({
        clientId: process.env.REACT_APP_YODLEE_CLIENT_ID,
        secret: process.env.REACT_APP_YODLEE_SECRET,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Api-Version": "1.1",
          loginName: process.env.REACT_APP_YODLEE_LOGIN_NAME,
        },
      }
    )
    return response?.data?.token?.accessToken
  }
  const { data, status, refetch } = useQuery({ queryKey: ["token"], queryFn: getAccessToken })

  useEffect(() => {
    if (status === "success" && "fastlink" in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fastlink.open(
        {
          fastLinkURL: process.env.REACT_APP_YODLEE_FAST_LINK_URL,
          accessToken: `Bearer ${data}`,
          forceIframe: true,
          iframeScrolling: true,
          params: {
            configName: process.env.REACT_APP_YODLEE_CONFIG_NAME,
          },

          onSuccess: function (data: any) {
            // will be called on success. For list of possible message, refer to onSuccess(data) Method.
            console.log(data)
          },
          onError: async function (data: any) {
            // will be called on error. For list of possible message, refer to onError(data) Method.
            if ((data.code = "N403")) {
              refetch()
            }
            console.log("the error", data)
          },
          onClose: function (data: any) {
            // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.
            console.log(data)
          },
          onEvent: function (data: any) {
            // will be called on intermittent status update.
            console.log(data)
          },
        },
        "container-fastlink"
      )
    }
  }, [status, data, refetch])
  return (
    <Grid container className={classes.fastlink} spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="body1">Yodlee Integration - here will be a description of yodlle</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Box component="div" id="container-fastlink">
            {/*<div style={{ textAlign: 'center' }}></div>*/}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FastLink
