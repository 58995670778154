import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import MainSidebar from "./MainSidebar"
import { NavigateFunction } from "react-router"
import { ITheme } from "../../theme/theme"
import { useTheme } from "@mui/material"
const SIDEBAR_WIDTH = 180

function SidebarWrapper({ sidebarElements }: any) {
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const { category } = useParams()
  const theme: ITheme = useTheme()

  const [comp, setComp] = useState(null)

  useEffect(() => {
    // @ts-ignore
    const playbook = sidebarElements.filter((x) => x.category.toLowerCase() === category.toLowerCase())
    playbook.length === 0 ? navigate("/not-found") : setComp(playbook[0].component)
  }, [location.pathname, navigate, category, sidebarElements])

  return (
    <>
      <MainSidebar width={SIDEBAR_WIDTH} sidebarElements={sidebarElements.map((x: any) => x.category)} />
      <div
        style={{
          color: theme.palette.text.primary,
          width: `calc(100% - ${SIDEBAR_WIDTH})`,
          height: "100vh",
          marginLeft: SIDEBAR_WIDTH,
        }}
      >
        {comp}
      </div>
    </>
  )
}

export default SidebarWrapper
