export const stringContainsOnlyNumber = (string: any): boolean => {
  return /^\d+$/.test(string)
}

export const stringContainsValidEmail = (string: any): boolean => {
  return /\S+@\S+\.\S+/.test(string)
}

export const stringContainsValidPhone = (string: any): boolean => {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g.test(string)
}

export const stringContainsValidSwiftCode = (string: any): boolean => {
  return /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/.test(string)
}

export const stringContainsOnlyValidChar = (string: any): boolean => {
  return /^[a-zA-Z0-9 '_./]*$/.test(string)
}

export const stringContainsValidWebsite = (string: any): boolean => {
  return /^(https:\/\/|http:\/\/|www\.)/.test(string)
}
