import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"

const Auth0ProviderWithNavigate = ({ children }: any) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "creednz-test.us.auth0.com"
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "5GinRhbbJKVN6y35Q39lj3S1qKV4ihh2"
  const redirectUri: string = process.env.REACT_APP_BASE_URL || "http://localhost:3000"
  const audience: string = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`

  return (
    <Auth0Provider
      useCookiesForTransactions={true}
      domain={domain}
      clientId={clientId}
      issuer={domain}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithNavigate
