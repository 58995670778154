import React, { useCallback, useEffect, useState } from "react"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { Alert } from "@mui/material"
import Typography from "@mui/material/Typography"

interface IProps {
  toast: { severity: "error" | "success"; message: string }
  remove: () => void
}

const Toast = ({ toast, remove }: IProps) => {
  const [open, setOpen] = useState<boolean>(true)

  const handleClose = useCallback(() => {
    setOpen(false)
    remove()
  }, [remove])

  useEffect(() => {
    if (!open) return

    const timer = setTimeout(handleClose, 10000)
    return () => clearTimeout(timer)
  }, [open, handleClose])

  return (
    <Stack data-testid="container">
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        data-testid="snackbar"
      >
        <Alert variant="filled" style={{ fontWeight: "bold" }} severity={toast.severity} data-testid="alert">
          <Typography>{toast.message}</Typography>
        </Alert>
      </Snackbar>
    </Stack>
  )
}
export default Toast
