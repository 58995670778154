import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { Button, Paper } from "@mui/material"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router"
import { formatDate } from "../../utils/utils"

const useStyles = makeStyles()(() => ({
  expand: {
    padding: "2rem 1rem",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0.75rem",
  },
}))

interface IProps {
  row: any
}
const ExpandInvite = ({ row }: IProps): JSX.Element => {
  const { classes } = useStyles()

  const navigate = useNavigate()
  const steps = [
    "Invite Sent",
    "Invite Opened",
    "Submission Completed",
    "Risk Analysis Completed",
    "Instant Bank Inspection",
  ]

  const isStepFailed = (step: number) => {
    if (row.status !== "Pending") {
      return step === 3
    }
    return false
  }

  const isShowButton = (step: number) => {
    return step === 4
  }

  const goToVendor = (url: string) => {
    return navigate(url)
  }

  const getActiveStep = () => {
    if (row.status === "Pending") {
      return 0
    } else {
      return 3
    }
  }

  return (
    <Grid container className={classes.expand}>
      <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>{`${row.vendorName} Flow`}</Typography>
      <Paper sx={{ padding: "1rem", width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={getActiveStep()}>
            {steps.map((label, index) => {
              const labelProps: {
                optional?: React.ReactNode
                error?: boolean
              } = {}
              if (isStepFailed(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={goToVendor.bind(null, `/vendors/${row.vendorDto.id}`)}
                  >
                    View Details
                  </Typography>
                )
                labelProps.error = true
              }

              if (isShowButton(index)) {
                labelProps.optional = (
                  <Button variant={"outlined"} sx={{ marginTop: "1rem" }}>
                    Send Request
                  </Button>
                )
                labelProps.error = false
              }

              // fix date - fix email mocks
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>
                    <Typography variant={"body1"}>{label}</Typography>
                    {index <= getActiveStep() && (
                      <Typography variant={"caption"}>
                        {index === 0 ? formatDate(row.createdAt) : formatDate(row.updatedAt)}
                      </Typography>
                    )}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
      </Paper>
    </Grid>
  )
}

export default ExpandInvite
