import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import { DataGridPro, DataGridProProps, GridToolbar } from "@mui/x-data-grid-pro"
import { makeStyles } from "makeStyles"
import { useEmailControllerGetEmails } from "../../apis/endpoints/edgeService"
import { formatDate } from "../../utils/utils"
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined"
import TablePageSkeleton from "../../common/TablePageSkeleton"
import ExpandEmailDetails from "./ExpandEmail"
import { ITheme } from "../../theme/theme"
import { useTheme } from "@mui/material"
import { dataGridSx, slotProps } from "../../theme/data-grid"
import TableTextCell from "../../common/TableTextCell"
import { GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-premium"
import Stack from "@mui/material/Stack"
import { NavigateFunction, useNavigate } from "react-router"
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined"
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined"

const useStyles = makeStyles()(() => ({
  emails: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    padding: "1rem",
    height: "80vh",
    width: "100%",
  },
  title: {
    fontWeight: 400,
  },
}))

const Emails = () => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const navigate: NavigateFunction = useNavigate()

  const { data: emails, isLoading } = useEmailControllerGetEmails({
    query: {
      refetchInterval: 2000,
    },
  })

  const navigateTo = (url: number | string) => navigate(`${url}`)

  const handleRowEvent: GridEventListener<"rowClick"> = (params: GridRowParams<any>) => navigateTo(params.id)

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandEmailDetails row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const columns: any[] = [
    { field: "id", headerName: "ID", flex: 0.7 },
    {
      field: "atRisk",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"}>
          {params.value ? (
            <GppMaybeOutlinedIcon color={"error"} sx={{ verticalAlign: "middle" }} />
          ) : (
            <GppGoodOutlinedIcon color={"success"} sx={{ verticalAlign: "middle" }} />
          )}
          <TableTextCell style={{ margin: "auto auto auto 0.75rem" }} content={params.value ? "At Risk" : " No Risk"} />
        </Stack>
      ),
    },
    {
      field: "sender",
      headerName: "Sent by",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "forwarder",
      headerName: "Forwarded By",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "receivedDateTime",
      headerName: "Received At",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      field: "hasAttachments",
      headerName: "Attachments",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (params.value ? <AttachFileOutlinedIcon fontSize={"small"} /> : ""),
    },
  ]

  if (isLoading || !emails) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.emails} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Stack direction={"column"}>
            <Typography variant="h6" className={classes.title}>
              {t.General.emails}
            </Typography>
            <Typography variant="body1">{`Send Emails to: acme-ap-inbox@creednzisr.onmicrosoft.com`}</Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} data-testid="email-list">
        <Paper className={classes.table}>
          <DataGridPro
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={emails || []}
            onRowClick={handleRowEvent}
            columns={columns}
            slotProps={slotProps}
            sx={dataGridSx}
            getEstimatedRowHeight={() => 200}
            getRowHeight={() => "auto"}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            initialState={{
              sorting: {
                sortModel: [{ field: "receivedDateTime", sort: "desc" }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Emails
