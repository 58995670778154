import React, { useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { Chip, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import TablePageSkeleton from "common/TablePageSkeleton"
import TableTextCell from "common/TableTextCell"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { formatDate } from "utils/utils"
import { useOrganizationControllerOrganizations } from "../../apis/endpoints/edgeService"
import DeleteDialog from "./DeleteDialog"
import CreateOrganization from "./CreateOrganization"
import { OrganizationDto } from "../../apis/model"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

const useStyles = makeStyles()(() => ({
  organizations: {
    padding: "0 1rem",
  },
  table: {
    height: "auto",
    maxHeight: "80vh",
  },
  actionIcon: {
    verticalAlign: "middle",
    marginRight: "2rem",
    cursor: "pointer",
  },
  btn: {
    margin: "1rem",
  },
}))
const Organizations = (): JSX.Element => {
  const { classes } = useStyles()
  const navigate: NavigateFunction = useNavigate()
  const theme: ITheme = useTheme()
  const t = theme.props

  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [openCreateOrganization, setOpenCreateOrganization] = useState<boolean>(false)
  const [organization, setOrganization] = useState<OrganizationDto | null>(null)

  const { data, isLoading, refetch } = useOrganizationControllerOrganizations({
    query: {
      refetchOnMount: "always",
    },
  })

  const navigateTo = (url: string) => navigate(url)

  const closeDialog = () => {
    refetch()
    setOpenCreateOrganization(false)
  }
  const closeDeleteDialog = (): void => setOpenDelete(false)

  const openDeleteOrg = (organization: OrganizationDto) => {
    setOrganization(organization)
    setOpenDelete(true)
  }

  const openCreateNewOrganization = () => setOpenCreateOrganization(true)

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      field: "name",
      headerName: t.General.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "companies",
      headerName: t.General.companies,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          variant="outlined"
          size="small"
          label={t.General.companies}
          onClick={navigateTo.bind(null, `/admin/organizations/${params.id}/companies`)}
        />
      ),
    },
    {
      field: "users",
      headerName: t.General.users,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          variant="outlined"
          size="small"
          label={t.General.users}
          onClick={navigateTo.bind(null, `/admin/organizations/${params.id}/users`)}
        />
      ),
    },
    {
      headerName: t.General.actions,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <EditIcon
              fontSize={"small"}
              onClick={navigateTo.bind(null, `/admin/organizations/${params.id}`)}
              className={classes.actionIcon}
            />
            <Chip
              variant="outlined"
              size="small"
              color={"error"}
              label={t.General.deleteOrganizationData}
              onClick={openDeleteOrg.bind(null, params.row)}
              className={classes.actionIcon}
            />
          </Box>
        )
      },
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.organizations} direction="row" justifyContent="flex-end" alignItems="center">
      <Grid item xs={12} md={12} display="flex" justifyContent={"flex-end"}>
        <Button className={classes.btn} variant={"contained"} onClick={openCreateNewOrganization}>
          {t.General.createOrganization}
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container className={classes.table}>
          <DataGrid
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={data}
            columns={columns}
            slotProps={slotProps}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Grid>
      </Grid>
      {openCreateOrganization && <CreateOrganization open={openCreateOrganization} closeDialog={closeDialog} />}
      {openDelete && organization && (
        <DeleteDialog open={openDelete} organization={organization} closeDialog={closeDeleteDialog} />
      )}
    </Grid>
  )
}

export default Organizations
