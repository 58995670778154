import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

const useStyles = makeStyles()(() => ({
  dashboard: {
    height: "auto",
    padding: "0 1rem",
  },
}))
const DashboardSkeleton = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Box className={classes.dashboard}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" height={"10rem"} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" height={"5rem"} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={7} md={9}>
              <Skeleton variant="rectangular" height={"30rem"} />
            </Grid>
            <Grid item xs={5} md={3}>
              <Grid container spacing={3}>
                {[1, 2, 3, 4].map(
                  (key: any): JSX.Element => (
                    <Grid item xs={12} md={12} lg={12} key={key} alignItems="center">
                      <Skeleton height={"6rem"} variant={"rectangular"} />
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rectangular" height={"28rem"} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardSkeleton
