import { createTheme, ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React, { useLayoutEffect } from "react"
import { AXIOS_INSTANCE } from "../../apis/mutator/custom-instance"
import { EN } from "../../locale"
import { Boarding } from "./Boarding"
import { useToast } from "../../providers/ToastContextProvider"
import { getErrorString } from "../../utils/utils"
import creednzThemeLight from "../../theme/theme-light"

export const VendorOnBoarding = (): JSX.Element => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const token = params.get("token")
  const { addToast } = useToast()

  useLayoutEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use((config: any) => ({
      ...config,
      headers: token
        ? {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          }
        : config.headers,
    }))
    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId)
    }
  }, [token])

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        // @ts-ignore
        addToast("error", `Error: ${getErrorString(error.request.status)}`)

        if (query.state.data !== undefined) {
          console.error(`Something went wrong: ${error || ""}`)
        }
      },
    }),
  })

  return (
    <ThemeProvider theme={createTheme(creednzThemeLight, EN)}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Boarding />
      </QueryClientProvider>
    </ThemeProvider>
  )
}
