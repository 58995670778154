import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import Button from "@mui/material/Button"
import { Checkbox, FormControlLabel, FormGroup, SelectChangeEvent, Skeleton, TextField, useTheme } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { NavigateFunction, useNavigate } from "react-router"
import Stack from "@mui/material/Stack"
import LoadingButton from "@mui/lab/LoadingButton"
import { CompanyDto, CreateUserDTO, CreateUserDTORoleName, UpdateUserDTO } from "../../apis/model"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import { stringContainsValidEmail } from "../../utils/regax"
import {
  useAuthControllerGetProfile,
  useOrganizationControllerCompanies,
  userControllerCreateUser,
  userControllerUpdate,
  useUserControllerCreateUser,
  useUserControllerUpdate,
  useUserControllerUser,
} from "apis/endpoints/edgeService"
import { useParams } from "react-router-dom"
import { usePermission } from "../../providers/AuthorizedUserPermissionsProvider"

const useStyles = makeStyles()(() => ({
  userForm: {
    padding: "1rem",
  },
  title: {
    fontWeight: 600,
  },
  form: {
    height: "75vh",
    padding: "1rem 0",
  },
  paper: {
    padding: "1rem",
  },
  save: {
    margin: "2rem 2rem 0 0",
  },
}))

interface IProps {
  nextStep?: () => void
}

const UserForm = ({ nextStep }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const userId: number = params?.id === undefined || params.id === "new" ? -1 : Number(params.id)
  const { isRole } = usePermission()

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    companyIds: "",
    email: "",
    name: "",
  })
  const [user, setUser] = useState<CreateUserDTO>({
    companyIds: [],
    selectedCompanyId: 0,
    email: "",
    roleName: CreateUserDTORoleName.CustomerUser,
    name: "",
  })

  const { data: meData, isLoading: meIsLoading } = useAuthControllerGetProfile()
  const { data, isLoading } = useOrganizationControllerCompanies(meData?.company.organizationId || 1, {
    query: {
      enabled: meData?.company.organizationId !== undefined,
    },
  })

  const {
    data: userData,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
  } = useUserControllerUser(userId, {
    query: {
      enabled: userId !== -1,
    },
  })

  useEffect(() => {
    userIsSuccess &&
      setUser({
        name: user.name,
        email: user.email,
        roleName: CreateUserDTORoleName[userData.roleDto.name as CreateUserDTORoleName],
        selectedCompanyId: user.selectedCompanyId,
        companyIds: userData.companiesDto?.map((company) => company.id) || [],
      })
  }, [userIsSuccess, user, userData])

  const createUser = useUserControllerCreateUser<CreateUserDTO>({
    mutation: {
      mutationFn: (userInput: { data: CreateUserDTO }) => {
        return userControllerCreateUser(userInput.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        nextStep ? nextStep() : navigate(-1)
      },
    },
  })

  const updateUser = useUserControllerUpdate<CreateUserDTO>({
    mutation: {
      mutationFn: (toUpdate: { data: UpdateUserDTO }) => {
        return userControllerUpdate(userId, toUpdate.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        nextStep ? nextStep() : navigate(-1)
      },
    },
  })

  const roles: CreateUserDTORoleName[] = isRole(CreateUserDTORoleName.CreednzAdmin)
    ? [
        CreateUserDTORoleName.CustomerUser,
        CreateUserDTORoleName.CustomerAdmin,
        CreateUserDTORoleName.CreednzUser,
        CreateUserDTORoleName.CreednzAdmin,
      ]
    : [CreateUserDTORoleName.CustomerUser, CreateUserDTORoleName.CustomerAdmin, CreateUserDTORoleName.CreednzUser]

  const navigateTo = (url: string) => navigate(url)

  const onUserNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => setUser({ ...user, name: e.target.value })

  const onUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setUser({ ...user, email: e.target.value })

  const handleRoleChange = (e: SelectChangeEvent): void =>
    setUser({ ...user, roleName: e.target.value as CreateUserDTORoleName })

  const handleCompanyCheck = (companyId: number): void => {
    user.companyIds.includes(companyId)
      ? setUser({ ...user, companyIds: user.companyIds.filter((id: number) => id !== companyId) })
      : setUser({ ...user, companyIds: [...user.companyIds, companyId] })
  }

  const validateForm = () => {
    const { name, email, companyIds } = user
    let errors = { ...formErrors }
    errors.name = !name
      ? t.Errors.fieldInNotDefined
      : name?.length === 0
        ? t.Errors.emptyInputError
        : name?.length > 400
          ? t.Errors.inputIsTooLongError
          : ""

    errors.email = !email
      ? ""
      : email?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidEmail(email)
          ? t.Errors.fieldForEmailOnly
          : ""

    errors.companyIds = !companyIds || companyIds?.length === 0 ? t.Errors.youMustSelectAtLeastOneCompany : ""

    setFormErrors(errors)
    return !Object.values(errors).some((error: string) => !!error)
  }

  const save = () => {
    if (validateForm()) {
      setIsSaving(true)
      user.selectedCompanyId = user.companyIds[0]

      if (userId !== -1) {
        const { companyIds, roleName, selectedCompanyId } = user
        updateUser.mutate({
          id: userId,
          data: { companyIds, roleName, selectedCompanyId },
        })
      } else {
        createUser.mutate({ data: user })
      }
    }
  }

  if (!data || isLoading || !meData || meIsLoading || (userId !== -1 && (userIsLoading || !userData)))
    return <Skeleton variant={"rectangular"} height={"15rem"} />

  return (
    <Grid container className={classes.userForm} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper sx={{ padding: "1rem" }}>
          <Typography variant={"h6"} className={classes.title}>
            {user?.name ? `Edit ${user.name}` : t.General.createUser}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ padding: "1rem" }}>
          <Grid container className={classes.form}>
            <Grid item md={8} xs={12}>
              <Stack spacing={3} width={"40%"}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={user.name}
                  label={t.General.name}
                  onChange={onUserNameChange}
                  inputProps={{ maxLength: 100 }}
                  required
                  helperText={formErrors.name}
                  error={formErrors.name !== ""}
                  disabled={userId !== -1}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  value={user.email}
                  type={"email"}
                  label={t.General.email}
                  onChange={onUserEmailChange}
                  inputProps={{ maxLength: 100 }}
                  required
                  helperText={formErrors.email}
                  error={formErrors.email !== ""}
                  disabled={userId !== -1}
                />
                <FormControl fullWidth data-testid="role">
                  <InputLabel id="category">{t.General.role}</InputLabel>
                  <Select
                    value={user.roleName}
                    label={t.General.role}
                    labelId="role"
                    onChange={handleRoleChange}
                    required
                    size={"small"}
                  >
                    {roles.map((role: CreateUserDTORoleName) => (
                      <MenuItem key={role} value={role}>
                        <Typography>{role}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormGroup>
                  <Typography variant={"body1"} gutterBottom fontWeight={600}>
                    {t.General.selectCompanies}
                  </Typography>
                  <Typography variant={"caption"} gutterBottom>
                    {t.General.youMustSelectAtLeastOneCompany}
                  </Typography>
                  {data?.map((company: CompanyDto) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.companyIds.includes(company.id)}
                          onClick={handleCompanyCheck.bind(null, company.id)}
                          key={`checkbox-${company.name}`}
                        />
                      }
                      key={company.name}
                      label={company.name}
                    />
                  ))}
                  {formErrors.companyIds !== "" && (
                    <Typography variant={"caption"} gutterBottom color={"error"}>
                      {t.General.youMustSelectAtLeastOneCompany}
                    </Typography>
                  )}
                </FormGroup>
              </Stack>
            </Grid>
            <Grid item md={8} xs={12}>
              <LoadingButton
                size="medium"
                color="primary"
                variant="contained"
                loading={isSaving}
                className={classes.save}
                onClick={save}
              >
                {t.General.save}
              </LoadingButton>
              <Button variant={"outlined"} onClick={navigateTo.bind(null, "/settings/users")} className={classes.save}>
                {t.General.cancel}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default UserForm
