import React from "react"
import Grid from "@mui/material/Grid"
import { Asset } from "../../utils/types"
import { ITheme } from "../../theme/theme"
import { useTheme } from "@mui/material"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import { NavigateFunction, useNavigate } from "react-router"
import { abbreviateNumber } from "../../utils/utils"
import { makeStyles } from "../../makeStyles"
import { EntitiesWidgetDto } from "../../apis/model"

const useStyles = makeStyles()((theme: ITheme) => ({
  asset: {
    cursor: "pointer",
  },
  paper: {
    padding: "1rem",
  },
  stack: {
    position: "relative",
  },
  alert: {
    borderRadius: "1rem",
    backgroundColor: theme.palette.severities.high,
    height: "1.5rem",
    width: "auto",
    position: "absolute",
    top: 0,
    right: "0",
  },
  alertNumber: {
    color: theme.palette.common.white,
    lineHeight: "1.5rem",
    padding: "0 0.75rem",
    cursor: "pointer",
  },
  totalAsset: {
    textAlign: "left",
    marginBottom: "0.75rem",
    fontWeight: 400,
  },
}))

interface IProps {
  entitiesWidget: EntitiesWidgetDto
}

const Assets = ({ entitiesWidget }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const navigate: NavigateFunction = useNavigate()

  const goToAlerts = (assetType: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    navigate(`/alerts?type=${assetType}`)
  }

  const goToAssetPage = (url: string) => {
    navigate(url)
  }

  const assets: Asset[] = [
    {
      name: t.General.payments,
      total: entitiesWidget.payment.total,
      linkTo: "payments",
      alerts: entitiesWidget.payment.alerts,
      type: "Payment",
    },
    {
      name: t.General.vendors,
      total: entitiesWidget.vendor.total,
      linkTo: "vendors",
      alerts: entitiesWidget.vendor.alerts,
      type: "Vendor",
    },
    // {
    //   name: "Vendor onboarding",
    //   total: 16,
    //   linkTo: "invite-vendor",
    //   alerts: 2,
    //   type: "",
    // },
    // {
    //   name: "Bank account validation",
    //   total: 84,
    //   linkTo: "",
    //   alerts: 5,
    //   type: "",
    // },
    // {
    //   name: "Bank accounts",
    //   total: 3,
    //   linkTo: "",
    //   alerts: 0,
    //   type: "",
    // },
    // {
    //   name: t.General.emails,
    //   total: 15000,
    //   linkTo: "emails",
    //   alerts: 0,
    //   type: "Emails",
    // },
  ]

  return (
    <Grid container spacing={3} direction="column">
      {assets.map(
        (asset: Asset): JSX.Element => (
          <Grid
            item
            xs={12}
            key={asset.name}
            alignItems="center"
            className={classes.asset}
            onClick={goToAssetPage.bind(this, `/${asset.linkTo}`)}
          >
            <Paper className={classes.paper}>
              <Stack direction={"column"} className={classes.stack}>
                <Stack>
                  {asset.alerts > 0 && (
                    <Box className={classes.alert} onClick={goToAlerts.bind(this, asset.type)}>
                      <Typography className={classes.alertNumber} variant={"subtitle1"}>
                        {abbreviateNumber(asset.alerts)}
                      </Typography>
                    </Box>
                  )}
                  <Typography variant={"h4"} className={classes.totalAsset}>
                    {abbreviateNumber(asset.total)}
                  </Typography>
                  <Typography variant={"body1"}>{asset.name}</Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ),
      )}
    </Grid>
  )
}

export default Assets
