/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type FindingDtoExaminedEntity = typeof FindingDtoExaminedEntity[keyof typeof FindingDtoExaminedEntity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FindingDtoExaminedEntity = {
  Vendor: 'Vendor',
  Payment: 'Payment',
  Ledger: 'Ledger',
  Email: 'Email',
} as const;
