import React, { useEffect, useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import { Checkbox, FormControlLabel, FormGroup, SelectChangeEvent, Skeleton, TextField, useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import {
  useOrganizationControllerCompanies,
  userControllerCreateUser,
  userControllerUpdate,
  useUserControllerCreateUser,
  useUserControllerUpdate,
  useUserControllerUser,
} from "apis/endpoints/edgeService"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import { ITheme } from "theme/theme"
import { CompanyDto, CreateUserDTO, CreateUserDTORoleName, UpdateUserDTO } from "../../apis/model"
import { stringContainsValidEmail } from "../../utils/regax"

const useStyles = makeStyles()(() => ({
  userForm: {
    padding: "1rem",
  },
  form: {
    height: "65vh",
    padding: "1rem 0",
  },
  save: {
    margin: "2rem 2rem 0 0",
  },
}))

const OrganizationUserForm = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const organizationId: number = Number(params.id)
  const userId: number = params?.userId === undefined || params.userId === "new" ? -1 : Number(params.userId)
  const companyId: number = params?.companyId === undefined ? -1 : Number(params.companyId)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    companyIds: "",
    email: "",
    name: "",
  })
  const [user, setUser] = useState<CreateUserDTO>({
    companyIds: companyId === -1 ? [] : [companyId],
    selectedCompanyId: companyId === -1 ? 0 : companyId,
    email: "",
    roleName: CreateUserDTORoleName.CustomerUser,
    name: "",
  })

  const { data, isLoading } = useOrganizationControllerCompanies(organizationId || 1, {
    query: {
      enabled: organizationId !== undefined,
    },
  })

  const {
    data: userData,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
  } = useUserControllerUser(userId, {
    query: {
      enabled: userId !== -1,
    },
  })

  useEffect(() => {
    userIsSuccess &&
      setUser({
        name: userData.name,
        email: userData.email,
        roleName: CreateUserDTORoleName[userData.roleDto.name as CreateUserDTORoleName],
        selectedCompanyId: userData.selectedCompanyId,
        companyIds: userData.companiesDto?.map((company) => company.id) || [],
      })
  }, [userIsSuccess, userData])

  const roles: CreateUserDTORoleName[] = [
    CreateUserDTORoleName.CustomerUser,
    CreateUserDTORoleName.CustomerAdmin,
    CreateUserDTORoleName.CreednzUser,
    CreateUserDTORoleName.CreednzAdmin,
  ]

  const navigateTo = (url: string) => navigate(url)

  const onUserNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => setUser({ ...user, name: e.target.value })

  const onUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setUser({ ...user, email: e.target.value })

  const handleRoleChange = (e: SelectChangeEvent): void =>
    setUser({ ...user, roleName: e.target.value as CreateUserDTORoleName })

  const handleCompanyCheck = (companyId: number): void => {
    user.companyIds.includes(companyId)
      ? setUser({ ...user, companyIds: user.companyIds.filter((id: number) => id !== companyId) })
      : setUser({ ...user, companyIds: [...user.companyIds, companyId] })
  }

  const validateForm = () => {
    const { name, email, companyIds } = user
    let errors = { ...formErrors }
    errors.name = !name
      ? t.Errors.fieldInNotDefined
      : name?.length === 0
        ? t.Errors.emptyInputError
        : name?.length > 400
          ? t.Errors.inputIsTooLongError
          : ""

    errors.email = !email
      ? t.Errors.fieldInNotDefined
      : email?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidEmail(email)
          ? t.Errors.fieldForEmailOnly
          : ""

    errors.companyIds = !companyIds || companyIds?.length === 0 ? t.Errors.youMustSelectAtLeastOneCompany : ""

    setFormErrors(errors)
    return !Object.values(errors).some((error: string) => !!error)
  }

  const createUser = useUserControllerCreateUser<CreateUserDTO>({
    mutation: {
      mutationFn: (userInput) => {
        return userControllerCreateUser(userInput.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate(-1)
      },
      onError: (e): void => {
        setIsSaving(false)
      },
    },
  })

  const updateUser = useUserControllerUpdate<CreateUserDTO>({
    mutation: {
      mutationFn: (toUpdate: { data: UpdateUserDTO }) => {
        return userControllerUpdate(userId, toUpdate.data)
      },
      onSuccess: () => {
        setIsSaving(false)
        navigate(-1)
      },
    },
  })

  const save = () => {
    if (validateForm()) {
      setIsSaving(true)
      user.selectedCompanyId = user.companyIds[0]

      if (userId !== -1) {
        const { companyIds, roleName, selectedCompanyId } = user
        updateUser.mutate({
          id: userId,
          data: { companyIds, roleName, selectedCompanyId },
        })
      } else {
        createUser.mutate({ data: user })
      }
    }
  }

  if ((companyId === -1 && (!data || isLoading)) || (userId !== -1 && (userIsLoading || !userData)))
    return <Skeleton data-testid={"loading"} variant={"rectangular"} height={"15rem"} />

  return (
    <Grid container className={classes.userForm} spacing={3}>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent={"space-between"}>
          <Typography variant="h6" data-testid={"title"}>
            {t.General.newUser}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.form}>
          <Grid item md={4} xs={12}>
            <Stack direction="column" spacing={3}>
              <TextField
                size="small"
                variant="outlined"
                value={user.name}
                label={t.General.name}
                onChange={onUserNameChange}
                helperText={formErrors.name}
                error={formErrors.name !== ""}
                inputProps={{ maxLength: 100 }}
                required
                data-testid={"name"}
                disabled={userId !== -1}
              />
              <TextField
                size="small"
                variant="outlined"
                value={user.email}
                type={"email"}
                label={t.General.email}
                onChange={onUserEmailChange}
                helperText={formErrors.email}
                error={formErrors.email !== ""}
                inputProps={{ maxLength: 100 }}
                required
                data-testid={"email"}
                disabled={userId !== -1}
              />
              <FormControl fullWidth>
                <InputLabel id="category">{t.General.role}</InputLabel>
                <Select
                  value={user.roleName}
                  label={t.General.role}
                  labelId="role"
                  onChange={handleRoleChange}
                  required
                  size={"small"}
                >
                  {roles.map((role: CreateUserDTORoleName) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormGroup>
                <Typography variant={"body1"} gutterBottom fontWeight={600}>
                  {t.General.selectCompanies}
                </Typography>
                <Typography variant={"caption"} gutterBottom>
                  {t.General.youMustSelectAtLeastOneCompany}
                </Typography>
                {data?.map((company: CompanyDto) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid={`${company.name}`}
                        checked={user.companyIds.includes(company.id)}
                        onClick={handleCompanyCheck.bind(null, company.id)}
                        key={`checkbox-${company.name}`}
                      />
                    }
                    key={company.name}
                    label={company.name}
                  />
                ))}
                {formErrors.companyIds !== "" && (
                  <Typography variant={"caption"} gutterBottom color={"error"}>
                    {t.General.youMustSelectAtLeastOneCompany}
                  </Typography>
                )}
              </FormGroup>
            </Stack>
          </Grid>
        </Grid>
        <LoadingButton
          size="medium"
          color="primary"
          variant="contained"
          loading={isSaving}
          className={classes.save}
          onClick={save}
          data-testid={"save-button"}
        >
          {t.General.save}
        </LoadingButton>
        <Button variant={"outlined"} onClick={navigateTo.bind(null, `/admin/organizations`)} className={classes.save}>
          {t.General.cancel}
        </Button>
      </Grid>
    </Grid>
  )
}

export default OrganizationUserForm
