import React from "react"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import * as serviceWorker from "serviceWorker"
import Auth0ProviderWithNavigate from "providers/Auth0ProviderWithNavigate"
import { AuthProvider } from "./providers/auth.context"
import { LicenseInfo } from "@mui/x-data-grid-pro"
import ToastContextProvider from "./providers/ToastContextProvider"
import { AuthorizedUserPermissionsProvider } from "./providers/AuthorizedUserPermissionsProvider"

const container = document.getElementById("root")
const root = createRoot(container!)

LicenseInfo.setLicenseKey(
  "fd58c0fd8a32404986e996aa70514bafTz03MzM5MCxFPTE3MjQ1MTg0ODAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
)

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <AuthProvider>
        <AuthorizedUserPermissionsProvider>
          <ToastContextProvider>
            <React.StrictMode>
              <App/>
            </React.StrictMode>
          </ToastContextProvider>
        </AuthorizedUserPermissionsProvider>
      </AuthProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
)
serviceWorker.unregister()
