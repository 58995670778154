import React from "react"
import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
import TimelineDot from "@mui/lab/TimelineDot"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined"
import GroupsIcon from "@mui/icons-material/Groups"
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined"
import dayjs from "dayjs"
import { formatDate } from "../../utils/utils"

interface IProps {
  risks: any
  vendorName: string
}
const VendorTimeline = ({ risks, vendorName }: IProps): JSX.Element => {
  const steps = [
    {
      title: "Vendor Created",
      subTitle: "ERP",
      icon: (
        <TimelineDot color="primary">
          <GroupsIcon />
        </TimelineDot>
      ),
      time: formatDate(dayjs().subtract(3, "days").subtract(2, "hours").toISOString()),
    },
  ]

  if (!risks.bankAccount && !risks.sanction && !risks.cyber && !vendorName.toLowerCase().includes("management")) {
    steps.unshift({
      title: "No risks",
      subTitle: "No risks detected",
      icon: (
        <TimelineDot color="success" variant="outlined">
          <GppGoodOutlinedIcon color={"success"} />
        </TimelineDot>
      ),
      time: formatDate(dayjs().subtract(3, "days").toISOString()),
    })
  } else {
    steps.unshift({
      title: risks.cyber ? "Cyber risk" : risks.sanction ? "Sanction risk" : "Bank account validation risk",
      subTitle: "Risk detected",
      icon: (
        <TimelineDot color="error" variant="outlined">
          <GppMaybeOutlinedIcon color={"error"} />
        </TimelineDot>
      ),
      time: formatDate(dayjs().subtract(3, "days").subtract(2, "hours").toISOString()),
    })
  }

  return (
    <Accordion>
      <AccordionSummary
        sx={{ height: "5rem", fontSize: "1.3rem", borderRadius: "1rem" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Change Log
      </AccordionSummary>
      <AccordionDetails>
        <Timeline position="right">
          {!risks.bankAccount && !risks.sanction && !risks.cyber && vendorName.toLowerCase().includes("management") && (
            <React.Fragment>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2" color="text.secondary">
                  {formatDate(dayjs().subtract(3, "days").toISOString())}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="success" variant="outlined">
                    <GppGoodOutlinedIcon color={"success"} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant="h6" component="span">
                    No risks
                  </Typography>
                  <Typography>No risks detected</Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2" color="text.secondary">
                  {formatDate(dayjs().subtract(3, "days").toISOString())}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary">
                    <GroupsIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant="h6" component="span">
                    Vendor Updated
                  </Typography>
                  <Typography>Vendor bank account was updated</Typography>
                </TimelineContent>
              </TimelineItem>
            </React.Fragment>
          )}
          {steps.map((step) => {
            return (
              <TimelineItem key={step.title}>
                <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2" color="text.secondary">
                  {step.time}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  {step.icon}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant="h6" component="span">
                    {step.title}
                  </Typography>
                  <Typography>{step.subTitle}</Typography>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </AccordionDetails>
    </Accordion>
  )
}

export default VendorTimeline
