/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type FindingDtoType = typeof FindingDtoType[keyof typeof FindingDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FindingDtoType = {
  Alert: 'Alert',
  Gap: 'Gap',
  Insight: 'Insight',
} as const;
