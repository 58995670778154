/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

/**
 * User's role
 */
export type CreateUserDTORoleName = typeof CreateUserDTORoleName[keyof typeof CreateUserDTORoleName];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateUserDTORoleName = {
  CreednzAdmin: 'CreednzAdmin',
  CreednzUser: 'CreednzUser',
  CustomerAdmin: 'CustomerAdmin',
  CustomerUser: 'CustomerUser',
} as const;
