import { useAuth0 } from "@auth0/auth0-react"
import { createTheme, ThemeProvider } from "@mui/material"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import PrivateRoute from "PrivateRoute"
import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { useAuthControllerGetProfile } from "./apis/endpoints/edgeService"
import Header from "./common/header/Header"
import SidebarWrapper from "./common/header/SidebarWrapper"
import { EN } from "./locale"
import Companies from "./pages/companies/Companies"
import CompanyEdit from "./pages/companies/CompanyEdit"
import Dashboard from "./pages/dashboard/Dashboard"
import Emails from "./pages/emails/Emails"
import Indicator from "./pages/indicator/Indicator"
import Indications from "./pages/indicators/Indicators"
import InviteVendor from "./pages/invite-vendor/InviteVendor"
import NotFound from "./pages/NotFound"
import OrganizationEdit from "./pages/organizations/OrganizationEdit"
import FastLink from "./pages/settings/FastLink"
import Integrations from "./pages/settings/Integrations"
import NotificationsSettings from "./pages/settings/NotificationsSettings"
import Payment from "./pages/payment/Payment"
import Payments from "./pages/payments/Payments"
import UploadBankTemplate from "./pages/upload-bank-template/UploadBankTemplate"
import UploadBankTransactions from "./pages/upload-bank-transactions/UploadBankTransactions"
import UploadMVF from "./pages/upload-mvf/UploadMvf"
import UploadPaymentFile from "./pages/upload-payment-file/UploadPaymentFile"
import UserForm from "./pages/users/UserForm"
import Users from "./pages/users/Users"
import VendorFileChangeLog from "./pages/vendor-file-changelog/VendorFileChangeLog"
import Vendor from "./pages/vendor/Vendor"
import VendorForm from "./pages/vendor/VendorForm"
import Vendors from "./pages/vendors/Vendors"
import VersionLog from "./pages/version-log/VersionLog"
import { usePermission } from "./providers/AuthorizedUserPermissionsProvider"
import creednzThemeDark from "./theme/theme-dark"
import creednzThemeLight from "./theme/theme-light"
import Organizations from "./pages/organizations/Organizations"
import { CreateUserDTORoleName } from "./apis/model"
import { VendorFormType } from "./utils/types"
import OrganizationUsers from "./pages/organization-users/OrganizationUsers"
import OrganizationUserForm from "./pages/organization-users/OrganizationUserForm"
import Ledgers from "./pages/ledgers/Ledgers"
import PaymentsAlerts from "./pages/dashboard/PaymentsAlerts"
import Admin from "./pages/admin/Admin"
import Rules from "./pages/rules/Rules"
import RuleForm from "./pages/rules/RuleForm"
import RulesConfiguration from "./pages/rules-configuration/RulesConfiguration"
import CompanyRules from "./pages/companies/CompanyRules"
import Email from "./pages/email/Email"
import Coverage from "./pages/dashboard/Coverage"

const settings: { category: string; component: JSX.Element }[] = [
  {
    category: "Integrations",
    component: <Integrations />,
  },
  {
    category: "Notifications",
    component: <NotificationsSettings />,
  },
  {
    category: "Coverage",
    component: <Coverage />,
  },
  {
    category: "Users",
    component: <Users />,
  },
  {
    category: "Version",
    component: <VersionLog />,
  },
]
const ConsoleRoutes = (): JSX.Element => {
  const { isAuthenticated } = useAuth0()
  const { setUserRole } = usePermission()

  const [mode, setMode] = useState<string>(localStorage.getItem("mode") || "dark")
  const [doneDispatchToken, setDoneDispatchToken] = useState<boolean>(false)
  const [isCreednzAdmin, setIsCreednzAdmin] = useState<boolean | null>(null)
  const [isCustomerAdmin, setIsCustomerAdmin] = useState<boolean | null>(null)

  const { data, isSuccess } = useAuthControllerGetProfile({
    query: {
      enabled: false,
    },
  })

  useEffect(() => {
    isSuccess && setUserRole(data.roles, data.permissions)
  }, [isSuccess, data, setUserRole])

  useEffect(() => {
    setTimeout(() => setDoneDispatchToken(true), 0)
  }, [isAuthenticated])

  useEffect(() => {
    if (isCreednzAdmin === null && data?.roles) {
      if (data?.roles.includes(CreateUserDTORoleName.CreednzAdmin)) {
        setIsCreednzAdmin(true)
      } else {
        setIsCreednzAdmin(false)
      }

      if (data?.roles.includes(CreateUserDTORoleName.CustomerAdmin)) {
        setIsCustomerAdmin(true)
      } else {
        setIsCustomerAdmin(false)
      }
    }
  }, [doneDispatchToken, data, isCreednzAdmin])

  const changeMode = () => {
    const newMode = mode === "dark" ? "light" : "dark"
    setMode(newMode)
    localStorage.setItem("mode", newMode)
  }

  const routes = (): JSX.Element => {
    return (
      <React.Suspense>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/payments-alerts"
            element={
              <PrivateRoute>
                <PaymentsAlerts />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-mvf"
            element={
              <PrivateRoute>
                <UploadMVF />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-change-log"
            element={
              <PrivateRoute>
                <VendorFileChangeLog />
              </PrivateRoute>
            }
          />
          <Route
            path="/indicators"
            element={
              <PrivateRoute>
                <Indications />
              </PrivateRoute>
            }
          />
          <Route
            path="/alerts"
            element={
              <PrivateRoute>
                <Indications />
              </PrivateRoute>
            }
          />
          <Route
            path="/gaps"
            element={
              <PrivateRoute>
                <Indications />
              </PrivateRoute>
            }
          />
          <Route
            path="/insights"
            element={
              <PrivateRoute>
                <Indications />
              </PrivateRoute>
            }
          />

          <Route
            path="/vendors"
            element={
              <PrivateRoute>
                <Vendors />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendors"
            element={
              <PrivateRoute>
                <Vendors />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendors/:id"
            element={
              <PrivateRoute>
                <Vendor />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor/:id"
            element={
              <PrivateRoute>
                <VendorForm vendorFormType={VendorFormType.UpdateVendor} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor"
            element={
              <PrivateRoute>
                <VendorForm vendorFormType={VendorFormType.NewVendor} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-evaluation"
            element={
              <PrivateRoute>
                <InviteVendor />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-bank-template"
            element={
              <PrivateRoute>
                <UploadBankTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-bank-transactions"
            element={
              <PrivateRoute>
                <UploadBankTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-payment-file"
            element={
              <PrivateRoute>
                <UploadPaymentFile />
              </PrivateRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <PrivateRoute>
                <Payments />
              </PrivateRoute>
            }
          />
          <Route
            path="/payments/:id"
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path="/emails"
            element={
              <PrivateRoute>
                <Emails />
              </PrivateRoute>
            }
          />
          <Route
            path="/emails/:id"
            element={
              <PrivateRoute>
                <Email />
              </PrivateRoute>
            }
          />
          <Route
            path="/ledgers"
            element={
              <PrivateRoute>
                <Ledgers />
              </PrivateRoute>
            }
          />
          <Route
            path="/indicators/:id"
            element={
              <PrivateRoute>
                <Indicator />
              </PrivateRoute>
            }
          />
          <Route
            path="/alerts/:id"
            element={
              <PrivateRoute>
                <Indicator />
              </PrivateRoute>
            }
          />
          <Route
            path="/gaps/:id"
            element={
              <PrivateRoute>
                <Indicator />
              </PrivateRoute>
            }
          />
          <Route
            path="/insights/:id"
            element={
              <PrivateRoute>
                <Indicator />
              </PrivateRoute>
            }
          />
          <Route
            path="/rules-configuration"
            element={
              <PrivateRoute>
                <RulesConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/rules/:id"
            element={
              <PrivateRoute>
                <RuleForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/:category"
            element={
              <PrivateRoute>
                <SidebarWrapper sidebarElements={settings} />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
          {(isCreednzAdmin || isCustomerAdmin) && (
            <Route
              path="/settings/users/:id"
              element={
                <PrivateRoute>
                  <UserForm />
                </PrivateRoute>
              }
            />
          )}
          <Route
            path="/settings/integrations/fastlink"
            element={
              <PrivateRoute>
                <FastLink />
              </PrivateRoute>
            }
          />
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations"
              element={
                <PrivateRoute>
                  <Organizations />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id"
              element={
                <PrivateRoute>
                  <OrganizationEdit />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/companies"
              element={
                <PrivateRoute>
                  <Companies />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/users"
              element={
                <PrivateRoute>
                  <OrganizationUsers />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/users/:userId"
              element={
                <PrivateRoute>
                  <OrganizationUserForm />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/companies/:companyId"
              element={
                <PrivateRoute>
                  <CompanyEdit />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/companies/:companyId/rules"
              element={
                <PrivateRoute>
                  <CompanyRules />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/organizations/:id/companies/:companyId/users/:userId"
              element={
                <PrivateRoute>
                  <OrganizationUserForm />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/rules"
              element={
                <PrivateRoute>
                  <Rules />
                </PrivateRoute>
              }
            />
          )}
          {isCreednzAdmin && (
            <Route
              path="/admin/rules/:id"
              element={
                <PrivateRoute>
                  <RuleForm />
                </PrivateRoute>
              }
            />
          )}
        </Routes>
      </React.Suspense>
    )
  }

  return (
    <ThemeProvider theme={createTheme(mode === "dark" ? creednzThemeDark : creednzThemeLight, EN)}>
      <CssBaseline />
      <PrivateRoute>
        {doneDispatchToken ? <Header children={routes()} changeMode={changeMode} mode={mode} /> : <Box />}
      </PrivateRoute>
    </ThemeProvider>
  )
}

export default ConsoleRoutes
