import React, { useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import TablePageSkeleton from "common/TablePageSkeleton"
import TableTextCell from "common/TableTextCell"
import { makeStyles } from "makeStyles"
import { NavigateFunction, useNavigate } from "react-router"
import { slotProps } from "theme/data-grid"
import { ITheme } from "theme/theme"
import { formatDate } from "utils/utils"
import { useParams } from "react-router-dom"
import { useOrganizationControllerCompanies } from "../../apis/endpoints/edgeService"
import { CompanyDto } from "../../apis/model"
import DeleteDialog from "./DeleteDialog"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

const useStyles = makeStyles()(() => ({
  companies: {
    padding: "1rem",
  },
  table: {
    height: "80vh",
  },
  actionIcon: {
    margin: "1rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))

const Companies = (): JSX.Element => {
  const { classes } = useStyles()
  const navigate: NavigateFunction = useNavigate()
  const theme: ITheme = useTheme()
  const t = theme.props

  const params = useParams()
  const organizationId: number = Number(params.id)

  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [company, setCompany] = useState<CompanyDto | null>(null)

  const { data, isLoading, refetch } = useOrganizationControllerCompanies(organizationId, {
    query: {
      refetchOnMount: "always",
    },
  })

  const navigateTo = (url: string) => navigate(url)

  const closeDialog = () => {
    refetch()
    setOpenDelete(false)
  }
  const openDeleteCompany = (company: CompanyDto) => {
    setCompany(company)
    setOpenDelete(true)
  }

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      field: "name",
      headerName: t.General.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "domain",
      headerName: t.General.domain,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "rules",
      headerName: t.General.rules,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          variant="outlined"
          size="small"
          label={t.General.rules}
          onClick={navigateTo.bind(null, `/admin/organizations/${organizationId}/companies/${params.id}/rules`)}
        />
      ),
    },
    {
      headerName: t.General.actions,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <EditIcon
              onClick={navigateTo.bind(null, `/admin/organizations/${organizationId}/companies/${params.id}`)}
              className={classes.actionIcon}
              fontSize={"medium"}
            />
            <Chip
              variant="outlined"
              size="small"
              color={"error"}
              label={t.General.deleteCompanyData}
              onClick={openDeleteCompany.bind(null, params.row)}
              className={classes.actionIcon}
            />
          </Box>
        )
      },
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.companies} spacing={1} direction={"row"}>
      <Grid item xs={12} md={12} display="flex" justifyContent={"flex-end"}>
        <Button
          variant={"contained"}
          onClick={navigateTo.bind(null, `/admin/organizations/${organizationId}/companies/new`)}
        >
          {t.General.createCompany}
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container className={classes.table}>
          <DataGrid
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            rows={data || []}
            columns={columns}
            slotProps={slotProps}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
          />
        </Grid>
      </Grid>
      {openDelete && <DeleteDialog open={openDelete} company={company} closeDialog={closeDialog} />}
    </Grid>
  )
}

export default Companies
