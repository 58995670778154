import React, { useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import CssBaseline from "@mui/material/CssBaseline"
import { Skeleton } from "@mui/material"
import Login from "./pages/login"
import ConsoleRoutes from "./ConsoleRoutes"
import { useAuthDispatch } from "./providers/auth.context"
import NewUserSignUp from "./pages/new-user-signup/NewUserSignUp"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { VendorOnBoarding } from "./pages/vendor-on-boarding/VendorOnBoarding"

const App = (): JSX.Element => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0()
  const dispatch = useAuthDispatch()
  const [token, setToken] = useState<string | null>(null)

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100vw" height="100vh" data-testid="loading" />
  }

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/vendor-on-boarding" element={<VendorOnBoarding />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    )
  }

  if (error) {
    console.error("Authentication error: ", error.message)
    return <NewUserSignUp />
  }

  if (!token) {
    getAccessTokenSilently().then((res: any) => {
      dispatch(res)
      setToken(res)
    })
    return <Skeleton variant="rectangular" width="100vw" height="100vh" data-testid="loading" />
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <CssBaseline />
      <Routes>
        <Route path="/*" element={<ConsoleRoutes />} />
      </Routes>
    </QueryClientProvider>
  )
}

export default App
