import React from "react"
import { makeStyles } from "../../makeStyles"
import { ITheme } from "../../theme/theme"
import { useTheme } from "@mui/material"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { NavigateFunction, useNavigate } from "react-router"
import { FindingsWidgetDto } from "../../apis/model"

const useStyles = makeStyles()(() => ({
  title: {
    padding: "0.75rem 0",
    width: "100%",
    fontWeight: 400,
  },
}))

interface IProps {
  findingsWidget: FindingsWidgetDto
}

const FindingsByType = ({ findingsWidget }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()

  const navigate: NavigateFunction = useNavigate()

  const navigateTo = (route: any): void => navigate(route)

  const getPercentage = (value: number): number => {
    const total = findingsWidget.alerts + findingsWidget.gaps + findingsWidget.insights
    return (100 * value) / total
  }

  return (
    <Paper sx={{ padding: "0 0.875rem", height: "15.5rem" }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ height: "23vh" }}>
        <Typography
          variant={"h5"}
          className={classes.title}
        >{`Risks (${findingsWidget.alerts + findingsWidget.gaps + findingsWidget.insights})`}</Typography>
        <Grid item xs={12}>
          <Box
            component={"div"}
            sx={{
              width: "20%",
              height: "30px",
              display: "flex",
              position: "absolute",
              borderRadius: "5px",
            }}
          >
            <Box
              component={"span"}
              sx={{
                backgroundColor: theme.palette.severities.high,
                width: `${getPercentage(findingsWidget.alerts)}%`,
                height: "100%",
                borderRadius: "5px 0 0  5px",
              }}
            />
            <Box
              component={"span"}
              sx={{
                backgroundColor: theme.palette.severities.medium,
                width: `${getPercentage(findingsWidget.gaps)}%`,
                height: "100%",
                borderRadius: findingsWidget.insights === 0 ? "0 5px 5px 0" : "0",
              }}
            />
            <Box
              component={"span"}
              sx={{
                backgroundColor: theme.palette.severities.info,
                width: `${getPercentage(findingsWidget.insights)}%`,
                height: "100%",
                borderRadius: "0 5px 5px 0",
              }}
            />
          </Box>
        </Grid>
        <Grid container sx={{ margin: "3rem 0 2rem 0" }}>
          <Grid item xs={3}>
            <Grid
              container
              sx={{ borderLeft: `3px solid ${theme.palette.severities.high}`, cursor: "pointer" }}
              onClick={navigateTo.bind(null, `/alerts`)}
            >
              <Grid item sx={{ paddingLeft: "0.75rem" }}>
                <Stack>
                  <Typography sx={{ fontWeight: 400, fontSize: "2rem" }}>{findingsWidget.alerts}</Typography>
                </Stack>
                <Stack>Alerts</Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              sx={{ borderLeft: `3px solid ${theme.palette.severities.medium}`, cursor: "pointer" }}
              onClick={navigateTo.bind(null, `/gaps`)}
            >
              <Grid item sx={{ paddingLeft: "0.75rem" }}>
                <Stack sx={{ fontWeight: 600 }}>
                  <Typography sx={{ fontWeight: 400, fontSize: "2rem" }}>{findingsWidget.gaps}</Typography>
                </Stack>
                <Stack>Gaps</Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              sx={{ borderLeft: `3px solid ${theme.palette.severities.info}`, cursor: "pointer" }}
              onClick={navigateTo.bind(null, `/insights`)}
            >
              <Grid item sx={{ paddingLeft: "0.75rem" }}>
                <Stack sx={{ fontWeight: 600 }}>
                  <Typography sx={{ fontWeight: 400, fontSize: "2rem" }}>{findingsWidget.insights}</Typography>
                </Stack>
                <Stack>Insights</Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FindingsByType
