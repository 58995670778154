import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { formatDate } from "utils/utils"
import FieldWithValue from "../../common/FieldWithValue"
import { RuleDto } from "../../apis/model"

const useStyles = makeStyles()((theme: ITheme) => ({
  expand: {
    padding: "1rem",
    height: "auto",
  },
  titleBox: {
    margin: "0.5rem 0rem 0 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    verticalAlign: "middle",
    margin: "0 0 0.5rem 0rem",
  },
}))

interface IProps {
  row: RuleDto
}
const ExpandRule = ({ row }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  return (
    <Grid container className={classes.expand}>
      <Grid item xs={12} md={9}>
        <FieldWithValue name={t.General.output} value={row.output} />
        <FieldWithValue name={t.General.examinedEntity} value={row.examinedEntity} testId={"rule-examined-entity"} />
        <FieldWithValue name={t.General.type} value={row.type} testId={"rule-type"} />
      </Grid>
      <Grid item xs={12} md={3}>
        <FieldWithValue name={t.General.createdAt} value={formatDate(row.createdAt)} />
        <FieldWithValue name={t.General.updatedAt} value={formatDate(row.updatedAt)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <FieldWithValue name={t.General.remediation} value={row.remediation} />
      </Grid>
    </Grid>
  )
}

export default ExpandRule
