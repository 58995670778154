import * as React from "react"
import { useState } from "react"
import { FormControlLabel, FormLabel, Radio, RadioGroup, useTheme } from "@mui/material"
import { makeStyles } from "makeStyles"
import { ITheme } from "theme/theme"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import Upload from "../Upload"
import { UploadType } from "../../utils/types"
import FormControl from "@mui/material/FormControl"

const useStyles = makeStyles()(() => ({
  uploadContent: {
    width: "43vh",
    height: "100vh",
  },
  title: {
    textAlign: "center",
    fontWeight: 600,
  },
  formLabel: {
    textTransform: "capitalize",
    fontSize: "1.5rem",
    fontWeight: 600,
    padding: "1rem 0",
  },
  formControl: {
    marginBottom: "3rem",
    padding: "0 2rem",
  },
}))

interface IProps {
  openImport: boolean
}

const ActionDrawer = ({ openImport }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const [title, setTitle] = useState<string>(t.General.uploadMVF)
  const [uploadValue, setUploadValue] = React.useState<UploadType>(UploadType.Mvf)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch ((event.target as HTMLInputElement).value) {
      case "mvf":
        setUploadValue(UploadType.Mvf)
        setTitle(t.General.uploadMVF)
        break
      case "payment":
        setUploadValue(UploadType.Payment)
        setTitle(t.General.uploadPaymentFile)
        break
      case "ledger":
        setUploadValue(UploadType.Ledger)
        setTitle(t.General.uploadLedger)
        break
      case "transaction":
        setUploadValue(UploadType.Transaction)
        setTitle(t.General.uploadBankTransactions)
        break
      default:
        setUploadValue(UploadType.Mvf)
    }
  }

  return (
    <Box className={classes.uploadContent}>
      <List>
        <FormControl className={classes.formControl}>
          <FormLabel className={classes.formLabel} data-testid={"upload-type"}>
            {t.General.uploadType}
          </FormLabel>
          <RadioGroup name="upload-radio-group" value={uploadValue} onChange={handleChange} data-testid={"radio-group"}>
            <FormControlLabel value={UploadType.Mvf} control={<Radio size="small" />} label={t.General.uploadMVF} />
            <FormControlLabel
              value={UploadType.Payment}
              control={<Radio size="small" />}
              label={t.General.uploadPaymentFile}
            />
            <FormControlLabel
              value={UploadType.Ledger}
              control={<Radio size="small" />}
              label={t.General.uploadLedger}
            />
            <FormControlLabel
              value={UploadType.Transaction}
              control={<Radio size="small" />}
              label={t.General.uploadBankTransactions}
            />
          </RadioGroup>
        </FormControl>
        <Typography className={classes.title} variant={"body1"}>
          {title}
        </Typography>
        <ListItem disablePadding data-testid={"upload-component"}>
          <Upload type={uploadValue} emptyFile={!openImport} />
        </ListItem>
      </List>
    </Box>
  )
}

export default ActionDrawer
