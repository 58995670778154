import React from "react"
import { CompanyDto } from "../../apis/model"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import CheckIcon from "@mui/icons-material/Check"
import { NavigateFunction, useNavigate } from "react-router"
import { ITheme } from "theme/theme"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Paper from "@mui/material/Paper"
import {
  useOrganizationControllerOrganization,
  userControllerSetSelectedCompany,
  useUserControllerSetSelectedCompany,
  useUserControllerUserCompanies,
} from "../../apis/endpoints/edgeService"

const useStyles = makeStyles()((theme: ITheme) => ({
  selectCompany: {
    height: "auto",
    width: "100%",
    padding: "1rem",
    borderRadius: "1rem",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    fontSize: "1rem",
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
  },
  selectedCompany: {
    fontWeight: 600,
    lineHeight: "3.3rem",
    marginLeft: "1rem",
    // color: theme.palette.text.primary,
  },
  checkIcon: {
    height: "3.5rem",
    margin: "0 auto",
    verticalAlign: "middle",
    marginLeft: "2rem",
  },
  org: {
    marginBottom: "2rem",
    fontWeight: 600,
  },
  company: {
    cursor: "pointer",
    padding: "0.75rem 1rem",
    "&:hover": {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))

interface IProps {
  selectedCompany: CompanyDto
  refetch: () => void
}

const SelectCompany = ({ selectedCompany, refetch }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const navigate: NavigateFunction = useNavigate()

  const { data, isLoading } = useUserControllerUserCompanies()
  const { data: orgData, isLoading: isLoadingOrg } = useOrganizationControllerOrganization(
    selectedCompany.organizationId,
  )

  const navigateTo = (url: string) => navigate(url)

  const switchCompany = useUserControllerSetSelectedCompany({
    mutation: {
      mutationFn: (selectedCompanyInput: { selectedCompanyId: number }) => {
        return userControllerSetSelectedCompany(selectedCompanyInput.selectedCompanyId)
      },
      onSuccess: () => {
        refetch()
        navigateTo("/")
      },
    },
  })

  const setSelectedCompany = (selectedCompanyId: number) => switchCompany.mutate({ selectedCompanyId })

  if (isLoading || !data || isLoadingOrg || !orgData) <Skeleton variant={"rectangular"} height={"15rem"} />

  return (
    <Paper>
      <Grid container className={classes.selectCompany}>
        <Grid item>
          <Typography variant={"h6"} className={classes.org}>
            {orgData?.name || ""}
          </Typography>
          {data &&
            data.map((company: CompanyDto) => (
              <Stack direction={"row"} className={classes.company} onClick={setSelectedCompany.bind(null, company.id)}>
                <Avatar className={classes.avatar} variant="rounded">
                  {company.name.substring(0, 3)}
                </Avatar>
                <Typography variant={"body1"} className={classes.selectedCompany}>
                  {company.name}
                </Typography>
                {company.id === selectedCompany.id && <CheckIcon className={classes.checkIcon} />}
              </Stack>
            ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SelectCompany
