import React, { useState, useEffect } from "react"
import GppMaybeIcon from "@mui/icons-material/GppMaybe"
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import FieldWithValue from "common/FieldWithValue"
import { makeStyles } from "makeStyles"
import { useParams } from "react-router-dom"
import { ITheme } from "theme/theme"
import { formatDate } from "utils/utils"
import IndicatorSkeleton from "./IndicatorSkeleton"
import { FindingDto, RuleDtoExaminedEntity, VendorDto } from "../../apis/model"
import {
  useFindingsControllerFinding,
  usePaymentControllerPayment,
  useVendorsControllerVendor,
  useVendorsControllerVendorByName,
} from "apis/endpoints/edgeService"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"

const useStyles = makeStyles()((theme: ITheme) => ({
  indicator: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  box: {
    padding: "1rem 0",
  },
  divider: {
    margin: "0.5rem 0",
  },
  description: {
    padding: "1rem",
    margin: "1rem 0",
    border: `1px solid #E7EEFA`,
    borderRadius: "8px",
  },
  boxDetails: {
    height: "26.25rem",
    border: "1px solid #E7EEFA",
    padding: "1rem 0.85rem",
    borderRadius: "10px",
  },
}))

const Indicator = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props

  const indicatorId = Number(useParams().id)
  const [indicator, setIndicator] = useState<FindingDto | null>(null)

  const { data, isLoading, isSuccess } = useFindingsControllerFinding(indicatorId)

  useEffect(() => {
    isSuccess && setIndicator(data)
  }, [isSuccess, data])

  const { data: vendor, isLoading: isLoadingVendor } = useVendorsControllerVendor(
    indicator?.examinedEntityId ? indicator?.examinedEntityId : 0,
    {
      query: {
        enabled: !!indicator?.examinedEntityId && indicator.examinedEntity === RuleDtoExaminedEntity.Vendor,
      },
    },
  )

  const { data: payment, isLoading: isLoadingPayment } = usePaymentControllerPayment(
    indicator?.examinedEntityId ? indicator?.examinedEntityId : 0,
    {
      query: {
        enabled: !!indicator?.examinedEntityId && indicator.examinedEntity === RuleDtoExaminedEntity.Payment,
      },
    },
  )

  const { data: vendorByNameData, isLoading: isLoadingVendorByNameData } = useVendorsControllerVendorByName(
    payment?.payeeName || "",
    {
      query: {
        enabled: !!payment?.payeeName,
      },
    },
  )

  const renderVendor = (vendor: VendorDto) => {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent={"space-between"} columnSpacing={2}>
            <Grid item xs={12} alignItems={"center"}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Groups2OutlinedIcon color={"primary"} sx={{ marginRight: "0.5rem" }} />
                <Typography variant="h6" fontWeight={600} data-testid={"vendor-name"}>
                  {vendor.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} alignItems={"center"}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"1rem"}>
                  Status
                </Typography>
                <Box className={classes.boxDetails}>
                  <FieldWithValue
                    name={"Status"}
                    value={"At Risk"}
                    testId={"internal-id"}
                    style={{ backgroundColor: "rgba(219,97,97,0.2)", borderRadius: "5px" }}
                    icon={<InfoOutlinedIcon color={"error"} />}
                  />
                  <FieldWithValue name={"Source"} value={"ERP"} testId={"internal-id"} />
                  <FieldWithValue name={"Owner"} value={"Yaniv Hayun"} testId={"internal-id"} />
                  <Divider sx={{ margin: "1rem 0" }} />
                  <FieldWithValue
                    name={t.General.createdAt}
                    value={vendor?.dateCreated ? formatDate(vendor.dateCreated) : "-"}
                  />
                  <FieldWithValue
                    name={t.General.lastEdited}
                    value={vendor?.lastModified ? formatDate(vendor.lastModified) : "-"}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"1rem"}>
                  General details
                </Typography>
                <Box className={classes.boxDetails}>
                  <Box className={classes.box}>
                    <FieldWithValue name={t.General.internalId} value={vendor.internalId} testId={"internalId"} />
                    <FieldWithValue name={t.General.email} value={vendor.email} />
                    <FieldWithValue name={t.General.phone} value={vendor.phone} />
                    <FieldWithValue name={t.General.taxpayerID} value={vendor.registrationCode} />
                    <FieldWithValue name={t.General.registrationCode} value={vendor.taxpayerID} />
                    <FieldWithValue name={t.General.currency} value={vendor.currency} />
                  </Box>
                  <Divider sx={{ margin: "1rem 0" }} />
                  <Box>
                    <FieldWithValue name={t.General.primaryContact} value={vendor.primaryContact} />
                    <FieldWithValue name={t.General.primarySubsidiary} value={vendor.primarySubsidiary} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"1rem"}>
                  Payment details
                </Typography>
                <Box className={classes.boxDetails}>
                  <Box>
                    <FieldWithValue name={t.General.iban} value={vendor.iban} />
                    <FieldWithValue name={t.General.swiftCode} value={vendor.swift} />
                    <FieldWithValue name={t.General.abaNumber} value={vendor.abaNumber} />
                    <FieldWithValue name={t.General.paymentMethod} value={vendor.vendorPaymentMethod} />
                    <FieldWithValue name={t.General.paypalAccount} value={vendor.paypalAccount} />
                  </Box>
                  <Divider sx={{ margin: "1rem 0" }} />
                  <Box>
                    <FieldWithValue name={t.General.address} value={vendor.address} />
                    <FieldWithValue name={t.General.countryCode} value={vendor.billingCountry} />
                    <FieldWithValue name={t.General.billingRegion} value={vendor.billingRegion} />
                    <FieldWithValue name={t.General.billingZipCode} value={vendor.billingZip} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            {/*<Grid item xs={12} md={4}>*/}
            {/*  <Stack>*/}
            {/*    <Box className={classes.box}>*/}
            {/*      <FieldWithValue name={t.General.bankAccountName} value={vendor.bankAccountName} />*/}
            {/*      <FieldWithValue name={t.General.bankAccountType} value={vendor.bankAccountType} />*/}
            {/*      <FieldWithValue name={t.General.bankCode} value={vendor.bankCode} />*/}
            {/*      <FieldWithValue name={t.General.bankAccountNumber} value={vendor.bankAccountNumber} />*/}
            {/*      <FieldWithValue name={t.General.bankAddress} value={vendor.bankAddress} />*/}
            {/*    </Box>*/}
            {/*  </Stack>*/}
            {/*</Grid>*/}
          </Grid>
        </Paper>
      </Grid>
    )
  }

  if (
    isLoading ||
    !indicator ||
    !data ||
    (indicator?.examinedEntity === RuleDtoExaminedEntity.Vendor && (isLoadingVendor || !vendor)) ||
    (indicator?.examinedEntity === RuleDtoExaminedEntity.Payment &&
      (isLoadingPayment || !payment || isLoadingVendorByNameData || !vendorByNameData))
  )
    return <IndicatorSkeleton />

  return (
    <Grid container className={classes.indicator} spacing={1} data-testid={"indicator"}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12} alignItems={"center"}>
              <Stack direction="row" alignItems="center" gap={1}>
                <GppMaybeIcon color={"error"} />
                <Typography variant="h5" fontWeight={600} data-testid={"title"}>
                  {indicator?.title}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack>
                <Typography variant="body1" gutterBottom className={classes.description} data-testid={"description"}>
                  {indicator?.description}
                </Typography>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    General Details
                  </Typography>
                  <FieldWithValue name={t.General.type} value={indicator?.type} />
                  <FieldWithValue
                    name={t.General.createdAt}
                    value={indicator?.createdAt ? formatDate(indicator.createdAt) : "-"}
                  />
                  <FieldWithValue
                    name={t.General.remediation}
                    value={indicator?.remediation || "-"}
                    testId="remediation"
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {payment && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid container justifyContent={""} spacing={2}>
                <Grid item xs={12} alignItems={"center"}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <PaidOutlinedIcon color={"primary"} />
                    <Typography variant="h6" fontWeight={600} data-testid={"name"}>
                      {t.General.payment}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                      General details
                    </Typography>
                    <Box className={classes.boxDetails}>
                      <Box>
                        <FieldWithValue name={t.General.id} value={payment.id} />
                        <FieldWithValue name={t.General.amount} value={payment.amount.toLocaleString()} />
                        <FieldWithValue name={t.General.currencyCode} value={payment.currencyCode} />
                        <FieldWithValue name={t.General.abaNumber} value={payment?.abaNumber || "-"} />
                      </Box>
                      <Divider sx={{ margin: "1rem 0" }} />
                      <Box>
                        <FieldWithValue
                          name={t.General.createdAt}
                          value={data?.createdAt ? formatDate(data.createdAt) : "-"}
                        />
                        <FieldWithValue
                          name={t.General.lastEdited}
                          value={data?.updatedAt ? formatDate(data.updatedAt) : "-"}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                      Payee details
                    </Typography>
                    <Box className={classes.boxDetails}>
                      <Box>
                        <FieldWithValue
                          name={t.General.payeeBankAccountNumber}
                          value={payment?.payeeBankAccountNumber || "-"}
                        />
                        <FieldWithValue
                          name={t.General.payeeBankAccountNumber}
                          value={payment?.payeeBankAccountNumber || "-"}
                        />
                        <FieldWithValue name={t.General.payerName} value={payment?.payeeName || "-"} />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      {vendor && renderVendor(vendor)}
      {vendorByNameData?.name && renderVendor(vendorByNameData)}
    </Grid>
  )
}

export default Indicator
