import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "makeStyles"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import TableTextCell from "common/TableTextCell"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import { NavigateFunction, useNavigate } from "react-router"
import TablePageSkeleton from "common/TablePageSkeleton"
import { dataGridSx, slotProps } from "theme/data-grid"
import { DataGridPro, DataGridProProps, GridRowId, GridToolbar } from "@mui/x-data-grid-pro"
import ExpandPayment from "./ExpandPayment"
import { GridEventListener, GridRenderCellParams, GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium"
import { formatDate } from "../../utils/utils"
import { usePaymentControllerPayments } from "../../apis/endpoints/edgeService"
import Stack from "@mui/material/Stack"
import { useSearchParams } from "react-router-dom"
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined"
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined"

const useStyles = makeStyles()(() => ({
  payments: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  table: {
    height: "78vh",
    width: "100%",
  },
  title: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
  actionIcon: {
    marginRight: "0.75rem",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}))

const Payments = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const [searchParams] = useSearchParams()

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const navigateTo = (url: number | string) => navigate(`${url}`)

  const { data, isLoading } = usePaymentControllerPayments({
    query: {
      refetchOnMount: "always",
    },
  })
  const apiRef = useGridApiRef()

  const getSearchParams = () => {
    const result = []
    if (searchParams.get("paymentDate")) {
      result.push({ id: 1, field: "paymentDate", value: searchParams.get("paymentDate"), operator: "contains" })
    }
    if (searchParams.get("atRisk") && searchParams.get("atRisk") !== "Total") {
      if (searchParams.get("atRisk") === "Trusted") {
        result.push({
          id: 2,
          field: "atRisk",
          value: "false",
          operator: "equals",
        })
      } else {
        result.push({
          id: 2,
          field: "atRisk",
          value: "true",
          operator: "equals",
        })
      }
    }
    return result
  }
  const [filterParams] = useState(getSearchParams)

  const handleRowEvent: GridEventListener<"rowClick"> = (params: GridRowParams<any>) =>
    navigateTo(params.id !== "" ? params.id : `/vendor/${params.id}`)

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandPayment row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const columns: any[] = [
    { field: "id", headerName: "ID" },
    {
      field: "atRisk",
      headerName: "Status",
      flex: 1.3,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"}>
          {params.value ? (
            <GppMaybeOutlinedIcon color={"error"} sx={{ verticalAlign: "middle" }} />
          ) : (
            <GppGoodOutlinedIcon color={"success"} sx={{ verticalAlign: "middle" }} />
          )}
          <TableTextCell style={{ margin: "auto auto auto 0.75rem" }} content={params.value ? "At Risk" : " No Risk"} />
        </Stack>
      ),
    },
    {
      field: "payeeName",
      headerName: "Name",
      flex: 1.3,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.7,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "currencyCode",
      headerName: "Currency code",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
    },
    {
      field: "paymentDate",
      headerName: "Payment date",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <TableTextCell content={params.value ? formatDate(params.value) : "-"} />
      ),
    },
  ]

  if (isLoading || !data) return <TablePageSkeleton columns={columns} />

  return (
    <Grid container className={classes.payments} spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" className={classes.title}>
              {data.length.toLocaleString()} {t.General.payments}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container className={classes.table}>
            <DataGridPro
              apiRef={apiRef}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              rows={data}
              columns={columns}
              onRowClick={handleRowEvent}
              slotProps={slotProps}
              sx={dataGridSx}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
              initialState={{
                filter: {
                  filterModel: {
                    items: filterParams,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Payments
