import * as React from "react"
import { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import { Checkbox, FormControlLabel, FormGroup, useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useVendorOnBoardingControllerInvite, vendorOnBoardingControllerInvite } from "apis/endpoints/edgeService"
import { ITheme } from "theme/theme"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { stringContainsValidEmail } from "../../utils/regax"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { getErrorString } from "../../utils/utils"
import { useToast } from "../../providers/ToastContextProvider"
import { InviteVendorOnBoardingDto } from "../../apis/model"
import Divider from "@mui/material/Divider"

interface IProps {
  open: boolean
  closeDialog: (isToRefetch: boolean) => void
}

const InviteVendorDialog = ({ open, closeDialog }: IProps): JSX.Element => {
  const theme: ITheme = useTheme()
  const t = theme.props
  const [vendorOnBoarding, setVendorOnBoarding] = useState<InviteVendorOnBoardingDto>({
    vendorName: "",
    primaryContact: "",
    email: "",
  })
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    vendorName: "",
    primaryContact: "",
    email: "",
  })
  const [inviting, setInviting] = useState<boolean>(false)
  const { addToast } = useToast()

  const validateForm = () => {
    const { vendorName, primaryContact, email } = vendorOnBoarding
    let errors = { ...formErrors }
    errors.vendorName = !vendorName
      ? t.Errors.fieldInNotDefined
      : vendorName?.length === 0
        ? t.Errors.emptyInputError
        : vendorName?.length > 400
          ? t.Errors.inputIsTooLongError
          : ""

    errors.email = !email
      ? t.Errors.fieldInNotDefined
      : email?.length > 400
        ? t.Errors.inputIsTooLongError
        : !stringContainsValidEmail(email)
          ? t.Errors.fieldForEmailOnly
          : ""

    errors.primaryContact = !primaryContact
      ? t.Errors.fieldInNotDefined
      : String(primaryContact).length > 400
        ? t.Errors.inputIsTooLongError
        : ""

    setFormErrors(errors)

    return !Object.values(errors).some((error: string) => !!error)
  }

  const sendInvitation = () => {
    if (validateForm()) {
      setInviting(true)
      inviteVendorOnBoarding.mutate({ data: vendorOnBoarding })
    }
  }

  const inviteVendorOnBoarding = useVendorOnBoardingControllerInvite({
    mutation: {
      mutationFn: (vendorOnBoarding: { data: InviteVendorOnBoardingDto }) => {
        return vendorOnBoardingControllerInvite(vendorOnBoarding.data)
      },
      onSuccess: () => {
        setInviting(false)
        setVendorOnBoarding({ primaryContact: "", email: "", vendorName: "" })
        closeDialog(true)
      },
      onError: (error) => {
        addToast("error", `Error: ${getErrorString(error.request.status)}`)
        setInviting(false)
      },
    },
  })

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendorOnBoarding({ ...vendorOnBoarding, email: e.target.value })

  const onPrimaryContactChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendorOnBoarding({ ...vendorOnBoarding, primaryContact: e.target.value })

  const onCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setVendorOnBoarding({ ...vendorOnBoarding, vendorName: e.target.value })

  const closeDialogWithOutSaving = () => closeDialog(false)

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={closeDialogWithOutSaving}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ padding: "1rem" }}
    >
      <DialogTitle id="alert-dialog-title" fontWeight={600}>
        {t.General.sendVendorEvaluationRequest}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography>{t.General.pleaseCompleteTheFormToInvite}</Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <Stack spacing={2}>
              <TextField
                size="small"
                variant="outlined"
                value={vendorOnBoarding.email}
                onChange={onEmailChange}
                label={t.General.email}
                inputProps={{ maxLength: 250 }}
                helperText={formErrors.email}
                error={formErrors.email !== ""}
                required
                data-testid={"email-text-field"}
              />
              <TextField
                size="small"
                variant="outlined"
                value={vendorOnBoarding.vendorName}
                onChange={onCompanyNameChange}
                label={t.General.vendorName}
                inputProps={{ maxLength: 250 }}
                helperText={formErrors.vendorName}
                error={formErrors.vendorName !== ""}
                required
                data-testid={"vendor-name-text-field"}
              />
              <TextField
                size="small"
                variant="outlined"
                value={vendorOnBoarding.primaryContact}
                onChange={onPrimaryContactChange}
                label={t.General.primaryContactName}
                inputProps={{ maxLength: 250 }}
                helperText={formErrors.primaryContact}
                error={formErrors.primaryContact !== ""}
                required
                data-testid={"primary-contact-text-field"}
              />
              <TextField
                size="small"
                variant="outlined"
                label={"Message"}
                inputProps={{ maxLength: 500 }}
                rows={4}
                multiline
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Bank account verification" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Sanctions" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Cyber" />
              </FormGroup>
              <Divider />
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Allow Creednz connect to vendor" />
              </FormGroup>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={closeDialogWithOutSaving}>
          {t.General.cancel}
        </Button>
        <LoadingButton loading={inviting} variant={"contained"} onClick={sendInvitation} data-testid={"invite-button"}>
          {t.General.invite}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default InviteVendorDialog
