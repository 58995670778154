import React, { useState, useContext } from "react"
import Toast from "../common/Toast"

interface IToastInterface {
  toast?: { severity: "error" | "success"; message: string } | null
  addToast: (severity: "error" | "success", message: string) => void
}

export const ToastContext = React.createContext({} as IToastInterface)

export const useToast = () => useContext(ToastContext)

const ToastContextProvider = ({ children }: any) => {
  const [toast, setToast] = useState<{ severity: "error" | "success"; message: string } | null>(null)

  const addToast = (severity: "error" | "success", message: string) => {
    setToast({ severity, message })
  }

  const removeToast = () => setToast(null)

  return (
    <ToastContext.Provider value={{ addToast, toast }}>
      {toast !== null && <Toast toast={toast} remove={removeToast} data-testid={toast} />}
      {children}
    </ToastContext.Provider>
  )
}

export default ToastContextProvider
