import React from "react"
import { ITheme } from "../../theme/theme"
import { Grid, Paper, useTheme } from "@mui/material"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { makeStyles } from "../../makeStyles"
import { abbreviateNumber, formatDate } from "../../utils/utils"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { PaymentCycleDto, PaymentWidgetDto } from "../../apis/model"
import { useNavigate } from "react-router"
import EmptyPayments from "./EmptyPayments"

const useStyles = makeStyles()((theme: ITheme) => ({
  asset: {
    padding: "1rem",
    cursor: "pointer",
  },
  paper: {
    padding: "1rem",
  },
  stack: {
    position: "relative",
  },
  percentage: {
    lineHeight: "3rem",
    marginLeft: "0.75rem",
  },
  total: {
    lineHeight: "3rem",
    fontWeight: 400,
  },
  name: {
    color: theme.palette.secondary.main,
  },
}))

interface IProps {
  paymentWidget: PaymentWidgetDto
  paymentCycle: PaymentCycleDto[]
}
const PaymentCycle = ({ paymentWidget, paymentCycle }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const navigate = useNavigate()
  const [alignment, setAlignment] = React.useState("justify")

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment)
  }

  const payment = {
    series: [
      {
        name: "Total",
        group: "total",
        data: paymentCycle.map((pc: PaymentCycleDto) => pc.totalAmount),
      },
      {
        name: "Trusted",
        group: "actual",
        data: paymentCycle.map((pc: PaymentCycleDto) => pc.trustedAmount),
      },
      {
        name: "Risky",
        group: "actual",
        data: paymentCycle.map((pc: PaymentCycleDto) => pc.riskyAmount),
      },
      {
        name: "Hold",
        group: "actual",
        data: [0, 0, 0],
      },
    ],
    options: {
      chart: {
        background: "transparent",
        type: "bar",
        width: "100%",
        stacked: false,
        toolbar: {
          show: false,
        },
        events: {
          click: (event: any, chartContext: any, config: any) => {
            if (config.seriesIndex === -1 && config.dataPointIndex === -1) {
              return
            } else {
              console.log(config.config.xaxis.categories[config.dataPointIndex])
              console.log(config.config.series[config.seriesIndex].name)
              navigate(
                `/payments?paymentDate=${formatDate(config.config.xaxis.categories[config.dataPointIndex], "YYYY-MM-DD")}&atRisk=${
                  config.config.series[config.seriesIndex].name
                }`,
              )
            }
          },
          xAxisLabelClick: (event: any, chartContext: any, config: any) => {
            if (config.labelIndex !== undefined) {
              navigate(`/indicators?indicator=${config.config.xaxis.categories[config.labelIndex]}`)
            }
          },
          dataPointMouseEnter: (event: any) => {
            event.target.style.cursor = "pointer"
          },
        },
        zoom: {
          enabled: true,
        },
      },
      colors: [
        theme.palette.primary.main,
        theme.palette.severities.success,
        theme.palette.severities.high,
        theme.palette.severities.info,
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          columnWidth: "35%",
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (val: any) => {
          return `${abbreviateNumber(val)}`
        },
      },
      grid: {
        show: true,
        borderColor: "rgba(144,164,174,0.18)",
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return `$${val.toLocaleString()}`
          },
        },
      },
      xaxis: {
        tickPlacement: "on",
        type: "datetime",
        categories: paymentCycle.map((pc: PaymentCycleDto) => pc.date),
      },
      legend: {
        show: false,
        floating: false,
        position: "top",
        offsetX: 0,
        offsetY: 0,
        markers: {
          radius: 12,
        },
      },
      fill: {
        opacity: 0.7,
      },
      theme: {
        mode: theme.palette.mode,
      },
    },
  }

  const children = [
    <ToggleButton value="left" key="left" sx={{ padding: "0 1rem" }}>
      7 Days
    </ToggleButton>,
    <ToggleButton value="center" key="center" sx={{ padding: "0 1rem" }}>
      15 Days
    </ToggleButton>,
    <ToggleButton value="right" key="right" sx={{ padding: "0.5rem 1rem" }}>
      30 Days
    </ToggleButton>,
    <ToggleButton value="justify" key="justify" sx={{ padding: "0 1rem" }}>
      Max
    </ToggleButton>,
  ]

  const paymentKeys: any = [
    {
      name: "Total Payments Amount",
      total: paymentWidget.totalAmount,
      percentage: null,
      color: theme.palette.primary.main,
    },
    {
      name: "Total Trusted Payments",
      total: paymentWidget.totalAmount,
      percentage: paymentWidget.totalAmount > 0 ? (100 * paymentWidget.trustedAmount) / paymentWidget.totalAmount : 0,
      color: theme.palette.severities.success,
    },
    {
      name: "Total Risky Payments",
      total: paymentWidget.riskyAmount,
      percentage: paymentWidget.totalAmount > 0 ? (100 * paymentWidget.riskyAmount) / paymentWidget.totalAmount : 0,
      color: theme.palette.severities.high,
    },
    {
      name: "Payments At Hold",
      total: 0,
      percentage: 0,
      color: theme.palette.severities.info,
    },
  ]

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  }

  return (
    <Paper sx={{ padding: "1rem", height: "54vh" }}>
      <Grid container spacing={3} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h5"}>Payment Run</Typography>
            <ToggleButtonGroup size={"small"} {...control} aria-label="Small sizes" sx={{ float: "right" }}>
              {children}
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          {paymentWidget.totalAmount === 0 && <EmptyPayments />}
          {paymentWidget.totalAmount > 0 && (
            <ReactApexChart
              options={payment.options as ApexOptions}
              series={payment.series}
              type="bar"
              height={400}
              width={"100%"}
            />
          )}
        </Grid>
        <Grid item xs={2} marginTop={"1rem"}>
          <Grid container spacing={3} direction="row" justifyContent={"flex-end"}>
            {paymentKeys.map(
              (key: any): JSX.Element => (
                <Grid item xs={12} md={12} lg={12} key={key.name} alignItems="center" className={classes.asset}>
                  <Stack direction={"column"} className={classes.stack}>
                    <Stack>
                      <Typography variant={"body1"} className={classes.name}>
                        {key.name}
                      </Typography>
                      <Stack direction={"row"}>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{
                            borderRightWidth: 5,
                            borderRadius: "1rem",
                            marginRight: "5px",
                            backgroundColor: key.color,
                          }}
                        />
                        <Typography variant={"h6"} className={classes.total}>
                          ${key.total.toLocaleString()}
                        </Typography>
                        {key.percentage > 0 && (
                          <Typography variant={"body1"} className={classes.percentage}>
                            {`(${key.percentage.toFixed(0)}%)`}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              ),
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PaymentCycle
