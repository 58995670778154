import React, { useMemo } from "react"
import { makeStyles } from "makeStyles"
import Grid from "@mui/material/Grid"
import { useParams } from "react-router-dom"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import FieldWithValue from "../../common/FieldWithValue"
import { formatDate } from "../../utils/utils"
import {
  useFindingsControllerFindingsByExaminedId,
  usePaymentControllerPayment,
  useVendorsControllerVendorByName,
} from "../../apis/endpoints/edgeService"
import VendorSkeleton from "../vendor/VendorSkeleton"
import { DataGridPro, DataGridProProps, GridRowId } from "@mui/x-data-grid-pro"
import { GridToolbar } from "@mui/x-data-grid"
import { dataGridSx, slotProps } from "../../theme/data-grid"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import TableTextCell from "../../common/TableTextCell"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import Divider from "@mui/material/Divider"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AccordionDetails from "@mui/material/AccordionDetails"
import Link from "@mui/material/Link"
import ExpandIndicators from "../indicators/ExpandIndicators"
import { FindingDto, FindingDtoType } from "../../apis/model"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"
import { NavigateFunction, useNavigate } from "react-router"

const useStyles = makeStyles()((theme: ITheme) => ({
  payment: {
    padding: "0 1rem",
  },
  paper: {
    padding: "1rem",
  },
  bottomPaper: {
    padding: "1rem",
    marginBottom: "1rem",
  },
  table: {
    height: "auto",
  },
  box: {
    padding: "1rem 0",
  },
  paymentDetails: {
    height: "15rem",
    border: "1px solid #E7EEFA ",
    padding: "1rem 0.85rem",
    borderRadius: "10px",
  },
  boxDetails: {
    height: "26.25rem",
    border: "1px solid #E7EEFA ",
    padding: "1rem 0.85rem",
    borderRadius: "10px",
  },
}))

const Payment = (): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const paymentId = parseInt(useParams().id || "0")
  const navigate: NavigateFunction = useNavigate()

  const { data, isLoading } = usePaymentControllerPayment(paymentId)
  const { data: findings, isLoading: isLoadingFindings } = useFindingsControllerFindingsByExaminedId(paymentId)

  // const { data: examinedEntityIdData, isLoading: isLoadingExaminedEntityId } = usePaymentControllerPayment(paymentId)

  const { data: vendor, isLoading: isLoadingVendor } = useVendorsControllerVendorByName(data?.payeeName || "", {
    query: {
      enabled: !!data?.payeeName,
    },
  })

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const isAtRisk = useMemo(() => {
    return findings?.find((finding: FindingDto) => finding.type === FindingDtoType.Alert)
  }, [findings])

  const handleClose = () => setAnchorEl(null)

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = ({ row }) => <ExpandIndicators row={row} />

  const getDetailPanelHeight: DataGridProProps["getDetailPanelHeight"] = () => "auto"

  const ExpandableCell = ({ description }: { description: string }) => {
    const [expanded, setExpanded] = React.useState(false)

    return (
      <Box component="div" key={description}>
        {expanded ? description : `${description.slice(0, 0)}`}
        {description.length > 0 && (
          <Link
            sx={{ paddingLeft: expanded ? "0.5rem" : 0, fontSize: "0.5", cursor: "pointer" }}
            type="button"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
              event.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </Box>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  // const columns: any[] = [
  //   { field: "id", headerName: t.General.id, flex: 0.2 },
  //   {
  //     field: "type",
  //     headerName: t.General.type,
  //     flex: 0.3,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell content={params.value} />,
  //   },
  //   {
  //     field: "title",
  //     headerName: t.General.name,
  //     flex: 1.6,
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Box>
  //         <TableTextCell
  //           content={params.value}
  //           testId={params.value}
  //           style={{ fontWeight: 600, marginBottom: "0.4rem", fontSize: "1rem" }}
  //         />
  //         <ExpandableCell {...params} />
  //       </Box>
  //     ),
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: t.General.createdAt,
  //     flex: 0.5,
  //     renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
  //   },
  // ]

  const navigateTo = (url: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    navigate(url)
  }

  const columns: any[] = [
    { field: "id", headerName: t.General.id },
    {
      field: "title",
      headerName: "Alert",
      flex: 1.2,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography gutterBottom fontWeight={400} variant={"body1"} data-testid={params.value}>
            {params.value}
          </Typography>
          <ExpandableCell description={params.row.description} />
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: t.General.createdAt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <TableTextCell content={formatDate(params.value)} />,
    },
    {
      headerName: t.General.actions,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            color={"error"}
            size={"small"}
            variant={"contained"}
            sx={{ padding: "0 1rem" }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Action
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            marginThreshold={20}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack sx={{ width: "17rem" }}>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Hold</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Inspect</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Upload to bank</Typography>
              <Typography sx={{ p: 2, cursor: "pointer" }}>Ignore</Typography>
            </Stack>
          </Popover>
          <Button size={"small"} variant={"outlined"} onClick={navigateTo.bind(null, `/indicators/${params?.row.id}`)}>
            Details
          </Button>
        </Stack>
      ),
    },
  ]

  if (isLoading || !data || !findings || isLoadingFindings || !vendor || isLoadingVendor) return <VendorSkeleton />

  return (
    <Grid container className={classes.payment} spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} alignItems={"center"}>
              <Stack direction="row" alignItems="center" gap={1}>
                <PaidOutlinedIcon color={"primary"} />
                <Typography variant="h6" fontWeight={600} data-testid={"name"}>
                  {t.General.payment}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  Status
                </Typography>
                <Box className={classes.paymentDetails}>
                  <FieldWithValue
                    name={"Status"}
                    value={isAtRisk ? "At Risk" : "No Risk"}
                    testId={"internal-id"}
                    style={{
                      backgroundColor: isAtRisk ? "rgba(219,97,97,0.2)" : "transparent",
                      borderRadius: "5px",
                    }}
                    icon={isAtRisk ? <InfoOutlinedIcon color={"error"} /> : undefined}
                  />
                  <FieldWithValue name={"Source"} value={"Payment File"} testId={"internal-id"} />
                  <FieldWithValue name={"Owner"} value={"Johnny Deutsch"} />
                  <Divider sx={{ margin: "1rem 0" }} />
                  <FieldWithValue
                    name={t.General.createdAt}
                    value={vendor?.dateCreated ? formatDate(vendor.dateCreated) : "-"}
                  />
                  <FieldWithValue
                    name={t.General.lastEdited}
                    value={vendor?.lastModified ? formatDate(vendor.lastModified) : "-"}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                  General Details
                </Typography>
                <Box className={classes.paymentDetails}>
                  <Box>
                    <FieldWithValue name={t.General.amount} value={data.amount.toLocaleString()} />
                    <FieldWithValue name={t.General.currencyCode} value={data.currencyCode} />
                    <FieldWithValue name={t.General.abaNumber} value={data?.abaNumber || "-"} />
                    <FieldWithValue
                      name={t.General.payeeBankAccountNumber}
                      value={data?.payeeBankAccountNumber || "-"}
                    />
                    <FieldWithValue name={t.General.payerName} value={data?.payeeName || "-"} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {vendor?.name && (
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              sx={{ height: "5rem", fontSize: "1.3rem", borderRadius: "1rem" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack direction="row">
                <Groups2OutlinedIcon color={"primary"} fontSize={"large"} sx={{ marginRight: "0.5rem" }} />
                <Typography
                  variant="h5"
                  data-testid={"vendor-name"}
                  sx={{ marginLeft: "0.5rem", verticalAlign: "middle" }}
                >
                  {vendor.name}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                      General details
                    </Typography>
                    <Box className={classes.boxDetails}>
                      <Box>
                        <FieldWithValue name={t.General.internalId} value={vendor.internalId} testId={"internal-id"} />
                        <FieldWithValue name={t.General.email} value={vendor.email} />
                        <FieldWithValue name={t.General.phone} value={vendor.phone} />
                        <FieldWithValue name={t.General.taxpayerID} value={vendor.taxpayerID} />
                        <FieldWithValue name={t.General.registrationCode} value={vendor.registrationCode} />
                        <FieldWithValue name={t.General.currency} value={vendor.currency} />
                      </Box>
                      <Divider sx={{ margin: "1rem 0" }} />
                      <Box>
                        <FieldWithValue
                          name={t.General.primaryContact}
                          value={vendor.primaryContact}
                          testId={"primary-contact"}
                        />
                        <FieldWithValue name={t.General.primarySubsidiary} value={vendor.primarySubsidiary} />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                      Payment details
                    </Typography>
                    <Box className={classes.boxDetails}>
                      <Box>
                        <FieldWithValue name={t.General.iban} value={vendor.iban} testId={"iban"} />
                        <FieldWithValue name={t.General.swiftCode} value={vendor.swift} testId={"swift"} />
                        <FieldWithValue name={t.General.abaNumber} value={vendor.abaNumber} />
                        <FieldWithValue name={t.General.paymentMethod} value={vendor.vendorPaymentMethod} />
                        <FieldWithValue name={t.General.paypalAccount} value={vendor.paypalAccount} />
                      </Box>
                      <Divider sx={{ margin: "1rem 0" }} />
                      <Box>
                        <FieldWithValue name={t.General.address} value={vendor.address} />
                        <FieldWithValue name={t.General.countryCode} value={vendor.billingCountry} />
                        <FieldWithValue name={t.General.billingRegion} value={vendor.billingRegion} />
                        <FieldWithValue name={t.General.billingZipCode} value={vendor.billingZip} />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <Typography variant={"h6"} fontWeight={400} marginBottom={"0.875rem"}>
                      Bank account details
                    </Typography>
                    <Box className={classes.boxDetails}>
                      <FieldWithValue name={t.General.bankAccountName} value={vendor.bankAccountName} />
                      <FieldWithValue name={t.General.bankAccountType} value={vendor.bankAccountType} />
                      <FieldWithValue name={t.General.bankCode} value={vendor.bankCode} />
                      <FieldWithValue name={t.General.bankAccountNumber} value={vendor.bankAccountNumber} />
                      <FieldWithValue name={t.General.bankAddress} value={vendor.bankAddress} />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      {findings.length > 0 && (
        <Grid item xs={12} marginBottom={"1rem"}>
          <Paper className={classes.bottomPaper}>
            <Box className={classes.table} data-testid={"vendor-table"}>
              <DataGridPro
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                rows={findings}
                columns={columns}
                slotProps={slotProps}
                sx={dataGridSx}
                getEstimatedRowHeight={() => 200}
                getRowHeight={() => "auto"}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default Payment
