/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * edge service
 * Creednz platform edge service
 * OpenAPI spec version: 1.0
 */

export type RuleDtoExaminedEntity = typeof RuleDtoExaminedEntity[keyof typeof RuleDtoExaminedEntity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuleDtoExaminedEntity = {
  Vendor: 'Vendor',
  Payment: 'Payment',
  Ledger: 'Ledger',
  Email: 'Email',
} as const;
