import React, { useState } from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { ITheme } from "theme/theme"
import { useTheme } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { makeStyles } from "makeStyles"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepButton from "@mui/material/StepButton"
import Grid from "@mui/material/Grid"
import CompanyForm from "../companies/CompanyForm"
import OrganizationForm from "./OrganizationForm"
import {
  CreateCompanyDto,
  CreateCompanyDtoMvfFormat,
  CreateCompanyDtoPaymentFormat,
  OrganizationDto,
} from "../../apis/model"
import { useNavigate } from "react-router"

const useStyles = makeStyles()((theme: ITheme) => ({
  content: {
    padding: "2rem",
    height: "auto",
    minHeight: "20rem",
    marginTop: "2rem",
    "@media (max-width: 600px)": {
      height: "38rem",
    },
  },
  stepContent: {
    height: "auto",
    minHeight: "20rem",
  },
  stage: {
    textAlign: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.5rem",
  },
  gridMargin: {
    margin: "0 0 1rem 0",
  },
  logo: {
    fontSize: "1.5rem",
    cursor: "pointer",
    textAlign: "center",
    lineHeight: "3.5rem",
    height: "3.5rem",
    marginBottom: "5rem",
    color: theme.palette.mode,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey["500"]}`,
  },
  loader: {
    marginTop: "1rem",
  },
  createUser: {
    fontWeight: 600,
  },
  createUserLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}))

interface IProps {
  open: boolean
  closeDialog: () => void
}

const CreateOrganization = ({ open, closeDialog }: IProps): JSX.Element => {
  const { classes } = useStyles()
  const theme: ITheme = useTheme()
  const t = theme.props
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [currentStage] = useState<string>("Create new organization")

  const [completed] = useState<{ [k: number]: boolean }>({})
  const [company, setCompany] = useState<CreateCompanyDto>({
    domain: "",
    name: "",
    mvfFormat: CreateCompanyDtoMvfFormat.NetSuite,
    paymentFormat: CreateCompanyDtoPaymentFormat.CHASE,
    organizationId: 0,
  })

  const steps = ["Create new organization", "Create your first company", "Create your first user"]

  const navigateTo = (url: string): void => navigate(url)

  const saveOrganization = (organization: OrganizationDto) => {
    setCompany({ ...company, organizationId: organization.id })
  }

  const handleNext = () => setActiveStep(activeStep + 1)

  const handleStep = (step: number) => () => setActiveStep(step)

  const handleClose = () => closeDialog()

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.logo}>
        {t.General.createOrganization}
        <Typography variant={"h4"} className={classes.stage}>
          {currentStage}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={5} className={classes.borderRight}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(
                (label: string, index: number): JSX.Element => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                )
              )}
            </Stepper>
          </Grid>
          <Grid item md={8} xs={7}>
            {activeStep === 0 && (
              <Grid container className={classes.stepContent}>
                <OrganizationForm handleNext={handleNext} saveOrganization={saveOrganization} />
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container className={classes.stepContent}>
                <Grid item xs={12} className={classes.gridMargin} textAlign={"center"}>
                  <CompanyForm nextStep={handleNext} company={company} setCompany={setCompany} />
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid container className={classes.stepContent}>
                <Grid item xs={12} className={classes.gridMargin} textAlign={"center"}>
                  <Typography variant={"body1"} gutterBottom className={classes.createUser}>
                    {t.General.goToUsersPage}
                  </Typography>
                  <Typography
                    className={classes.createUserLink}
                    variant={"body1"}
                    onClick={navigateTo.bind(null, `/admin/organizations/${company.organizationId}/users`)}
                  >
                    {t.General.here}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t.General.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrganization
